import { Button, Col, Popover, Row } from 'antd'
import { Component } from 'react'
import { connect } from 'react-redux'
import { ICentralPoint } from '../../../models/reducers/centralPoint.model'
import { IConnectPoint } from '../../../models/reducers/connectionPoint.model'
import { ISetting } from '../../../models/reducers/setting.model'
import { isIphone } from '../../../utils/checkIos'
import { utilssManager } from '../../../utils/utilsManager'
import MAudio from '../../Audio/MAudio'
import { IUserParticipants } from '../../GridParticipants/GridParticipants'
import NoVideoMobile from '../../MobileVideo/NoVideoMobile'
import VideoMobile from '../../MobileVideo/VideoMobile'
import style from './MobileView.module.scss'
import RoomMaster from './RoomMaster'
type Props = {
    settingState: ISetting
    centralPoint: ICentralPoint
    connectionPoint: Array<IConnectPoint>
    row: number
}
type State = {
}
class MobileView extends Component<Props, State> {
    state = {
    }
    renderGridParticipants = () => {
        const users: Array<IUserParticipants> = []
        let total = 0
        const { centralPoint, connectionPoint } = this.props
        const videos = centralPoint?.consumerList?.filter((i) => i.type === 'video')
        const audios = centralPoint?.consumerList?.filter((i) => i.type === 'audio')
        if (centralPoint) {
            const userCenterPoint: IUserParticipants = {
                id: centralPoint.id,
                username: centralPoint.username,
                videos,
                audios,
                totalView: videos.length === 0 ? 1 : videos.length,
            }
            users.push(userCenterPoint)
            total += userCenterPoint.totalView
        }
        connectionPoint.forEach((user) => {
            const videosConnectionPoint = user.consumerList.filter((i) => i.type === 'video')
            const audiosConnectionPoint = user.consumerList.filter((i) => i.type === 'audio')
            const userConnectionPoint: IUserParticipants = {
                id: user.id,
                username: user.username,
                videos: videosConnectionPoint,
                audios: audiosConnectionPoint,
                totalView: videos.length === 0 ? 1 : videos.length,
            }
            total += userConnectionPoint.totalView
            users.push(userConnectionPoint)
        })
        return { users, total }
    }

    shorten = (value: string) => {
        let name = value.trim()
        while (name.indexOf('  ') !== -1) {
            name = name.replace('  ', ' ')
        }
        const array = name.split(' ')
        if (array.length > 1) {
            let result = ''
            for (let i = 0; i < array.length; i++) {
                if (i === array.length - 1) {
                    result += '.' + array[i]
                } else if (i === 0) {
                    result += array[i].charAt(0)
                } else {
                    result += '.' + array[i].charAt(0)
                }
            }
            return result
        } else {
            return array[0]
        }
    }
    render() {
        const { users } = this.renderGridParticipants()
        const { row, settingState, centralPoint } = this.props
        const iPhone = isIphone()
        return (
            row === 2 ?
                <div className={style["Mobile-View"]}>
                    <div className="Other-User"
                        style={{
                            width: '100%'
                        }}
                    >
                        <Row style={{
                            width: '100%',
                            height: '100%',
                            overflow: 'auto',
                            padding: '0px',
                        }}>
                            {users.map((user) => {
                                if (user.videos.length === 0) {
                                    return (<Col
                                        key={user.id}
                                        style={{
                                            height: '23vh',
                                            borderRadius: '6px',
                                            background: 'transparent',
                                            padding: 5
                                            // borderRadius: '10px',
                                            // marginTop: '10px'
                                        }} span={12}
                                    >
                                        <div
                                            style={{
                                                height: '100%',
                                                position: 'relative',
                                                width: '100%',
                                                background: 'black',
                                                border: '1px solid aqua',
                                                borderRadius: '5px'
                                            }}
                                            className="content"
                                        >
                                            <NoVideoMobile username={user.username} />
                                            {utilssManager.me !== user.id ?
                                                user.audios.length === 0 ?
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: '8%',
                                                            left: '2%',
                                                            width: '10px',
                                                            height: '10px',
                                                            zIndex: 1
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                width: '16px', height: '16px'
                                                            }}
                                                            src="./icons/mic-off-white.png" alt=""
                                                        />
                                                    </div>
                                                    : <MAudio
                                                        id={user.id}
                                                        audio={user.audios[0]}
                                                        consumerId={user.audios[0].id}
                                                    />
                                                : ''
                                            }
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: '0',
                                                    padding: '0 5px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    textShadow: 'var(--text-shadow-black)',
                                                    zIndex: 1,
                                                    color: `${utilssManager.me === user.id ? 'orange' : '#eeeff6'}`
                                                }}
                                            >
                                                {this.shorten(user.username) || ''}
                                            </div>
                                            {user.id !== centralPoint.id && settingState.isKey === true &&
                                                <Popover
                                                    style={{
                                                        position: 'relative',
                                                        borderRadius: '10px',
                                                        overflow: 'hidden'
                                                    }}
                                                    title={null}
                                                    content={<RoomMaster user={user} />}
                                                    trigger="click"

                                                >
                                                    <Button
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: '4%',
                                                            background: 'transparent',
                                                            border: 'none',
                                                            width: '16px',
                                                            height: '16px',
                                                            padding: 0
                                                        }}
                                                        icon={<img style={{
                                                            width: 'auto',
                                                            height: 'auto',
                                                            position: `${iPhone ? 'absolute' : 'relative'}`,
                                                            top: 0,
                                                            right: '1%'
                                                        }} src="./icons/more.png" alt="" />}
                                                    />
                                                </Popover>
                                            }
                                        </div>
                                    </Col>
                                    )
                                } else {
                                    return (user.videos.map((video, index) => {

                                        return (<Col
                                            key={index}
                                            style={{
                                                height: '23vh',
                                                borderRadius: '6px',
                                                background: 'transparent',
                                                padding: 5
                                                // borderRadius: '10px',
                                                // marginTop: '10px'
                                            }} span={12} >
                                            <div
                                                style={{
                                                    height: '100%',
                                                    position: 'relative',
                                                    width: '100%',
                                                    background: 'black',
                                                    border: '1px solid aqua',
                                                    borderRadius: '5px'
                                                }}
                                                className="content"
                                            >
                                                <VideoMobile
                                                    id={user.id}
                                                    consumerId={video.id}
                                                    settingState={settingState}
                                                />
                                                {index === 0 ?
                                                    <div>
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: '0',
                                                                padding: '0 5px',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                textShadow: 'var(--text-shadow-black)',
                                                                zIndex: 1,
                                                                color: `${utilssManager.me === user.id ? 'orange' : '#eeeff6'}`
                                                            }}
                                                        >
                                                            {this.shorten(user.username) || ''}
                                                        </div>
                                                        {user.id !== centralPoint.id && settingState.isKey &&
                                                            <Popover
                                                                style={{
                                                                    position: 'relative',
                                                                    borderRadius: '10px',
                                                                    overflow: 'hidden'
                                                                }}
                                                                title={null}
                                                                content={<RoomMaster user={user} />}
                                                                trigger="click"

                                                            >
                                                                <Button
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        right: '4%',
                                                                        background: 'transparent',
                                                                        border: 'none',
                                                                        width: '16px',
                                                                        height: '16px',
                                                                        padding: 0
                                                                    }}
                                                                    icon={<img style={{
                                                                        width: 'auto',
                                                                        height: 'auto',
                                                                        position: `${iPhone ? 'absolute' : 'relative'}`,
                                                                        top: 0,
                                                                        right: '1%'
                                                                    }} src="./icons/more.png" alt="" />}
                                                                />
                                                            </Popover>
                                                        }
                                                        {utilssManager.me !== user.id ?
                                                            user.audios.length === 0 ?
                                                                <div
                                                                    style={{
                                                                        position: 'absolute',
                                                                        bottom: `${iPhone ? '0px' : '2%'}`,
                                                                        left: '2%',
                                                                        width: '20px',
                                                                        height: '20px',
                                                                        zIndex: 1
                                                                    }}
                                                                >
                                                                    <img
                                                                        style={{
                                                                            width: '16px', height: '16px',
                                                                            position: 'absolute',
                                                                            bottom: 0
                                                                        }}
                                                                        src="./icons/mic-off-white.png" alt=""
                                                                    />
                                                                </div>
                                                                :
                                                                <MAudio
                                                                    id={user.id}
                                                                    audio={user.audios[0]}
                                                                    consumerId={user.audios[0].id}
                                                                />

                                                            : ''
                                                        }
                                                    </div> : ''}
                                            </div>
                                        </Col>
                                        )
                                    })
                                    )
                                }
                            })
                            }
                        </Row>
                    </div>
                </div > :
                <div className={style["Mobile-View"]}>
                    <div className="Other-User"
                        style={{
                            width: '100%',
                            height: '100%',
                            padding: 0
                        }}
                    >
                        <Row
                            style={{
                                width: '100%',
                                height: '100%',
                                overflow: 'auto',
                                padding: '0px'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    padding: '0px'
                                }}
                            >
                                {users.map((user: any) => {
                                    if (user.videos.length === 0) {
                                        return (<Col
                                            key={user.id}
                                            style={{
                                                height: `${iPhone ? '50%' : 'calc((100%-100px)/2)'}`,
                                                padding: '5px',
                                                borderRadius: '20px',
                                                // borderRadius: '10px',
                                                // marginTop: '10px'
                                            }} span={24} >
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    padding: 5
                                                }}
                                            >
                                                <div
                                                    style={{ width: '100%', height: '100%', background: '#000', border: 'solid 1px aqua' }}
                                                    className="content">
                                                    <NoVideoMobile username={user.username} />
                                                    {utilssManager.me !== user.id ?
                                                        user.audios.length === 0 ?
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    bottom: '5%',
                                                                    right: '25px',
                                                                    width: '20px',
                                                                    height: '20px',
                                                                    zIndex: 1
                                                                }}
                                                            >
                                                                <img
                                                                    style={{
                                                                        width: '16px', height: '16px', position: 'absolute', bottom: 0, right: '0px'
                                                                    }}
                                                                    src="./icons/mic-off.png" alt=""
                                                                />
                                                            </div>
                                                            :
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    left: '3%',
                                                                    bottom: '3%'
                                                                }}
                                                            >
                                                                <MAudio
                                                                    id={user.id}
                                                                    audio={user.audios[0]}
                                                                    consumerId={user.audios[0].id}
                                                                />
                                                            </div>
                                                        :
                                                        ''
                                                    }
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: '2%',
                                                            left: '2%',
                                                            padding: '0 5px',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            textShadow: 'var(--text-shadow-black)',
                                                            width: 'auto',
                                                            zIndex: 1,
                                                            color: `${utilssManager.me === user.id ? 'orange' : '#eeeff6'}`,
                                                            fontSize: '16px'
                                                        }}
                                                    >
                                                        {user.username}
                                                    </div>
                                                    {
                                                        user.id !== centralPoint.id && settingState.isKey &&
                                                        <Popover
                                                            style={{
                                                                position: 'relative',
                                                                borderRadius: '10px',
                                                                overflow: 'hidden'
                                                            }}
                                                            title={null}
                                                            content={<RoomMaster user={user} />}
                                                            trigger="click"

                                                        >
                                                            <Button
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '3%',
                                                                    right: '4%',
                                                                    background: 'transparent',
                                                                    border: 'none',
                                                                    width: '16px',
                                                                    height: '16px',
                                                                    padding: 0
                                                                }}
                                                                icon={<img src="./icons/more.png" alt="" />}
                                                            />
                                                        </Popover>
                                                    }
                                                </div>
                                            </div>

                                        </Col>
                                        )
                                    } else {
                                        return (user.videos.map((video: any, index: any) => {
                                            return (<Col
                                                key={index}
                                                style={{
                                                    height: `${iPhone ? '50%' : 'calc((100%-100px)/2)'}`,
                                                    padding: '5px',
                                                    borderRadius: '20px',
                                                    overflow: 'hidden'
                                                    // borderRadius: '10px',
                                                    // marginTop: '10px'
                                                }} span={24} >
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        padding: 5
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            padding: '16px',
                                                            height: '100%',
                                                            position: 'relative',
                                                            background: '#000',
                                                            border: 'solid 1px aqua'
                                                        }}
                                                        className="content">
                                                        <VideoMobile
                                                            id={user.id}
                                                            consumerId={video.id}
                                                            settingState={settingState} />
                                                        {index === 0 ?
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        left: '0',
                                                                        padding: '0 5px',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        textShadow: 'var(--text-shadow-black)',
                                                                        width: 'auto',
                                                                        zIndex: 1,
                                                                        color: `${utilssManager.me === user.id ? 'orange' : '#eeeff6'}`
                                                                    }}
                                                                >
                                                                    {user.username}
                                                                </div>
                                                                {
                                                                    user.id !== centralPoint.id && settingState.isKey &&
                                                                    <Popover
                                                                        style={{
                                                                            position: 'relative',
                                                                            borderRadius: '10px',
                                                                            overflow: 'hidden'
                                                                        }}
                                                                        title={null}
                                                                        content={<RoomMaster user={user} />}
                                                                        trigger="click"

                                                                    >
                                                                        <Button
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: 0,
                                                                                right: '4%',
                                                                                background: 'transparent',
                                                                                border: 'none',
                                                                                width: '16px',
                                                                                height: '16px',
                                                                                padding: 0
                                                                            }}
                                                                            icon={<img src="./icons/more.png" alt="" />}
                                                                        />
                                                                    </Popover>
                                                                }
                                                                {utilssManager.me !== user.id ?
                                                                    user.audios.length === 0 ?
                                                                        <div
                                                                            style={{
                                                                                position: 'absolute',
                                                                                bottom: '1%',
                                                                                left: '2%',
                                                                                width: '20px',
                                                                                height: '20px',
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center'
                                                                            }}
                                                                        >
                                                                            <img
                                                                                style={{
                                                                                    width: '16px',
                                                                                    height: '16px',
                                                                                    position: 'absolute',
                                                                                    bottom: 0,
                                                                                    left: '0'
                                                                                }}
                                                                                src="./icons/mic-off.png" alt=""
                                                                            />
                                                                        </div>
                                                                        :
                                                                        <MAudio
                                                                            id={user.id}
                                                                            audio={user.audios[0]}
                                                                            consumerId={user.audios[0].id}
                                                                        />
                                                                    :
                                                                    ''
                                                                }
                                                            </div>
                                                            : ''
                                                        }
                                                    </div>
                                                </div>

                                            </Col>
                                            )
                                        })
                                        )
                                    }
                                })
                                }
                            </div>
                        </Row>
                    </div>
                </div >
        )
    }
}
const mapStateToProps = (state: any) => {
    return {
        settingState: state.settingState,
        centralPoint: state.centralPoint,
        connectionPoint: state.connectionPoint
    }
}
export default connect(mapStateToProps)(MobileView)
