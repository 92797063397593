export const renderDrawBoard = () => {
  const canvas: any = document.getElementById('board')
  const { isg_draw_board } = window
  const ctx = canvas?.getContext('2d')
  const toolData = isg_draw_board.Tools.ToolState.getToolState()
  const { listTools } = toolData
  console.log(listTools);
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  for (let index = 0; index < listTools.length; index++) {
    const tool = listTools[index]
    tool.draw(ctx)
  }
}
export const redraw = () => {
  const { isg_draw_board } = window
  const canvas: any = document.getElementById('board')
  const ctx = canvas?.getContext('2d')
  const toolData = isg_draw_board.Tools.ToolState.getToolState()
  const { listTools } = toolData
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  for (let index = 0; index < listTools.length; index++) {
    const tool = listTools[index]
    tool.draw(ctx)
  }
}

const imagePromise = (toolData: any) => {
  return new Promise((resolve, reject) => {
    try {
      const isg_draw_board = window.isg_draw_board

      const cloneTool = new isg_draw_board.Tools.Draw.MeasureImage(toolData.listPoint[0])
      const image = new Image()
      image.onload = () => {
        cloneTool.toolType = toolData.toolType
        cloneTool.imageElement = image
        resolve(cloneTool)
      }
      image.src = toolData.imageData
    } catch (error) {
      reject(error)
    }
  })
}

export const cloneToolData = async (toolData: any) => {
  const isg_draw_board = window.isg_draw_board
  if (toolData.toolType === 'imageTool') {
    return await imagePromise(toolData)
  }
  switch (toolData.toolType) {
    case 'length': {
      const cloneTool = new isg_draw_board.Tools.Draw.MeasureLine(toolData.listPoint[0])
      cloneTool.toolType = toolData.toolType
      cloneTool.author = toolData.author

      return cloneTool
    }

    case 'rectangleTool': {
      const cloneTool = new isg_draw_board.Tools.Draw.MeasureRectangle(toolData.listPoint[0])
      cloneTool.toolType = toolData.toolType
      cloneTool.author = toolData.author

      return cloneTool
    }
    case 'arrow': {
      const cloneTool = new isg_draw_board.Tools.Draw.MeasureArrow(toolData.listPoint[0])
      cloneTool.toolType = toolData.toolType
      cloneTool.author = toolData.author

      return cloneTool
    }

    case 'polygon': {
      const cloneTool = new isg_draw_board.Tools.Draw.MeasurePolygon(toolData.listPoint[0])
      cloneTool.toolType = toolData.toolType
      cloneTool.author = toolData.author

      return cloneTool
    }

    case 'arrowText': {
      const cloneTool = new isg_draw_board.Tools.Draw.MeasureArrow(
        toolData.listPoint[0],
        null,
        true
      )
      cloneTool.toolType = toolData.toolType
      cloneTool.author = toolData.author

      return cloneTool
    }

    case 'annotationElipse': {
      const cloneTool = new isg_draw_board.Tools.Draw.AnnotationElipse(toolData.listPoint[0])
      cloneTool.toolType = toolData.toolType
      cloneTool.author = toolData.author

      return cloneTool
    }

    case 'annotationRectangleTool': {
      const cloneTool = new isg_draw_board.Tools.Draw.annotationRectangle(toolData.listPoint[0])
      cloneTool.toolType = toolData.toolType
      cloneTool.author = toolData.author

      return cloneTool
    }

    case 'pencil': {
      const cloneTool = new isg_draw_board.Tools.Draw.MeasurePencil(toolData.listPoint[0])
      cloneTool.toolType = toolData.toolType
      cloneTool.author = toolData.author

      return cloneTool
    }
    case 'elipse': {
      const cloneTool = new isg_draw_board.Tools.Draw.MeasureElipse(toolData.listPoint[0])
      cloneTool.toolType = toolData.toolType
      cloneTool.author = toolData.author

      return cloneTool
    }
    case 'heart': {
      const cloneTool = new isg_draw_board.Tools.Draw.MeasureHeart(toolData.listPoint[0])
      cloneTool.toolType = toolData.toolType
      cloneTool.author = toolData.author

      return cloneTool
    }
    case 'star': {
      const cloneTool = new isg_draw_board.Tools.Draw.MeasureStar(toolData.listPoint[0])
      cloneTool.toolType = toolData.toolType
      cloneTool.author = toolData.author

      return cloneTool
    }
    case 'textTool': {
      const cloneTool = new isg_draw_board.Tools.Draw.MeasureText(toolData.listPoint[0])
      cloneTool.toolType = toolData.toolType
      cloneTool.customFont = toolData.customFont
      cloneTool.author = toolData.author

      return cloneTool
    }
    default:
      break
  }
}
