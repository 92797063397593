import { defaultDrawBoard, IDrawBoard } from "../models/reducers/drawBoard.model"

export const ACTION_TYPE = {
    DRAW_BOARD: 'DRAW_BOARD'
}
interface IAction {
    type: string
    payload: any
}
const initialize: IDrawBoard = defaultDrawBoard
const drawBoardReducer = (state = initialize, action: IAction) => {
    switch (action.type) {
        case ACTION_TYPE.DRAW_BOARD:
            state = action.payload
            return state
        default:
            return state
    }
}
const setDrawBoardReducer = (data: any) => {
    return {
        type: ACTION_TYPE.DRAW_BOARD,
        payload: data
    }
}
export {drawBoardReducer,setDrawBoardReducer}
