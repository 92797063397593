export interface IServer {
  RTC: string
}

const defaultServer: IServer = {
  RTC: 'string',
}

const server: IServer = defaultServer

export { defaultServer, server }
