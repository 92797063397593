import { Modal } from 'antd'
import { Component } from 'react'
import { connect } from 'react-redux'
import { defaultModal, IModal } from '../../models/reducers/modal.model'
import { setModal } from '../../reducers/modalReducer'
import './ModalBasic.scss'
type Props = {
  modal: IModal
  setModal(data: IModal): void
}

type State = {}

export class ModalBasic extends Component<Props, State> {
  state = {}

  render() {
    const { modal } = this.props
    return (
      <Modal
        className="modal-basic"
        title={modal.title}
        visible={modal.visible}
        width={modal.width}
        style={{ height: modal.height, top: modal.top }}
        footer={[]}
        onCancel={() => this.props.setModal(defaultModal)}
      >
        {modal.content}
      </Modal>
    )
  }
}

const mapStateToProps = (state: any) => ({
  modal: state.modal,
})
const mapDispatchToProps = (dispatch: any) => {
  return {
    setModal: (data: IModal) => dispatch(setModal(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalBasic)
