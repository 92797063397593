import { Component } from 'react'

interface IProps {
  data: any
  dragKey: string
  style?: any
  className?: string
  onDragStart?(): void
  onClick?(): void
}
interface IState {}

class DragExtend extends Component<IProps, IState> {
  elementRef: HTMLDivElement | null
  constructor(props: IProps) {
    super(props)
    this.elementRef = null
  }

  render() {
    return (
      <div
        onTouchEnd={this.props.onClick}
        onClick={this.props.onClick}
        className={this.props.className}
        style={{ cursor: 'move', float: 'left', ...this.props.style }}
        draggable={true}
        onDragStart={(e) => {
          const data = {
            data: this.props.data,
            dragKey: this.props.dragKey,
          }
          e.dataTransfer.effectAllowed = 'move'
          e.dataTransfer.setData('data', JSON.stringify(data))
          this.props.onDragStart && this.props.onDragStart()
        }}
      >
        {this.props.children}
      </div>
    )
  }
}

export default DragExtend
