import { CopyTwoTone } from '@ant-design/icons'
import { Modal, Tooltip, Typography } from 'antd'
import { Component } from 'react'
import { connect } from 'react-redux'
import { IRoom } from '../../models/reducers/room.model'
import { defaultIShareLink, setShareLink } from '../../reducers/shareLink'
import { encodeLink } from '../../utils/shareLink'
type Props = {
    roomState: IRoom
    shareLink: any
    setShareLink(data: any): void
}
type State = {
    link: any,
    coppy: boolean
}
const { Paragraph } = Typography
class FixShareLink extends Component<Props, State> {
    state = {
        link: window.location.href,
        coppy: false
    }
    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.roomState !== this.props.roomState) {
            this.getLink()
        }
    }
    componentDidMount = async () => {
        this.getLink()
    }
    getLink = () => {
        const { roomState } = this.props
        const link = encodeLink(roomState)
        this.setState({
            link
        })
    }
    Copy = async () => {
        this.setState({
            coppy: true
        })
        navigator.clipboard.writeText(this.state.link)
        setTimeout(async () => {
            await this.setState({
                coppy: false
            })
        }, 3000);
    }
    render() {
        const { shareLink } = this.props
        return (
            <Modal
                className="modal-setting"
                footer={null}
                visible={shareLink.visiable}
                onCancel={() => this.props.setShareLink(defaultIShareLink)}
            >
                <div style={{ width: 'auto' }}>
                    <Paragraph
                        style={{ zIndex: 1000 }}
                    //                    onClick={() => this.props.setShareLink(defaultIShareLink)}

                    >
                        {JSON.stringify(this.state.link)}
                        <Tooltip title="Coppy"
                            style={{
                                width: '50px',
                                height: '50px',
                                cursor: 'pointer'
                            }}
                        >
                            <span
                                onClick={() => { this.Copy() }}
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    cursor: 'pointer'
                                }}
                            >
                                {
                                    !this.state.coppy ?
                                        <CopyTwoTone style={{ width: '50px', height: '50px' }} /> :
                                        <span
                                            style={{
                                                marginLeft: '10px',
                                                color: 'blue'
                                            }}
                                        >
                                            copied
                                        </span>
                                }
                            </span>
                        </Tooltip>
                    </Paragraph>
                </div>
            </Modal>

        )
    }
}
const mapStateToProps = (state: any) => {
    return {
        shareLink: state.shareLink,
        roomState: state.roomState
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        setShareLink: (data: any) => dispatch(setShareLink(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FixShareLink)
