let currentRecorder: any = null
let recordedChunks: any = []

const handleDataAvailable = (event: any) => {
  if (event.data && event.data.size > 0) {
    recordedChunks.push(event.data)
  }
}

const compress = async (stream: MediaStream, quality: number = 0.75) => {
  const videoTrack = stream.getVideoTracks()[0]
  const trackProcessor = new window.MediaStreamTrackProcessor({
    track: videoTrack,
  })
  const trackGenerator = new window.MediaStreamTrackGenerator({
    kind: 'video',
  })
  const source = trackProcessor.readable
  const sink = trackGenerator.writable
  const { width, height } = videoTrack.getSettings()
  const resize = quality
  const resizeSize = {
    width: resize * (width ? width : 640),
    height: resize * (height ? height : 320),
  }
  const canvasElement = document.createElement('canvas')
  canvasElement.width = resizeSize.width
  canvasElement.height = resizeSize.height
  const canvasCtx = canvasElement?.getContext('2d')
  if (!canvasElement || !canvasCtx) {
    return stream
  }
  const transformer = new window.TransformStream({
    async transform(videoFrame, controller) {
      const bitmap = await createImageBitmap(videoFrame, {
        resizeWidth: resizeSize.width,
        resizeHeight: resizeSize.height,
        resizeQuality: 'low',
      })
      canvasCtx.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height)
      const newBitmap = await createImageBitmap(canvasElement)
      const newFrame = new window.VideoFrame(newBitmap, {
        timestamp: videoFrame.timestamp,
      })
      controller.enqueue(newFrame)

      videoFrame.close()
    },
  })
  const abortController = new AbortController()
  const signal = abortController.signal
  const promise = source.pipeThrough(transformer, { signal }).pipeTo(sink)
  promise.catch((e: any) => {
    source.cancel(e)
    sink.abort(e)
  })
  const transformedStream = new MediaStream([trackGenerator])
  const audioTracks = stream.getAudioTracks()
  audioTracks.forEach((track) => {
    transformedStream.addTrack(track)
  })
  return transformedStream
}
const recorderVideo = async (stream: MediaStream, codecs: string, quality: number) => {
  const options = { mimeType: `video/webm; codecs=${codecs}` }
  if (quality !== 1) {
    const transformedStream = await compress(stream, quality)
    currentRecorder = new MediaRecorder(transformedStream, options)
  } else {
    currentRecorder = new MediaRecorder(stream, options)
  }
  currentRecorder.ondataavailable = handleDataAvailable
  currentRecorder.onstop = () => {
    const blob = new Blob(recordedChunks, {
      type: 'video/webm',
    })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'video.webm'
    a.click()
    URL.revokeObjectURL(url)
    recordedChunks = []
  }
  currentRecorder.start()
}
const stopRecording = async () => {
  if (currentRecorder) {
    currentRecorder.stop()
  }
}
export { recorderVideo, stopRecording }
