import { defaultDrawTools, IDrawTool } from "../models/reducers/toolBoard.model"
export const ACTION_TYPES = {
    DRAW_CAMERA: 'DRAW_CAMERA'
}
interface IAction {
    type: string,
    payload: any
}
const initialize:Array<IDrawTool> = defaultDrawTools
const drawTools = (state = initialize, action: IAction) => {
    switch(action.type){
        case ACTION_TYPES.DRAW_CAMERA:
            state = action.payload
            return state
        default:
            return state
    }
}
const setDrawTools = (data: any)=>{
    return {
        type: ACTION_TYPES.DRAW_CAMERA,
        payload: data
    }
}
export {drawTools, setDrawTools}
