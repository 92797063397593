export interface IDevice {
  deviceId: string
  groupId: string
  id: string
  isCustomLabel?: boolean
  kind: string
  label: string
  active: boolean
}

export interface ISetting {
  micros: Array<IDevice>
  cameras: Array<IDevice>
  isScreenCapturingSupported: boolean
  isWebsiteHasWebcamPermissions: boolean
  isWebsiteHasMicrophonePermissions: boolean
  maxResolution: string
  isKey: boolean
  codecRecoder: string
  qualityRecorder: number
}

export const defaultSetting: ISetting = {
  micros: [],
  cameras: [],
  isKey: false,
  isScreenCapturingSupported: true,
  isWebsiteHasWebcamPermissions: true,
  isWebsiteHasMicrophonePermissions: true,
  maxResolution: '1280x720',
  codecRecoder: 'vp8',
  qualityRecorder: 0.75
}
