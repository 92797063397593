import { Component } from 'react'
import { setCentralPoint } from '../../reducers/centralPointReducer'
import { setConnectionPoint } from '../../reducers/connectionPointReducer'
import { store } from '../../redux/store'
import { utilssManager } from '../../utils/utilsManager'
import './Audio.scss'
type Props = {
    consumerId: string
    id: string
    audio: any
}
type State = {
    khoamom: boolean
}
class MAudio extends Component<Props, State> {
    state = {
        khoamom: false
    }
    audioRef: HTMLAudioElement | null = null
    microphone: any
    analyser: any
    scriptProcessor: any
    componentDidMount = () => {
        this.getStream()
    }

    componentDidUpdate = (prevProps: Props) => {
        if (prevProps.consumerId !== this.props.consumerId) {
            this.getStream()
        }
    }
    getStream = () => {
        const sfuUtils = utilssManager.SFUUtils
        if (!this.audioRef || !sfuUtils) {
            return
        }
        const { consumerId } = this.props
        const stream: any = sfuUtils.consumers.get(consumerId).stream
        if (!stream) {
            return
        } else {
            this.audioRef.srcObject = stream
        }

    }
    componentWillUnmount = () => {
        this.microphone && this.microphone.disconnect()
        this.analyser && this.analyser.disconnect()
        this.scriptProcessor && this.scriptProcessor.disconnect()
    }
    khoaMom = async () => {
        const state: any = store.getState()
        const { connectionPoint, centralPoint } = state
        if (centralPoint.id === this.props.id) {
            const newCentralPoint: any = {...centralPoint}
            newCentralPoint.consumerList?.map((i: any) => {
                if (i.id === this.props.audio.id) {
                    i.play = !this.props.audio.play
                }
            })
            await store.dispatch(setCentralPoint(newCentralPoint))
        } else {
            const newConnectionPoint: any = [...connectionPoint]
            newConnectionPoint.forEach((item: any) => {
                if (item.id === this.props.id) {
                    item.consumerList.map((i: any) => {
                        if (i.id === this.props.audio.id) {
                            i.play = !this.props.audio.play
                        }
                    })
                }
            })
            await store.dispatch(setConnectionPoint(newConnectionPoint))
        }
    }
    render() {
        const { play } = this.props.audio
        return (
            <div
                style={{
                    background: 'transparent',
                    position: 'absolute',
                    bottom: 0,
                    left: '1%'
                }}
                onClick={() => { this.khoaMom() }}
            >
                <img style={{
                    opacity: `${!play ? '40%' : ''}`
                }} src="./icons/mic-on.png" alt="" />
                <audio
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    autoPlay={true}
                    muted={!play ? true : false}
                    ref={(ref) => {
                        this.audioRef = ref
                    }} />
            </div>
        )
    }
}


export default MAudio
