export const ACTION_TYPES = {
    SET_WHITE_BOARD: 'SET_WHITE_BOARD',
}

interface IAction {
    type: string
    payload: any
}

export const WHITE_BOARD_TYPE = {
    ACTIVE: 'TRUE',
    DISABLE: 'FALSE'
}

const initialize: string = WHITE_BOARD_TYPE.DISABLE

const whiteBoardReducer = (state = initialize, action: IAction) => {
    switch (action.type) {
        case ACTION_TYPES.SET_WHITE_BOARD:
            state = action.payload
            return state
        default:
            return state
    }
}

const setWhiteBoardReducer = (data: string) => {
    return {
        type: ACTION_TYPES.SET_WHITE_BOARD,
        payload: data,
    }
}

export { whiteBoardReducer, setWhiteBoardReducer }
