import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { NOTIFYCATION_ERROR } from '../utils/notifycation'
import { clearAll, getToken } from '../utils/storeManager'

const TIMEOUT = 1 * 60 * 100000

class ServiceBaseRISSCU {
  service: AxiosInstance
  constructor(baseURL: string) {
    const service = axios.create({
      headers: { csrf: 'token' },
      timeout: TIMEOUT,
      baseURL,
    })
    service.interceptors.request.use(this.requestSuccess)
    service.interceptors.response.use(this.handleSuccess, this.handleError)
    this.service = service
  }

  addToken = (token: string) => {
    this.service.defaults.headers.common.Authorization = 'Bearer ' + token
  }

  requestSuccess = (config: any) => {
    const token = getToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }

  handleSuccess(response: AxiosResponse) {
    return response.data
  }

  handleError = (error: AxiosError | undefined) => {
    switch (error?.response?.status) {
      case 401:
      case 403:
        NOTIFYCATION_ERROR(error?.response?.status + '', error.message)
        clearAll()
        break

      default:
        // this.redirectTo(document, '/500')
        break
    }
    return Promise.reject(error)
  }

  redirectTo = (document: any, path: string) => {
    document.location = path
  }

  request = (config: any) => {
    return this.service(config)
  }
}

export { ServiceBaseRISSCU }
