import { Layout } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import { Header } from 'antd/lib/layout/layout'
import { Component } from 'react'
import { connect } from 'react-redux'
import ResizeObserver from 'react-resize-observer'
import { io } from 'socket.io-client'
import ChatSider from '../Layout/ChatSider/ChatSider'
import MainView from '../Layout/MainView/MainView'
import Participants from '../Layout/Participants/Participants'
import SettingTools from '../Layout/Setting/SettingTools'
import GridParticipants from '../components/GridParticipants/GridParticipants'
import Loading from '../components/Loading/Loading'
import ModalBasic from '../components/Modal/ModalBasic'
import ConfirmWhiteBoard from '../components/Modal/confirmWhiteBoard'
import FixShareLink from '../components/shareLink/FixShareLink'
import { ICentralPoint } from '../models/reducers/centralPoint.model'
import { IConfigAdmin } from '../models/reducers/configAdmin.model'
import { IConnectPoint } from '../models/reducers/connectionPoint.model'
import { ILargeView } from '../models/reducers/largeView.model'
import { ILayout } from '../models/reducers/layout.model'
import { IRoom } from '../models/reducers/room.model'
import { ISetting } from '../models/reducers/setting.model'
import { setCentralPoint } from '../reducers/centralPointReducer'
import { setConnectionPoint } from '../reducers/connectionPointReducer'
import { setLargeView } from '../reducers/largeviewReducer'
import { server } from '../server/server'
import { RTCService } from '../services/RTCService'
import { servicesManager } from '../services/servicesManager'
import { SFU } from '../utils/SFU.util'
import { listUserToBridgetList } from '../utils/listUserToBridgetList'
import { RoomListener } from '../utils/room.util'
import { RTCListener } from '../utils/rtc.util'
import { utilssManager } from '../utils/utilsManager'
const { Content } = Layout

interface IProps {
  settingState: ISetting
  roomState: IRoom
  layout: ILayout
  centralPoint: ICentralPoint
  connectionPoint: Array<IConnectPoint>
  configAdminState: Array<IConfigAdmin>
  setCentralPoint(data: ICentralPoint | null): void
  setConnectionPoint(data: Array<IConnectPoint>): void
  setLargeView(data: Array<ILargeView>): void
}
interface IState {
  loading: boolean
  viewType: 'horizontal' | 'vertical' | 'mobile' | any
}

class HomePage extends Component<IProps, IState> {
  state = {
    loading: true,
    viewType: 'horizontal',
  }

  componentDidMount = () => {
    const { roomState } = this.props
    const socket = io(server.RTC, {
      closeOnBeforeunload: true,
    })
    const rtcService = new RTCService(server.RTC)
    servicesManager.RTC = rtcService
    const roomUtils = new RoomListener({ socket, rtcService, room: roomState }, async () => {
      const mediaRTC = new window.isg_video_conference.MediaRTC()
      const sfuUtil = new SFU({ mediaLibrary: mediaRTC, rtcService })
      await sfuUtil.initialization()
      const rtcUtil = new RTCListener({ socket, sfuUtil })
      utilssManager.RTCUtils = rtcUtil
      utilssManager.SFUUtils = sfuUtil
      utilssManager.RoomUtils = roomUtils
      utilssManager.me = socket.id
      const listUser = await rtcService.getAllUser()
      const { centralPoint, connectionPoint } = await listUserToBridgetList(listUser, sfuUtil)
      await this.props.setCentralPoint(centralPoint)
      await this.props.setConnectionPoint(connectionPoint)
      await this.sendCamera(sfuUtil, roomState.openCamera)
      await this.sendMicro(sfuUtil, roomState.openMicro)
      setTimeout(async () => {
        await this.setMainLayout()
        await this.setState({ loading: false })
      }, 1000)
    })
  }
  setMainLayout = async () => {
    const { centralPoint } = this.props
    const activeCentalPoint = centralPoint?.consumerList
      .filter((consume) => consume.type === 'video')
      .find((consumer) => consumer.active === true)
    const { row, column } = this.props.layout
    const totalLargeView = row * column
    const largeView: Array<ILargeView> = []

    for (let index = 0; index < totalLargeView; index++) {
      largeView.push({ consumerId: activeCentalPoint && index === 0 ? activeCentalPoint.id : null })
    }
    await this.props.setLargeView(largeView)
  }
  sendCamera = async (sfuUtil: any, openCamera: boolean) => {
    const { settingState } = this.props
    const [width, height] = settingState.maxResolution.split('x') || [1280, 720]
    const camera = settingState.cameras.find((c) => c.active)
    if (camera) {
      try {
        const constraint = camera.active
          ? {
            audio: false,
            video: {
              deviceId: camera.deviceId ? { exact: camera.deviceId } : undefined,
              width: { max: Number(width), ideal: Number(width), min: 640 }, // new syntax
              height: { max: Number(height), ideal: Number(height), min: 480 }, // new syntax
            },
          }
          : {
            audio: false,
            video: {
              deviceId: camera.deviceId ? { exact: camera.deviceId } : undefined,
              width: { max: 640, ideal: 640, min: 320 }, // new syntax
              height: { max: 480, ideal: 480, min: 480 }, // new syntax
            },
          }
        try {
          const stream = await navigator.mediaDevices.getUserMedia(constraint)
          if (stream) {
            const video = stream.getVideoTracks()[0]
            openCamera && (await sfuUtil.createProducer(video.clone()))
            stream.getTracks().forEach((track) => track.stop())
          }
        } catch (error) {
        }
      } catch (error) {
        console.log('constraint err')
      }
    }

  }
  sendMicro = async (sfuUtil: any, openMicro: boolean) => {
    const { settingState } = this.props
    const activeMicro = settingState.micros.find((mic) => mic.active === true)
    // audio
    try {
      const streamAudio = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: {
          deviceId: activeMicro ? { exact: activeMicro.deviceId } : undefined,
        },
      })
      const audio = streamAudio.getAudioTracks()[0]
      openMicro && (await sfuUtil.createProducer(audio))
      streamAudio.getTracks().forEach((track) => track.stop())
    } catch (error) {
    }
  }
  render() {
    const { loading } = this.state
    const { layout } = this.props
    return !loading ? (
      <div style={{ width: '100%', height: '100vh', overflow: 'hidden', position: 'relative' }}>
        <ModalBasic />
        <ConfirmWhiteBoard />
        <SettingTools />
        <FixShareLink />
        <ResizeObserver
          onResize={(rect: any) => {
            this.setState({
              viewType: rect.width < rect.height ? 'vertical' : 'horizontal',
            })
          }}
        />
        {this.state.viewType === 'horizontal' ? (
          <Layout
            hasSider={true}
            style={{ width: '100%', height: '100%', background: 'var(--background-1)' }}
          >
            <Content>
              <MainView />
            </Content>
            <Sider
              style={{
                background: 'var(--background-1)',
                padding: layout.widthParticipants > 0 ? 8 : 0,
              }}
              width={layout.widthParticipants}
            >
              <Participants />
            </Sider>
            <Sider
              style={{userSelect: 'all' ,background: 'var(--background-1)', padding: layout.showChat ? 8 : 0 }}
              width={layout.showChat ? 400 : 0}
            >
              <ChatSider />
            </Sider>
          </Layout>
        ) : (
          <Layout
            hasSider={false}
            style={{ width: '100%', height: '100%', background: 'var(--background-1)' }}
          >
            <Header
              id="wrapper-grid-participant-vertical"
              style={{
                lineHeight: '26px',
                width: '100%',
                height: 116,
                padding: '0',
                background: 'transparent',
              }}
            >
              <GridParticipants />
            </Header>
            <Layout hasSider={true}>
              <Content>
                <MainView vertical={true} />
              </Content>
              <Sider
                style={{ background: 'var(--background-1)', padding: layout.showChat ? 8 : 0 }}
                width={layout.showChat ? '100%' : 0}
              >
                <ChatSider />
              </Sider>
            </Layout>
          </Layout>
        )}
      </div>
    ) : (
      <Loading />
    )
  }
}
const mapStateToProps = (state: any) => ({
  centralPoint: state.centralPoint,
  connectionPoint: state.connectionPoint,
  layout: state.layout,
  roomState: state.roomState,
  settingState: state.settingState,
  configAdminState: state.configAdminState,
})
const mapDispatchToProps = (dispatch: any) => {
  return {
    setCentralPoint: (data: ICentralPoint | null) => dispatch(setCentralPoint(data)),
    setConnectionPoint: (data: Array<IConnectPoint>) => dispatch(setConnectionPoint(data)),
    setLargeView: (data: Array<ILargeView>) => dispatch(setLargeView(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
