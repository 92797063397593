export interface IDrawBoard {
    data: Array<any>
    visible: boolean
    drawColor: string
  }
  
  export const colors = [
    '#000000',
    '#795548',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cddc39',
    '#ffeb3b',
    '#ffc107',
    '#ff9800',
    '#ff5722',
  ]
  export const defaultDrawBoard = {
    data: [],
    visible: false,
    drawColor: colors[Math.floor(Math.random() * 16)],
  }
