import { Button, Col, Radio, Row, Select } from 'antd'
import DetectRTC from 'detectrtc'
import { t } from 'i18next'
import { Component } from 'react'
import { connect } from 'react-redux'
import { defaultSetting, IDevice, ISetting } from '../../models/reducers/setting.model'
import { setSetting } from '../../reducers/settingReducer'
import { playAudio, EAudioType } from '../../utils/playAudio'
import styles from './style.module.scss'
const { Option } = Select
type Props = {
  hasVideo: boolean
  hasAudio: boolean
  hasShareScreen: boolean
  settingState: ISetting
  setSetting(data: ISetting): void
}

type State = {
  micros: Array<any>
  cameras: Array<any>
}

class SettingPage extends Component<Props, State> {
  state = {
    micros: [],
    cameras: [],
  }
  microTest: HTMLElement | null = null
  videoTest: HTMLVideoElement | null = null
  audioStream: MediaStream | null = null
  videoStream: MediaStream | null = null
  microphone: any
  analyser: any
  scriptProcessor: any
  componentDidMount = async () => {
    try {
      const cameraList: Array<IDevice> | any = DetectRTC.videoInputDevices
      const microList: Array<IDevice> | any = DetectRTC.audioInputDevices
      const cameras = cameraList.map((camera: IDevice, index: number) => {
        camera.active = index === 0 ? true : false
        return camera
      })
      const micros = microList.map((micro: IDevice, index: number) => {
        micro.active = index === 0 ? true : false
        return micro
      })
      const activeMicro = micros.find((mic: IDevice) => mic.active === true)
      const activeCamera = cameras.find((camera: IDevice) => camera.active === true)
      this.props.setSetting({
        ...this.props.settingState,
        cameras,
        micros,
        isScreenCapturingSupported: this.props.hasShareScreen,
        isWebsiteHasMicrophonePermissions: this.props.hasAudio,
        isWebsiteHasWebcamPermissions: this.props.hasVideo,
      })
      this.initMicroTest(activeMicro.deviceId)
      this.initCameraTest(activeCamera.deviceId)
    } catch (error) {}
  }

  initSpeakerTest = () => {
    playAudio(EAudioType.speakerTest)
  }

  initMicroTest = async (deviceId: string | undefined) => {
    try {
      const config = {
        audio: {
          deviceId: deviceId ? { exact: deviceId } : undefined,
        },
        video: false,
      }

      this.microphone && this.microphone.disconnect()
      this.analyser && this.analyser.disconnect()
      this.scriptProcessor && this.scriptProcessor.disconnect()
      if (this.audioStream) {
        this.audioStream.getTracks().forEach((track) => track.stop())
      }
      this.audioStream = await navigator.mediaDevices.getUserMedia(config)
      const audioContext = new AudioContext()
      this.analyser = audioContext.createAnalyser()
      this.microphone = audioContext.createMediaStreamSource(this.audioStream)
      this.scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1)

      this.analyser.smoothingTimeConstant = 0.8
      this.analyser.fftSize = 1024

      this.microphone.connect(this.analyser)
      this.analyser.connect(this.scriptProcessor)
      this.scriptProcessor.connect(audioContext.destination)
      this.scriptProcessor.onaudioprocess = () => {
        const array = new Uint8Array(this.analyser.frequencyBinCount)
        this.analyser.getByteFrequencyData(array)
        const arraySum = array.reduce((a, value) => a + value, 0)
        const average = arraySum / array.length
        this.microTest && (this.microTest.style.width = Math.round(average) + '%')
      }
    } catch (error) {
      console.log(error)
    }
  }

  initCameraTest = async (deviceId: string | undefined) => {
    try {
      const config = {
        video: {
          deviceId: deviceId ? { exact: deviceId } : undefined,
        },
        audio: false,
      }
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => track.stop())
      }
      this.videoStream = await navigator.mediaDevices.getUserMedia(config)
      this.videoTest && (this.videoTest.srcObject = this.videoStream)
    } catch (error) {
      console.log(error)
    }
  }

  onChangeMicro = (deviceId: string) => {
    const { settingState } = this.props
    const newSettingState = { ...settingState }
    newSettingState.micros = newSettingState.micros.map((mic) => {
      if (mic.deviceId === deviceId) {
        mic.active = true
        this.initMicroTest(deviceId)
      } else {
        mic.active = false
      }
      return mic
    })
    this.props.setSetting(newSettingState)
  }

  onChangeCamera = (deviceId: string) => {
    const { settingState } = this.props
    const newSettingState = { ...settingState }
    newSettingState.cameras = newSettingState.cameras.map((camera) => {
      if (camera.deviceId === deviceId) {
        camera.active = true
        this.initCameraTest(deviceId)
      } else {
        camera.active = false
      }
      return camera
    })
    this.props.setSetting(newSettingState)
  }

  componentWillUnmount = () => {
    if (this.audioStream) {
      this.audioStream.getTracks().forEach((track) => track.stop())
    }
    if (this.videoStream) {
      this.videoStream.getTracks().forEach((track) => track.stop())
    }

    this.microphone && this.microphone.disconnect()
    this.analyser && this.analyser.disconnect()
    this.scriptProcessor && this.scriptProcessor.disconnect()
  }

  render() {
    const { settingState } = this.props
    const activeMicro = settingState.micros.find((mic) => mic.active === true)
    const activeCamera = settingState.cameras.find((camera) => camera.active === true)
    return (
      <div id="setting-start" className={styles.setting}>
        <div className={styles.chooseCameraMicro}>
          <div style={{ marginTop: 10 }}>
            <label>{t('microsetting')}</label>
            <Select
              value={activeMicro?.deviceId}
              style={{ width: '100%' }}
              onChange={this.onChangeMicro}
            >
              {settingState.micros.map((item: any, index) => {
                return (
                  <Option value={item.deviceId} key={index}>
                    {item.label}
                  </Option>
                )
              })}
            </Select>
          </div>
          <div style={{ marginTop: 10 }}>
            <label>{t('camerasetting')}</label>
            <Select
              value={activeCamera?.deviceId}
              style={{ width: '100%' }}
              onChange={this.onChangeCamera}
            >
              {settingState.cameras.map((item: any, index) => {
                return (
                  <Option value={item.deviceId} key={index}>
                    {item.label}
                  </Option>
                )
              })}
            </Select>
          </div>
          <Row
            style={{
              marginTop: 10,
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <Col span={8}>
              <label>{t('microsignal')}: </label>
            </Col>
            <Col span={16}>
              <div
                style={{
                  width: '100%',
                  height: 10,
                  background: 'transparent',
                  marginBottom: 5,
                  borderRadius: 3,
                  // clipPath:
                  //     "polygon(100% 0, 0% 100%, 100% 100%)",
                }}
              >
                <div
                  ref={(ref) => (this.microTest = ref)}
                  style={{
                    width: '10%',
                    height: '100%',
                    maxWidth: '100%',
                    // clipPath: 'polygon(0 20%, 100% 0, 100% 100%, 0 80%)',
                    background:
                      'repeating-linear-gradient( 90deg,#fff,#fff 1px,#465298 1px,#465298 4px)',
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={24}>
              <label>{t('camerasignal')}: </label>
            </Col>
            {/* {this.state.cameras.map((item, index) => {
                    return <CameraItem data={item} key={index} index={index} />;
                })} */}
            <Col span={24}>
              <video
                style={{
                  borderRadius: 3,
                  padding: 2,
                  width: '100%',
                  background: 'black',
                  objectFit: 'contain',
                  height: 240,
                  maxHeight: 240,
                }}
                ref={(ref) => {
                  this.videoTest = ref
                }}
                playsInline={false}
                autoPlay={true}
                muted={true}
              />
            </Col>
            <label>{t('cameraresolution')}</label>
            <Col span={24}>
              <Radio.Group
                onChange={(e) => {
                  this.props.setSetting({
                    ...settingState,
                    maxResolution: e.target.value,
                  })
                }}
                defaultValue={defaultSetting.maxResolution}
              >
                <Radio.Button value="640x480">SD</Radio.Button>
                <Radio.Button value="1280x720">HD</Radio.Button>
                <Radio.Button value="1920x1080">Full HD</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col span={8}>
              <label>{t('speakertest')}: </label>
            </Col>
            <Col span={16}>
              <Button
                type="primary"
                shape="round"
                icon={<img src={`./icons/volume_up_white_24dp.svg`} />}
                onClick={this.initSpeakerTest}
              />
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state: any) => ({
  settingState: state.settingState,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSetting: (data: ISetting) => dispatch(setSetting(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingPage)
