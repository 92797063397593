import { defaultConnectPoint, IConnectPoint } from '../models/reducers/connectionPoint.model'

export const ACTION_TYPES = {
  CONNECTION_POINT: 'CONNECTION_POINT',
}

interface IAction {
  type: string
  payload: any
}

const initialize: Array<IConnectPoint> = defaultConnectPoint

const connectionPointReducer = (state = initialize, action: IAction) => {
  switch (action.type) {
    case ACTION_TYPES.CONNECTION_POINT:
      state = action.payload
      return state

    default:
      return state
  }
}

const setConnectionPoint = (data: Array<IConnectPoint>) => {
  return {
    type: ACTION_TYPES.CONNECTION_POINT,
    payload: data,
  }
}

const disableAllCamera = (data: Array<IConnectPoint>) => (dispatch: any) => {
  data.forEach((point) => {
    point.consumerList.forEach((consumer) => {
      consumer.active = false
    })
  })
  dispatch(setConnectionPoint(data))
}

export { connectionPointReducer, setConnectionPoint, disableAllCamera }
