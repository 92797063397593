import { Component } from 'react'
import { connect } from 'react-redux'
import { ILargeView } from '../../../models/reducers/largeView.model'
import { ILayout } from '../../../models/reducers/layout.model'
import { setLargeView } from '../../../reducers/largeviewReducer'
import CameraLarge from '../../BridgePoint/Camera/CameraLarge'
import DropExtend from '../../DragAndDrop/DropExtend'
import EmptyLarge from '../../Empty/EmptyLarge'

type Props = {
  largeView: Array<ILargeView>
  layout: ILayout
  setLargeView(data: Array<ILargeView>): void
}

type State = {}

class LargeView extends Component<Props, State> {
  state = {}

  onDrop = (viewIndex: number, consumerId: string) => {
    const largeView = [...this.props.largeView]
    largeView[viewIndex].consumerId = consumerId
    this.props.setLargeView(largeView)
  }

  render() {
    const { largeView } = this.props

    const { row, column } = this.props.layout
    return (
      <div
        id="main-videos"
        style={{ width: '100%', height: '100%', background: 'var(--background-1)', padding: 4 }}
      >
        {largeView.map((view, index) => (
          <DropExtend
            style={{
              width: `calc( 100% / ${column} )`,
              height: `calc( 100% / ${row} )`,
              float: 'left',
              padding: 4,
              borderRadius: '8px',
            }}
            onDrop={(e: any, data: any) => {
              if (e.dropKey === 'central-point') {
                this.onDrop(index, data.id)
              } else {
                this.onDrop(index, data.consumer.id)
              }
            }}
            key={index}
            dropKey={['central-point', 'connection-point']}
          >
            {view.consumerId ? <CameraLarge consumerId={view.consumerId} /> : <EmptyLarge />}
          </DropExtend>
        ))}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  largeView: state.largeView,
  layout: state.layout,
})
const mapDispatchToProps = (dispatch: any) => {
  return {
    setLargeView: (data: Array<ILargeView>) => dispatch(setLargeView(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LargeView)
