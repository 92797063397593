import { Button, Tooltip } from 'antd'
import { Component } from 'react'
// import { servicesManager } from '../../../services/servicesManager'
import { utilssManager } from '../../../utils/utilsManager'
import './Camera.scss'
type Props = {
  noControls?: boolean
  consumerId: string
}

type State = {}

enum EResolution {
  SD = 414720,
  HD = 921600,
  FHD = 2073600,
}

class CameraLarge extends Component<Props, State> {
  state = {}
  videoRef: HTMLVideoElement | null = null
  player: any
  revolution: any
  interval: any

  getLayer = () => {
    const mainStream = this.videoRef
    if (mainStream) {
      const bound = mainStream.getBoundingClientRect()
      const { width, height } = bound
      if (EResolution.FHD >= width * height && width * height >= EResolution.HD) {
        return 0
      } else {
        return 1
      }
    } else {
      return 1
    }
  }

  componentDidMount = () => {
    this.getStream()
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.consumerId !== this.props.consumerId) {
      // const service = servicesManager.RTC
      // service.changeConsumerLayer(prevProps.consumerId, 2)
      this.getStream()
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    if (this.player) {
      this.player.dispose()
    }
  }

  getStream = async () => {
    const sfuUtils = utilssManager.SFUUtils
    if (!this.videoRef || !sfuUtils) {
      return
    }
    const { consumerId } = this.props
    const consumeData = sfuUtils.consumers.get(consumerId)
    if (!consumeData) {
      return
    }
    const { stream } = consumeData
    if (!stream) {
      return
    }
    this.videoRef.srcObject = stream
    this.getRevolution(stream)
    clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.getRevolution(stream)
    }, 5000)
    // const service = servicesManager.RTC
    // service.changeConsumerLayer(consumerId, this.getLayer())
  }

  getRevolution = (stream: MediaStream) => {
    const videoTrack = stream.getVideoTracks()[0]
    const { width, height } = videoTrack.getSettings()
    this.revolution.innerText = width + 'x' + height
  }

  onFullscreen = () => {
    const elem = this.videoRef
    if (!elem) {
      return
    }
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    }
  }

  onPictureInPicture = () => {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture()
    } else if (document.pictureInPictureEnabled) {
      this.videoRef?.requestPictureInPicture()
    }
  }

  render() {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          borderRadius: '8px',
          position: 'relative',
        }}
        className="camera-large"
      >
        <div
          className="revolution"
          style={{
            position: 'absolute',
            top: 0,
            right: 4,
            padding: '0px 8px',
            borderRadius: 4,
            background: 'var(--color-doctor)',
            zIndex: 100,
            fontSize: 11,
          }}
          ref={(ref) => (this.revolution = ref)}
        />
        {!this.props.noControls && (
          <div className="controlsContainer">
            <div className="rightControls">
              <Tooltip title="Picture In Picture">
                <Button onClick={this.onPictureInPicture} size="small" type="text">
                  <img className="controlsIcon" alt="" src="./icons/minimize.png" />
                </Button>
              </Tooltip>
              <Tooltip title="Fullscreen" placement="topRight">
                <Button onClick={this.onFullscreen} size="small" type="text">
                  <img className="controlsIcon" alt="" src="./icons/full-video.png" />
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
        <video
          className="video-js"
          controls={false}
          poster={'./icons/loading.svg'}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            background: '#000',
            borderRadius: '9px',
          }}
          autoPlay={true}
          muted={true}
          ref={(ref) => {
            this.videoRef = ref
          }}
        />
      </div>
    )
  }
}

export default CameraLarge
