import { defaultISettingTool, ISettingTool } from "../models/reducers/settingTool.model"

export const ACTION_TYPES = {
    SETTING_TOOL: 'SETTING_TOOL',
}

interface IAction {
    type: string
    payload: any
}

const initialize: ISettingTool = defaultISettingTool

const settingTool = (state = initialize, action: IAction) => {
    switch (action.type) {
        case ACTION_TYPES.SETTING_TOOL:
            state = action.payload
            return state

        default:
            return state
    }
}

const setSettingTool = (data: ISettingTool) => {
    return {
        type: ACTION_TYPES.SETTING_TOOL,
        payload: data,
    }
}

export { settingTool, setSettingTool }
