import { RTCService } from '../services/RTCService'

interface ISFU {
  mediaLibrary: any
  rtcService: RTCService
}

class SFU {
  mediaLibrary: any
  rtcService: RTCService
  sendTransportInstance: any
  recvTransportInstance: any
  routerRtpCapabilities: any
  device: any
  sendTransport: any
  recvTransport: any
  consumers: any = new Map()
  producers: any = new Map()
  constructor(parameters: ISFU) {
    this.mediaLibrary = parameters.mediaLibrary
    this.rtcService = parameters.rtcService
  }

  initialization = async () => {
    await this.getRouterCapabilities()
    await this.loadDevices()
    await this.getTransportInstance()
    await this.createTransport()
    await this.addTransportListener()
  }

  getRouterCapabilities = async () => {
    this.routerRtpCapabilities = await this.rtcService.getRouterCapacities()
  }

  getTransportInstance = async () => {
    this.recvTransportInstance = await this.rtcService.createTransport()
    this.sendTransportInstance = await this.rtcService.createTransport()
  }

  loadDevices = async () => {
    this.device = await this.mediaLibrary.loadDevice(this.routerRtpCapabilities)
  }

  createTransport = async () => {
    this.sendTransport = await this.mediaLibrary.createTransport(
      this.device,
      this.sendTransportInstance,
      'producer_transport'
    )
    this.recvTransport = await this.mediaLibrary.createTransport(
      this.device,
      this.recvTransportInstance,
      'consumer_transport'
    )
  }

  addTransportListener = () => {
    this.sendTransport.on(
      'connect',
      async ({ dtlsParameters }: any, callback: () => void, errback: (errback: any) => void) => {
        try {
          const connected = await this.rtcService.connectTransport(
            dtlsParameters,
            this.sendTransport.id
          )
          connected ? callback() : errback(errback)
        } catch (error) {
          errback(errback)
        }
      }
    )
    this.recvTransport.on(
      'connect',
      async ({ dtlsParameters }: any, callback: () => void, errback: (errback: any) => void) => {
        try {
          const connected = await this.rtcService.connectTransport(
            dtlsParameters,
            this.recvTransport.id
          )
          connected ? callback() : errback(errback)
        } catch (error) {
          errback(errback)
        }
      }
    )
    this.sendTransport.on(
      'produce',
      async (
        parameters: any,
        callback: (callback: { id: any }) => void,
        errback: (errback: any) => void
      ) => {
        try {
          const { kind, rtpParameters, appData } = parameters

          const producerData = await this.rtcService.createProducer({
            producerTransportId: this.sendTransport.id,
            kind,
            rtpParameters,
            appData,
          })

          const producerId = producerData
          callback({ id: producerId })
        } catch (err) {
          errback(err)
        }
      }
    )
  }

  createProducer = async (track: MediaStreamTrack) => {
    const { width, height } = track.getSettings()
    const producer = await this.mediaLibrary.createProducer(
      this.routerRtpCapabilities,
      this.sendTransport,
      track,
      {
        common: track.kind,
        local: 'userMedia',
      },
      [{ maxBitrate: width && height ? width * height * 2 : 900000, scaleResolutionDownBy: 1 }]
    )
    if (!producer) {
      return
    }
    producer.on('trackended', async () => {
      this.closeProducer(producer.id)
    })
    if (producer.kind === 'video') {
      producer.setMaxSpatialLayer(0)
    }
    this.producers.set(producer.id, producer)
    return producer
  }

  createConsumer = async (producerId: string) => {
    const consumerParams = {
      rtpCapabilities: this.routerRtpCapabilities,
      consumerTransportId: this.recvTransport.id, // might be
      producerId,
    }
    const consumerData = await this.rtcService.createConsumer(consumerParams)
    if (!consumerData) {
      return
    }
    const consumer = await this.mediaLibrary.createConsumer(
      this.recvTransport,
      producerId,
      consumerData.params
    )
    const stream = new MediaStream([consumer.track])
    this.consumers.set(consumer.id, { consumer, stream })
    return consumer
  }

  closeProducer = async (producerId: string) => {
    this.producers.get(producerId)?.track.stop()
    this.producers.delete(producerId)
    await this.rtcService.closeProducer({
      producerId,
    })
  }

  closeConsumer = (consumerId: string) => {
    const { stream, consumer } = this.consumers.get(consumerId)
    consumer.track.stop()
    if (stream) {
      stream.getTracks().forEach((track: any) => {
        track.stop()
      })
    }
    this.consumers.delete(consumerId)
  }
}

export { SFU }
