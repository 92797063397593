import { message } from 'antd'
import 'antd/dist/antd.dark.min.css'
import DetectRTC from 'detectrtc'
import { t } from 'i18next'
import { Component, Suspense } from 'react'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import ErrorBoundry from './components/ErrorBoundry/ErrorBoundry'
import Loading from './components/Loading/Loading'
import i18n from './i18n'
import MStartPage from './pages/MStartPage'
import StartPage from './pages/StartPage/StartPage'
import { store } from './redux/store'
import './style/App.scss'
import './style/root.css'
import { mobileAndTabletCheck } from './utils/checkMobileAndTable'

interface IProps {}
interface IState {
  loading: boolean
  video: boolean
  audio: boolean
  screenShare: boolean
}

declare global {
  // tslint:disable-next-line: interface-name
  interface Window {
    isg_video_conference: any
    isg_draw_board: any
    webkitAudioContext: any
    MediaStreamTrackProcessor: any
    OffscreenCanvas: any
    VideoFrame: any
    RecordRTC: any
    MediaStreamTrackGenerator: any
    TELEMEDIC_VERSION: any
  }
}

class App extends Component<IProps, IState> {
  state = {
    loading: false,
    video: true,
    audio: true,
    screenShare: true,
  }

  constructor(props: IProps) {
    super(props)
    this.setGlobalWindowVariable()
  }

  setGlobalWindowVariable = () => {
    // eslint-disable-next-line no-self-assign
    window.isg_video_conference = window.isg_video_conference
    window.webkitAudioContext = window.webkitAudioContext
    window.MediaStreamTrackProcessor = window.MediaStreamTrackProcessor
    window.OffscreenCanvas = window.OffscreenCanvas
    window.VideoFrame = window.VideoFrame
    window.RecordRTC = window.RecordRTC
    window.MediaStreamTrackGenerator = window.MediaStreamTrackGenerator
    window.isg_draw_board = window.isg_draw_board
    window.TELEMEDIC_VERSION = window.TELEMEDIC_VERSION
  }

  componentDidMount = async () => {
    let audio: boolean = true
    let video: boolean = true
    let screenShare: boolean = true
    try {


      await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      })

      DetectRTC.load(async () => {
        setTimeout(() => {
          this.setState({ loading: true })
        }, 150)
      })
    } catch (error) {
      DetectRTC.load(() => {

        if (DetectRTC.isWebsiteHasWebcamPermissions === false) {
          video = false
          message.warn(t('noCameraAccess'))
        }
        if (DetectRTC.isWebsiteHasMicrophonePermissions === false) {
          audio = false
          message.warn(t('noMicroAccess'))
        }
        if (DetectRTC.isScreenCapturingSupported === false) {
          screenShare = false
          message.warn(t('noScreenCapturingAccess'))
        }

        this.setState({ loading: true, video, audio, screenShare })
      })


    }
  }

  render() {
    const { loading, video, audio, screenShare } = this.state
    const isMobile = mobileAndTabletCheck()
    return (
      <Suspense fallback={<Loading />}>
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <ErrorBoundry>
              {loading ? (
                isMobile ? (
                  <MStartPage hasVideo={video} hasAudio={audio} screenShare={screenShare} />
                ) : (
                  <StartPage hasVideo={video} hasAudio={audio} screenShare={screenShare} />
                )
              ) : (
                <Loading />
              )}
            </ErrorBoundry>
          </I18nextProvider>
        </Provider>
      </Suspense>
    )
  }
}

export default App
