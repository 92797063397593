import { SettingOutlined } from '@ant-design/icons'
import { message, Badge, Button, Layout, Popover, Select, Tooltip } from 'antd'
import { t } from 'i18next'
import moment from 'moment'
import { Component } from 'react'
import { connect } from 'react-redux'
import SelectLayout from '../../components/ModalContent/SelectLayout'
import ConferenceView from '../../components/Views/ConferenceView/ConferenceView'
import CustomView from '../../components/Views/CustomView/CustomView'
import LargeView from '../../components/Views/LargeView/LargeView'
import WhiteBoard from '../../components/Views/WhiteBoard/whiteBoard'
import i18n from '../../i18n'
import { ICentralPoint } from '../../models/reducers/centralPoint.model'
import { ILargeView } from '../../models/reducers/largeView.model'
import { ILayout } from '../../models/reducers/layout.model'
import { IModal } from '../../models/reducers/modal.model'
import { IRoom } from '../../models/reducers/room.model'
import { ISetting } from '../../models/reducers/setting.model'
import { ISpam } from '../../models/reducers/spam.model'
import { setImages } from '../../reducers/imagesReducer'
import { setLargeView } from '../../reducers/largeviewReducer'
import { setLayout } from '../../reducers/layoutReducer'
import { getNumberOfSmallCameras, LAYOUT_TYPE } from '../../reducers/layoutTypeReducer'
import { setModal } from '../../reducers/modalReducer'
import { setRoom } from '../../reducers/roomReducer'
import { setSetting } from '../../reducers/settingReducer'
import { setSettingTool } from '../../reducers/settingTool'
import { setShareLink } from '../../reducers/shareLink'
import { setSpamCamera } from '../../reducers/spamCamera'
import { setSpamMicro } from '../../reducers/spamMicro'
import { setWhiteBoardReducer, WHITE_BOARD_TYPE } from '../../reducers/whiteboardReducer'
import { store } from '../../redux/store'
import { servicesManager } from '../../services/servicesManager'
import { mobileAndTabletCheck } from '../../utils/checkMobileAndTable'
import { patientData } from '../../utils/patientData'
import { recorderVideo, stopRecording } from '../../utils/recorderVideo'
import { utilssManager } from '../../utils/utilsManager'
import styles from './style.module.scss'
const { Footer, Content } = Layout
type Props = {
  roomState: IRoom
  centralPoint: ICentralPoint | null
  layout: ILayout
  settingState: ISetting
  messageState: Array<any>
  layoutType: string
  vertical?: boolean
  setLayout(data: ILayout): void
  setLargeView(data: Array<ILargeView>): void
  setImages(data: Array<string>): void
  setRoom(data: IRoom): void
  setSetting(data: ISetting): void
  setModal(data: IModal): void
  whiteBoard: string
  setWhiteBoardReducer(data: string): void
  shareLink: any
  setShareLink(data: any): void
  spamCamera: ISpam
  setSpamCamera(data: any): void
  spamMicro: ISpam
  setSpamMicro(data: any): void
}
type State = { fullview: boolean; recording: boolean; screenShare: boolean }
class MainView extends Component<Props, State> {
  state = { fullview: true, recording: false, screenShare: false }
  screen: { stream: MediaStream | null; producer: any | null } = { stream: null, producer: null }
  record: { screenDisplayStream: MediaStream | null; composeStream: MediaStream | null } = {
    screenDisplayStream: null,
    composeStream: null,
  }

  microTest: HTMLElement | null = null
  audioStream: MediaStream | null = null
  microphone: any
  analyser: any
  scriptProcessor: any
  submit: HTMLButtonElement | any
  componentDidMount = () => {
    const { settingState } = this.props
    const activeMic: any = settingState.micros?.find((i: any) => i.active)
    if (activeMic) {
      this.onChangeMicro(activeMic?.deviceId)
    }
  }
  componentWillUnmount = () => {
    if (this.audioStream) {
      this.audioStream.getTracks().forEach((track) => track.stop())
    }
    this.microphone && this.microphone.disconnect()
    this.analyser && this.analyser.disconnect()
    this.scriptProcessor && this.scriptProcessor.disconnect()
  }
  onFullViewClick = () => {
    const { fullview } = this.state
    const { layout } = this.props
    this.props.setLayout({ ...layout, showBridgeList: !fullview, showGallery: !fullview })
    this.setState({ fullview: !fullview })
  }

  onChangeLayoutLargeView = (row: number, col: number) => {
    const { centralPoint, layout } = this.props
    const activeCentalPoint = centralPoint?.consumerList.find(
      (consumer) => consumer.active === true
    )
    const totalLargeView = row * col
    const largeView: Array<ILargeView> = []

    for (let index = 0; index < totalLargeView; index++) {
      largeView.push({ consumerId: activeCentalPoint && index === 0 ? activeCentalPoint.id : null })
    }
    this.props.setLayout({ ...layout, row, column: col })
    this.props.setLargeView(largeView)
  }

  onRecordStartClick = async () => {
    const { settingState } = this.props
    this.record.screenDisplayStream = await navigator.mediaDevices.getDisplayMedia({
      video: true,
      audio: true,
    })

    const localStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })

    const audioContext = new AudioContext()

    const dest = audioContext.createMediaStreamDestination()

    if (this.record.screenDisplayStream.getAudioTracks().length > 0) {
      const audioIn = audioContext.createMediaStreamSource(this.record.screenDisplayStream)
      audioIn.connect(dest)
    }

    if (localStream.getAudioTracks().length > 0) {
      const audioIn = audioContext.createMediaStreamSource(localStream)
      audioIn.connect(dest)
    }

    this.record.composeStream = new MediaStream([
      ...dest.stream.getAudioTracks(),
      ...this.record.screenDisplayStream.getVideoTracks(),
    ])

    const track = this.record.composeStream.getVideoTracks()[0]
    track.onended = () => {
      stopRecording()
      this.setState({ recording: false })
    }
    recorderVideo(
      this.record.composeStream,
      settingState.codecRecoder,
      settingState.qualityRecorder
    )
    this.setState({ recording: true })
  }

  onRecordStopClick = () => {
    if (!this.record.composeStream || !this.record.screenDisplayStream) {
      return
    }
    this.record.composeStream.getTracks().forEach((track) => {
      track.stop()
    })
    this.record.screenDisplayStream.getTracks().forEach((track) => {
      track.stop()
    })
    stopRecording()
    this.setState({ recording: false })
  }

  onScreenShareStartClick = async () => {
    const constraints = {
      audio: false,
      video: true,
    }
    this.screen.stream = await navigator.mediaDevices.getDisplayMedia(constraints)
    if (!this.screen.stream) {
      return
    }
    const track = this.screen.stream.getVideoTracks()[0]
    track.onended = async () => {
      const sfuUtils1 = utilssManager.SFUUtils
      if (!sfuUtils1) {
        return
      }
      await sfuUtils1.closeProducer(this.screen.producer.id)
      this.setState({ screenShare: false })
    }
    const sfuUtils = utilssManager.SFUUtils
    if (!sfuUtils) {
      return
    }
    this.screen.producer = await sfuUtils.createProducer(track)
    this.setState({ screenShare: true })
  }

  onScreenShareStopClick = async () => {
    const sfuUtils = utilssManager.SFUUtils
    if (!sfuUtils) {
      return
    }
    if (!this.screen.stream) {
      return
    }
    this.screen.stream.getTracks().forEach((track) => {
      track.stop()
    })
    await sfuUtils.closeProducer(this.screen.producer.id)
    this.screen.stream = null
    this.screen.producer = null
    this.setState({ screenShare: false })
  }

  onCapture = async () => {
    const video: any = document.querySelector('#main-videos video')
    const canvas = document.createElement('canvas')
    if (video) {
      message.loading('Loading...', 10)
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      canvas?.getContext('2d')?.drawImage(video, 0, 0, canvas.width, canvas.height)
      const image = canvas.toDataURL('image/png')
      if (patientData.svid !== undefined) {
        const risService = servicesManager.RIS
        const uploadPermision = await risService.permissionUploadFile(patientData.svid)
        const dataURLtoFile = (dataurl: any, filename: string) => {
          try {
            const arr = dataurl.split(',')
            const mime = arr[0].match(/:(.*?);/)[1]
            const bstr = atob(arr[1])
            let n = bstr.length
            const u8arr = new Uint8Array(n)
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n)
            }
            return new File([u8arr], filename, { type: mime })
          } catch (error) {}
        }

        if (uploadPermision) {
          const images = [image]
          const form = new FormData()
          const dateTime = new Date()
          for (let index = 0; index < images.length; index++) {
            const imageData = images[index]
            const file = dataURLtoFile(imageData, `img${patientData.sid}${dateTime.getTime()}.jpg`)
            file && form.append('files', file)
          }
          const uploadDate = moment().format('DD-MM-YYYY')
          const scuService = servicesManager.SCU

          const uploadState = await scuService.uploadFileStudy(patientData.sid, uploadDate, form)
          if (uploadState && uploadState.status === 1) {
            message.destroy()

            const response = await scuService.fetchStudyImage(patientData.sid)

            if (response && response.status === 1) {
              const listImg: Array<string> = []
              response.data.studyList.forEach((std: any) => {
                std.seriesList.forEach((sr: any) => {
                  sr.imageIds.forEach((img: any) => {
                    const data2 = img.split('weburi:')[1]
                    if (data2) {
                      listImg.push(data2)
                    }
                  })
                })
              })
              if (listImg.length > 0) {
                this.props.setLayout({ ...this.props.layout, showGallery: true })
              }
              this.props.setImages(listImg)
            }
          } else {
            // return dispatch(setErrorBoundry({ show: true, error: "From:" + uploadState.data.message }));
            message.destroy()
          }
          // this.props.uploadImageToServer([image])
        } else {
          message.warning('Không có quyền upload!')
        }
      } else {
        message.warning('Svid = null ')
      }
    } else {
      message.error('Không tìm thấy video!')
    }
  }

  onMicOff = () => {
    const { settingState } = this.props
    const activeMicroDevide = settingState.micros.filter((i) => i.active)[0].deviceId
    const sfuUtils = utilssManager.SFUUtils
    if (!sfuUtils) {
      return
    }
    sfuUtils.producers.forEach(async (item: any) => {
      if (item.kind === 'audio' && item.track.getSettings().deviceId === activeMicroDevide) {
        await sfuUtils.closeProducer(item.id)
        item.track.stop()
      }
    })
    this.props.setRoom({ ...this.props.roomState, openMicro: false })
  }

  onMicOn = async () => {
    await this.props.setSpamMicro({
      ...this.props.spamMicro,
      disable: true,
    })
    const { settingState } = this.props
    const micro = settingState.micros.find((c) => c.active)
    if (micro) {
      try {
        // const { resolution } = await resolutionScan(camera.deviceId)
        // if (!resolution) {
        //   return
        // }
        const constraint = {
          audio: {
            deviceId: micro ? { exact: micro.deviceId } : undefined,
          },
          video: false,
        }
        const stream = await navigator.mediaDevices.getUserMedia(constraint)
        if (stream) {
          const micros = stream.getAudioTracks()[0]
          const sfuUtils = utilssManager.SFUUtils
          if (!sfuUtils) {
            return
          }
          await sfuUtils.createProducer(micros.clone())
          stream.getTracks().forEach((track) => track.stop())
        }
      } catch (error) {
        console.log('constraint err')
      }
    }
    this.props.setRoom({ ...this.props.roomState, openMicro: true })
    setTimeout(async () => {
      await this.props.setSpamMicro({
        ...this.props.spamMicro,
        disable: false,
      })
    }, 1000)
  }

  initMicroTest = async (stream: MediaStream | any) => {
    try {
      this.microphone && this.microphone.disconnect()
      this.analyser && this.analyser.disconnect()
      this.scriptProcessor && this.scriptProcessor.disconnect()
      if (this.audioStream) {
        this.audioStream.getTracks().forEach((track) => track.stop())
      }
      this.audioStream = stream
      const audioContext = new AudioContext()
      this.analyser = audioContext.createAnalyser()
      if (this.audioStream) {
        this.microphone = audioContext.createMediaStreamSource(this.audioStream)
      }
      this.scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1)
      this.analyser.smoothingTimeConstant = 0.8
      this.analyser.fftSize = 1024
      this.microphone.connect(this.analyser)
      this.analyser.connect(this.scriptProcessor)
      this.scriptProcessor.connect(audioContext.destination)
      this.scriptProcessor.onaudioprocess = () => {
        const array = new Uint8Array(this.analyser.frequencyBinCount)
        this.analyser.getByteFrequencyData(array)
        const arraySum = array.reduce((a, value) => a + value, 0)
        const average = arraySum / array.length
        this.microTest && (this.microTest.style.width = Math.round(average) + '%')
      }
    } catch (error) {
      console.log(error)
    }
  }
  onChangeMicro = async (deviceId: string) => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: deviceId ? { exact: deviceId } : undefined,
      },
      video: false,
    })
    const audio = stream.getAudioTracks()[0]
    this.initMicroTest(stream)
    const sfuUtils = utilssManager.SFUUtils
    if (!sfuUtils) {
      return
    }
    sfuUtils.producers.forEach(async (item: any) => {
      if (item.kind === 'audio') {
        await item.replaceTrack({ track: audio })
      }
    })
  }
  onShowChat = () => {
    const { layout } = this.props
    this.props.setLayout({
      ...layout,
      showChat: layout.showChat ? false : true,
      widthParticipants: 0,
    })
  }
  onShowParticipants = () => {
    const { layout } = this.props
    if (layout.widthParticipants === 0) {
      this.props.setLayout({
        ...layout,
        showChat: false,
        widthParticipants: 400,
      })
    } else {
      this.props.setLayout({
        ...layout,
        showChat: false,
        widthParticipants: 0,
      })
    }
  }
  onCloseCamera = async () => {
    const { settingState } = this.props
    const activeCameraDevice = settingState.cameras.filter((i) => i.active)[0].deviceId
    const sfuUtils = utilssManager.SFUUtils
    if (!sfuUtils) {
      return
    }
    sfuUtils.producers.forEach(async (item: any) => {
      if (item.kind === 'video' && item.track.getSettings().deviceId === activeCameraDevice) {
        await sfuUtils.closeProducer(item.id)
        item.track.stop()
      }
    })
    this.props.setRoom({ ...this.props.roomState, openCamera: false })
  }
  onOpenCamera = async () => {
    await this.props.setSpamCamera({
      ...this.props.spamCamera,
      disable: true,
    })
    const { settingState } = this.props
    const [width, height] = settingState.maxResolution.split('x') || [1280, 720]
    const camera = settingState.cameras.find((c) => c.active)
    if (camera) {
      try {
        // const { resolution } = await resolutionScan(camera.deviceId)
        // if (!resolution) {
        //   return
        // }
        const constraint = {
          audio: false,
          video: {
            deviceId: camera.deviceId ? { exact: camera.deviceId } : undefined,
            width: { max: Number(width), ideal: Number(width), min: 640 }, // new syntax
            height: { max: Number(height), ideal: Number(height), min: 480 }, // new syntax
          },
        }

        const stream = await navigator.mediaDevices.getUserMedia(constraint)
        if (stream) {
          const video = stream.getVideoTracks()[0]
          const sfuUtils = utilssManager.SFUUtils
          if (!sfuUtils) {
            return
          }
          await sfuUtils.createProducer(video.clone())
          stream.getTracks().forEach((track) => track.stop())
        }
      } catch (error) {
        console.log('constraint err')
      }
    }
    this.props.setRoom({ ...this.props.roomState, openCamera: true })
  }

  onChangeCamera = async (deviceIdOld: any, deviceIdNew: any) => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        deviceId: deviceIdNew ? { exact: deviceIdNew } : undefined,
      },
    })
    const video = stream.getVideoTracks()[0]
    const sfuUtils = utilssManager.SFUUtils
    if (!sfuUtils) {
      return
    }
    sfuUtils.producers.forEach(async (item: any) => {
      if (item.kind === 'video' && item.track.getSettings().deviceId === deviceIdOld) {
        await item.replaceTrack({ track: video })
      }
    })
    this.props.setRoom({ ...this.props.roomState, openCamera: true })
  }
  onWhiteBoard = async () => {
    const { whiteBoard } = this.props
    if (whiteBoard === WHITE_BOARD_TYPE.DISABLE) {
      await this.props.setWhiteBoardReducer(WHITE_BOARD_TYPE.ACTIVE)
      const service = servicesManager.RTC
      service.openWhiteBoard()
    } else {
      await this.props.setWhiteBoardReducer(WHITE_BOARD_TYPE.DISABLE)
    }
  }
  onShare = async () => {
    this.props.setShareLink({ visiable: true })
  }
  clickMicro = async (openMicro: any) => {
    if (openMicro) {
      this.onMicOff()
    } else {
      this.onMicOn()
    }
  }
  clickCamera = async (openCamera: any) => {
    if (openCamera) {
      this.onCloseCamera()
    } else {
      this.onOpenCamera()
    }
  }
  render() {
    const { recording, screenShare } = this.state
    const { settingState, layoutType, whiteBoard, spamMicro, spamCamera } = this.props
    const { openMicro, openCamera } = this.props.roomState
    const activeMicroDevice = settingState.micros.filter((i) => i.active)[0]?.deviceId
    const activeCameraDevice = settingState.cameras.filter((i) => i.active)[0]?.deviceId
    return (
      <Layout className={styles.mainView}>
        <Content style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
          {(layoutType === LAYOUT_TYPE.CENTER_4x4 ||
            layoutType === LAYOUT_TYPE.CENTER_5x5 ||
            layoutType === LAYOUT_TYPE.CENTER_6x6 ||
            layoutType === LAYOUT_TYPE.CENTER_7x7 ||
            layoutType === LAYOUT_TYPE.CENTER_8x8) && (
            <ConferenceView numberOfSmallCameras={getNumberOfSmallCameras(layoutType)} />
          )}
          {layoutType === LAYOUT_TYPE.GRID && <LargeView />}
          {(layoutType === LAYOUT_TYPE.TWO_CAMERA ||
            layoutType === LAYOUT_TYPE.THREE_CAMERA ||
            layoutType === LAYOUT_TYPE.FOUR_CAMERA ||
            layoutType === LAYOUT_TYPE.FIVE_CAMERA ||
            layoutType === LAYOUT_TYPE.SIX_CAMERA ||
            layoutType === LAYOUT_TYPE.SEVEN_CAMERA ||
            layoutType === LAYOUT_TYPE.EIGHT_CAMERA ||
            layoutType === LAYOUT_TYPE.NINE_CAMERA ||
            layoutType === LAYOUT_TYPE.TEN_CAMERA) && (
            <CustomView numberOfSmallCameras={getNumberOfSmallCameras(layoutType)} />
          )}
          {whiteBoard === WHITE_BOARD_TYPE.ACTIVE && <WhiteBoard />}
          {recording && (
            <div
              className={styles.recordingLabel}
              style={{
                animation: 'fadeInAnimation ease 1.5s',
                animationIterationCount: 'infinite',
              }}
            >
              <div className={styles.dot} />
              REC
            </div>
          )}
        </Content>
        <Footer className={styles.footer}>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              // background: '#27292b',
              borderRadius: 8,
              padding: '0 8px',
            }}
          >
            <div
              style={{
                marginLeft: '0px',
                flex: '1 1 0%',
                fontSize: '18px',
                fontWeight: 500,
                color: 'rgb(255, 255, 255)',
              }}
            >
              <Tooltip title={i18n.t('layout')}>
                <Button
                  className={styles.btn}
                  onClick={() => {
                    this.props.setModal({
                      visible: true,
                      top: 50,
                      width: 800,
                      height: 'calc(100vh - 100px)',
                      title: `${t('layout')}`,
                      content: <SelectLayout />,
                    })
                  }}
                  type="primary"
                  shape="circle"
                  icon={<img src="./icons/grid.png" alt="" />}
                />
              </Tooltip>
            </div>
            <div
              style={{
                flex: '1 1 0%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {settingState.isWebsiteHasMicrophonePermissions && openMicro && activeMicroDevice ? (
                <Badge
                  count={
                    <Tooltip title={t('microsetting')}>
                      <Popover
                        trigger={['click']}
                        content={
                          <>
                            <Select
                              style={{ width: 350 }}
                              value={activeMicroDevice}
                              onChange={async (v: string) => {
                                const settingStateNew = { ...settingState }
                                settingStateNew.micros.forEach((element) => {
                                  element.deviceId === v
                                    ? (element.active = true)
                                    : (element.active = false)
                                })
                                this.onChangeMicro(v)
                                await this.props.setSetting(settingStateNew)
                              }}
                            >
                              {settingState.micros.map((i) => (
                                <Select.Option key={i.deviceId} value={i.deviceId}>
                                  {i.label}
                                </Select.Option>
                              ))}
                            </Select>
                            <div
                              style={{
                                width: 350,
                                height: 10,
                                background: 'transparent',
                                marginTop: 15,
                                borderRadius: 3,
                                // clipPath:
                                //     "polygon(100% 0, 0% 100%, 100% 100%)",
                              }}
                            >
                              <div
                                ref={(ref) => (this.microTest = ref)}
                                style={{
                                  width: '10%',
                                  height: '100%',
                                  maxWidth: '100%',
                                  // clipPath: 'polygon(0 20%, 100% 0, 100% 100%, 0 80%)',
                                  background:
                                    'repeating-linear-gradient( 90deg,#fff,#fff 1px,#465298 1px,#465298 4px)',
                                }}
                              />
                            </div>
                          </>
                        }
                        title={t('microsetting')}
                      >
                        <SettingOutlined
                          style={{ color: '#fff', top: 10, right: 10, cursor: 'pointer' }}
                        />
                      </Popover>
                    </Tooltip>
                  }
                >
                  <Tooltip
                    title={spamMicro.disable ? t('spam') : openMicro ? t('microoff') : t('microon')}
                  >
                    <Button
                      ref={(ref) => {
                        this.submit = ref
                      }}
                      onClick={() => {
                        !spamMicro.disable && this.clickMicro(openMicro)
                      }}
                      className={styles.btn}
                      type="primary"
                      shape="circle"
                      icon={
                        openMicro ? (
                          <img src="./icons/mic.png" alt="" />
                        ) : (
                          <img src="./icons/mic-off.png" alt="" />
                        )
                      }
                    />
                  </Tooltip>
                </Badge>
              ) : (
                <>
                  {settingState.isWebsiteHasMicrophonePermissions && (
                    <Tooltip
                      title={
                        spamMicro.disable ? t('spam') : openMicro ? t('microoff') : t('microon')
                      }
                    >
                      <Button
                        onClick={() => {
                          !spamMicro.disable && this.clickMicro(openMicro)
                        }}
                        className={styles.btn}
                        type="primary"
                        shape="circle"
                        icon={
                          openMicro ? (
                            <img src="./icons/mic.png" alt="" />
                          ) : (
                            <img src="./icons/mic-off.png" alt="" />
                          )
                        }
                      />
                    </Tooltip>
                  )}
                </>
              )}

              {settingState.isWebsiteHasWebcamPermissions && openCamera && activeCameraDevice ? (
                <Badge
                  count={
                    <Tooltip title={t('camerasetting')}>
                      <Popover
                        trigger={['click']}
                        content={
                          <>
                            <Select
                              style={{ width: 350 }}
                              value={activeCameraDevice}
                              onChange={async (deviceId: string) => {
                                const settingStateNew = { ...settingState }
                                settingStateNew.cameras.forEach((element) => {
                                  element.deviceId === deviceId
                                    ? (element.active = true)
                                    : (element.active = false)
                                })
                                this.onChangeCamera(activeCameraDevice, deviceId)
                                await this.props.setSetting(settingStateNew)
                              }}
                            >
                              {settingState.cameras.map((i) => (
                                <Select.Option key={i.deviceId} value={i.deviceId}>
                                  {i.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </>
                        }
                        title={t('camerasetting')}
                      >
                        <SettingOutlined
                          style={{ color: '#fff', top: 10, right: 10, cursor: 'pointer' }}
                        />
                      </Popover>
                    </Tooltip>
                  }
                >
                  <Tooltip
                    title={
                      spamCamera.disable ? t('spam') : openCamera ? t('cameraoff') : t('cameraon')
                    }
                  >
                    <Button
                      onClick={() => {
                        !spamCamera.disable && this.clickCamera(openCamera)
                      }}
                      className={styles.btn}
                      type="primary"
                      shape="circle"
                      icon={
                        openCamera ? (
                          <img src="./icons/video-camera.png" alt="" />
                        ) : (
                          <img src="./icons/no-video.png" alt="" />
                        )
                      }
                    />
                  </Tooltip>
                </Badge>
              ) : (
                <>
                  {settingState.isWebsiteHasWebcamPermissions && (
                    <Tooltip
                      title={
                        spamCamera.disable ? t('spam') : openCamera ? t('cameraoff') : t('cameraon')
                      }
                    >
                      <Button
                        onClick={() => {
                          !spamCamera.disable && this.clickCamera(openCamera)
                        }}
                        className={styles.btn}
                        type="primary"
                        shape="circle"
                        icon={
                          openCamera ? (
                            <img src="./icons/video-camera.png" alt="" />
                          ) : (
                            <img src="./icons/no-video.png" alt="" />
                          )
                        }
                      />
                    </Tooltip>
                  )}
                </>
              )}
              {!mobileAndTabletCheck() && (
                <>
                  {!screenShare ? (
                    <Tooltip title={t('sharescreen')}>
                      <Button
                        onClick={this.onScreenShareStartClick}
                        className={styles.btn}
                        type="primary"
                        shape="circle"
                        style={{ background: '' }}
                        icon={<img src="./icons/screen.png" alt="" />}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={t('sharescreenoff')}>
                      <Button
                        onClick={this.onScreenShareStopClick}
                        className={styles.btn}
                        type="primary"
                        shape="circle"
                        style={{ background: '#1890ff' }}
                        icon={<img src="./icons/screen.png" alt="" />}
                      />
                    </Tooltip>
                  )}
                  {!recording ? (
                    <Tooltip title={t('recoderscreen')}>
                      <Button
                        onClick={this.onRecordStartClick}
                        className={styles.btn}
                        type="primary"
                        shape="circle"
                        style={{ background: '' }}
                        icon={<img src="./icons/play.png" alt="" />}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={t('recoderscreenoff')}>
                      <Button
                        onClick={this.onRecordStopClick}
                        className={styles.btn}
                        type="primary"
                        shape="circle"
                        style={{ background: '#ff4d4f' }}
                        icon={<img src="./icons/stop.png" alt="" />}
                      />
                    </Tooltip>
                  )}
                </>
              )}
              <Tooltip title={t('openwhiteboard')}>
                <Button
                  onClick={this.onWhiteBoard}
                  className={styles.btn}
                  type="primary"
                  shape="circle"
                  icon={<img src="./icons/tools/writeboard.svg" alt="" />}
                />
              </Tooltip>
              <Tooltip title={t('setting')}>
                <Button
                  onClick={() => {
                    store.dispatch(setSettingTool({ visible: true }))
                  }}
                  className={styles.btn}
                  type="primary"
                  shape="circle"
                  icon={<img src="./icons/settings.png" alt="" />}
                />
              </Tooltip>
              <Tooltip title={t('closeroom')}>
                <Button
                  onClick={() => {
                    window.location.reload()
                  }}
                  className={styles.btn}
                  type="primary"
                  shape="round"
                  style={{ background: '#f44336', width: 60, borderRadius: 20 }}
                  icon={
                    <img
                      src="./icons/phone-call-end.png"
                      style={{ width: 26, height: 26 }}
                      alt=""
                    />
                  }
                />
              </Tooltip>
            </div>
            <div style={{ flex: '1 1 0%', display: 'flex', justifyContent: 'flex-end' }}>
              {this.props.vertical !== true && (
                <Tooltip title={t('paticipants')}>
                  <Button
                    className={styles.btn}
                    onClick={this.onShowParticipants}
                    type="primary"
                    shape="circle"
                    icon={<img src="./icons/multiple-users-silhouette.png" alt="" />}
                  />
                </Tooltip>
              )}
              <Tooltip title="Chat">
                <Badge style={{ top: 10, right: 10 }} count={this.props.messageState.length}>
                  <Button
                    className={styles.btn}
                    onClick={this.onShowChat}
                    type="primary"
                    shape="circle"
                    icon={<img src="./icons/comment.png" alt="" />}
                  />
                </Badge>
              </Tooltip>
              <Tooltip title={t('share')}>
                <Button
                  onClick={this.onShare}
                  className={styles.btn}
                  type="primary"
                  shape="circle"
                  icon={<img src="./icons/share_white_24dp.svg" alt="" />}
                />
              </Tooltip>
            </div>
          </div>
        </Footer>
      </Layout>
    )
  }
}
const mapStateToProps = (state: any) => ({
  centralPoint: state.centralPoint,
  connectionPoint: state.connectionPoint,
  layout: state.layout,
  roomState: state.roomState,
  settingState: state.settingState,
  messageState: state.messageState,
  layoutType: state.layoutType,
  whiteBoard: state.whiteBoard,
  shareLink: state.shareLink,
  spamCamera: state.spamCamera,
  spamMicro: state.spamMicro,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLayout: (data: ILayout) => dispatch(setLayout(data)),
    setLargeView: (data: Array<ILargeView>) => dispatch(setLargeView(data)),
    setImages: (data: Array<string>) => dispatch(setImages(data)),
    setRoom: (data: IRoom) => dispatch(setRoom(data)),
    setSetting: (data: any) => dispatch(setSetting(data)),
    setModal: (data: IModal) => dispatch(setModal(data)),
    setWhiteBoardReducer: (data: string) => dispatch(setWhiteBoardReducer(data)),
    setShareLink: (data: any) => dispatch(setShareLink(data)),
    setSpamCamera: (data: any) => dispatch(setSpamCamera(data)),
    setSpamMicro: (data: any) => dispatch(setSpamMicro(data)),
    // setCentralPoint: (data: ICentralPoint | null) => dispatch(setCentralPoint(data)),
    // setConnectionPoint: (data: Array<IConnectPoint>) => dispatch(setConnectionPoint(data)),
    // setLargeView: (data: Array<ILargeView>) => dispatch(setLargeView(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainView)
