import { CloseOutlined, TableOutlined } from '@ant-design/icons'
import { Button, Layout, Tooltip } from 'antd'
import { Content, Header } from 'antd/lib/layout/layout'
import { t } from 'i18next'
import { Component } from 'react'
import { connect } from 'react-redux'
import GridParticipants from '../../components/GridParticipants/GridParticipants'
import { ILayout } from '../../models/reducers/layout.model'
import { setLayout } from '../../reducers/layoutReducer'
import styles from './Participants.module.scss'
type Props = {
  layout: ILayout

  setLayout(data: ILayout): void
}

type State = {}

export class Participants extends Component<Props, State> {
  state = {}
  onClose = () => {
    const { layout } = this.props
    this.props.setLayout({
      ...layout,
      widthParticipants: 0,
    })
  }
  setGridCamera = () => {
    const { layout } = this.props
    this.props.setLayout({
      ...layout,
      widthParticipants: layout.widthParticipants === '100%' ? 400 : '100%',
    })
  }

  render() {
    return (
      <Layout className={styles.wrapperParticipants}>
        <Header className={styles.header}>
          <div className={styles.headerLeft}>{t("paticipants")}</div>
          <div className={styles.headerRight}>
            <Tooltip title={t("fullpaticipants")}>
              <Button icon={<TableOutlined />} onClick={this.setGridCamera} />
            </Tooltip>
            <Button icon={<CloseOutlined />} onClick={this.onClose} />
          </div>
        </Header>
        <Content className={styles.content}>
          <GridParticipants />
        </Content>
      </Layout>
    )
  }
}

const mapStateToProps = (state: any) => ({
  layout: state.layout,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLayout: (data: ILayout) => dispatch(setLayout(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Participants)
