import { Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Component } from 'react'
// import style from "./MHomePage.module.scss"
import Div100vh from 'react-div-100vh'
import { connect } from 'react-redux'
import { io } from 'socket.io-client'
import MMainView from '../Layout/MMainView/MMainView'
import SettingTool from '../Layout/Mobile/Setting/MSettingTool'
import Loading from '../components/Loading/Loading'
import { ICentralPoint } from '../models/reducers/centralPoint.model'
import { IConnectPoint } from '../models/reducers/connectionPoint.model'
import { IRoom } from '../models/reducers/room.model'
import { ISetting } from '../models/reducers/setting.model'
import { setCentralPoint } from '../reducers/centralPointReducer'
import { setConnectionPoint } from '../reducers/connectionPointReducer'
import { setSetting } from '../reducers/settingReducer'
import { server } from '../server/server'
import { RTCService } from '../services/RTCService'
import { servicesManager } from '../services/servicesManager'
import { SFU } from '../utils/SFU.util'
import { listUserToBridgetList } from '../utils/listUserToBridgetList'
import { RoomListener } from '../utils/room.util'
import { RTCListener } from '../utils/rtc.util'
import { utilssManager } from '../utils/utilsManager'
type Props = {
  roomState: IRoom
  settingState: ISetting
  setSetting(data: ISetting): void
  setCentralPoint(data: ICentralPoint | null): void
  setConnectionPoint(data: Array<IConnectPoint>): void
}

type State = {
  loading: boolean
}

class MHomePage extends Component<Props, State> {
  videoRef: HTMLVideoElement | null = null
  videoStream: MediaStream | null = null
  state = {
    loading: true,
  }
  componentDidMount = async () => {
    const { roomState } = this.props
    const socket = io(server.RTC, {
      closeOnBeforeunload: true,
    })
    const rtcService = new RTCService(server.RTC)
    servicesManager.RTC = rtcService
    const roomUtils = new RoomListener({ socket, rtcService, room: roomState }, async () => {
      const mediaRTC = new window.isg_video_conference.MediaRTC()
      const sfuUtil = new SFU({ mediaLibrary: mediaRTC, rtcService })
      await sfuUtil.initialization()
      const rtcUtil = new RTCListener({ socket, sfuUtil })
      utilssManager.RTCUtils = rtcUtil
      utilssManager.SFUUtils = sfuUtil
      utilssManager.RoomUtils = roomUtils
      utilssManager.me = socket.id
      const listUser = await rtcService.getAllUser()
      const { centralPoint, connectionPoint } = await listUserToBridgetList(listUser, sfuUtil)
      await this.props.setCentralPoint(centralPoint)
      await this.props.setConnectionPoint(connectionPoint)
      await this.sendMicCamera(sfuUtil, roomState.openCamera, roomState.openMicro)
      setTimeout(async () => {
        await this.setState({ loading: false })
      }, 1000)
    })
  }
  sendMicCamera = async (sfuUtil: SFU, openCamera: boolean, openMicro: boolean) => {
    try {
      const { settingState } = this.props
      const [width, height] = settingState.maxResolution.split('x') || [1280, 720]
      const camera = settingState.cameras.find((c) => c.active)
      if (camera) {
        const constraint = camera.active
          ? {
              audio: false,
              video: {
                deviceId: camera.deviceId ? { exact: camera.deviceId } : undefined,
                width: { max: Number(width), ideal: Number(width), min: 640 }, // new syntax
                height: { max: Number(height), ideal: Number(height), min: 480 }, // new syntax
              },
            }
          : {
              audio: false,
              video: {
                deviceId: camera.deviceId ? { exact: camera.deviceId } : undefined,
                width: { max: 640, ideal: 640, min: 320 }, // new syntax
                height: { max: 480, ideal: 480, min: 480 }, // new syntax
              },
            }

        const stream = await navigator.mediaDevices.getUserMedia(constraint)
        if (stream) {
          const video = stream.getVideoTracks()[0]
          openCamera && (await sfuUtil.createProducer(video.clone()))
          stream.getTracks().forEach((track) => track.stop())
        }
      }
      const activeMicro = settingState.micros.find((mic) => mic.active === true)
      // audio
      const streamAudio = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: camera ? { exact: camera.deviceId } : undefined,
        },
        audio: {
          deviceId: activeMicro ? { exact: activeMicro.deviceId } : undefined,
        },
      })
      const audio = streamAudio.getAudioTracks()[0]
      openMicro && (await sfuUtil.createProducer(audio))
      streamAudio.getTracks().forEach((track) => track.stop())
    } catch (error) {}
  }
  render() {
    const { loading } = this.state
    return !loading ? (
      <Div100vh style={{ width: '100%' }}>
        <Layout
          style={{
            width: '100%',
            height: '100%',
            background: 'var(--background-1)',
          }}
        >
          <Content>
            <SettingTool />
            <MMainView />
          </Content>
        </Layout>
      </Div100vh>
    ) : (
      <Loading />
    )
  }
}
const mapStateToProps = (state: any) => {
  return {
    roomState: state.roomState,
    settingState: state.settingState,
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    setCentralPoint: (data: ICentralPoint | null) => dispatch(setCentralPoint(data)),
    setConnectionPoint: (data: Array<IConnectPoint>) => dispatch(setConnectionPoint(data)),
    setSetting: (data: ISetting) => dispatch(setSetting(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MHomePage)
