export const ACTION_TYPES = {
  SET_LAYOUT_TYPE: 'SET_LAYOUT_TYPE',
}

interface IAction {
  type: string
  payload: any
}

export const LAYOUT_TYPE = {
  GRID: 'GRID',
  CONFERENCE: 'CONFERENCE',
  TWO_CAMERA: 'TWO_CAMERA',
  THREE_CAMERA: 'THREE_CAMERA',
  FOUR_CAMERA: 'FOUR_CAMERA',
  FIVE_CAMERA: 'FIVE_CAMERA',
  SIX_CAMERA: 'SIX_CAMERA',
  SEVEN_CAMERA: 'SEVEN_CAMERA',
  EIGHT_CAMERA: 'EIGHT_CAMERA',
  NINE_CAMERA: 'NINE_CAMERA',
  TEN_CAMERA: 'TEN_CAMERA',
  CENTER_4x4: 'CENTER_4x4',
  CENTER_5x5: 'CENTER_5x5',
  CENTER_6x6: 'CENTER_6x6',
  CENTER_7x7: 'CENTER_7x7',
  CENTER_8x8: 'CENTER_8x8',
}

const getNumberOfSmallCameras = (layout: string) => {
  switch (layout) {
    case LAYOUT_TYPE.TWO_CAMERA:
      return 2
    case LAYOUT_TYPE.THREE_CAMERA:
      return 3
    case LAYOUT_TYPE.FOUR_CAMERA:
      return 4
    case LAYOUT_TYPE.FIVE_CAMERA:
      return 5
    case LAYOUT_TYPE.SIX_CAMERA:
      return 6
    case LAYOUT_TYPE.SEVEN_CAMERA:
      return 7
    case LAYOUT_TYPE.EIGHT_CAMERA:
      return 8
    case LAYOUT_TYPE.NINE_CAMERA:
      return 9
    case LAYOUT_TYPE.TEN_CAMERA:
      return 10
    case LAYOUT_TYPE.CENTER_4x4:
      return 4 * 4 - (4 * 4 - 4 * 4 + 4)
    case LAYOUT_TYPE.CENTER_5x5:
      return 5 * 5 - (5 * 5 - 4 * 5 + 4)
    case LAYOUT_TYPE.CENTER_6x6:
      return 6 * 6 - (6 * 6 - 4 * 6 + 4)
    case LAYOUT_TYPE.CENTER_7x7:
      return 7 * 7 - (7 * 7 - 4 * 7 + 4)
    case LAYOUT_TYPE.CENTER_8x8:
      return 8 * 8 - (8 * 8 - 4 * 8 + 4)
    default:
      return 0
  }
}

const initialize: string = LAYOUT_TYPE.GRID

const layoutTypeReducer = (state = initialize, action: IAction) => {
  switch (action.type) {
    case ACTION_TYPES.SET_LAYOUT_TYPE:
      state = action.payload
      return state
    default:
      return state
  }
}

const setLayoutType = (data: string) => {
  return {
    type: ACTION_TYPES.SET_LAYOUT_TYPE,
    payload: data,
  }
}

export { layoutTypeReducer, setLayoutType, getNumberOfSmallCameras }
