import { Badge, Button, Layout, Popover, Tooltip } from 'antd'
import { Content, Footer } from 'antd/lib/layout/layout'
import { Component } from 'react'
import { connect } from 'react-redux'
import MChatComponent from '../../components/ChatComponent/MChatComponent'
import MobileView from '../../components/Views/Mobile/MobileView'
import ShareLink from '../../components/shareLink/shareLink'
import { ILargeView } from '../../models/reducers/largeView.model'
import { ILayout } from '../../models/reducers/layout.model'
import { ILayoutMobile } from '../../models/reducers/layoutMobile'
import { IRoom } from '../../models/reducers/room.model'
import { ISetting } from '../../models/reducers/setting.model'
import { ISpam } from '../../models/reducers/spam.model'
import { setLargeView } from '../../reducers/largeviewReducer'
import { setLayoutMobile } from '../../reducers/layoutMobile'
import { setLayout } from '../../reducers/layoutReducer'
import { setRoom } from '../../reducers/roomReducer'
import { setSetting } from '../../reducers/settingReducer'
import { setSettingTool } from '../../reducers/settingTool'
import { setSpamCamera } from '../../reducers/spamCamera'
import { setSpamMicro } from '../../reducers/spamMicro'
import { store } from '../../redux/store'
import { utilssManager } from '../../utils/utilsManager'
import style from './Mstyle.module.scss'
type Props = {
    roomState: IRoom
    settingState: ISetting
    setRoom(data: IRoom): void
    setSetting(data: ISetting): void
    layout: ILayout
    setLayout(data: ILayout): void
    setLargeView(data: Array<ILargeView>): void
    messageState: Array<any>
    layoutMobile: ILayoutMobile
    setLayoutMobile(data: ILayoutMobile): void
    spamMicro: ISpam
    spamCamera: ISpam
    setSpamMicro(data: ISpam): void
    setSpamCamera(data: ISpam): void
}
type State = {
    fullview: boolean
    showChat: boolean
    blockSpam: boolean
}
class MMainView extends Component<Props, State> {
    state = {
        fullview: true,
        showChat: false,
        blockSpam: false,
    }
    microTest: HTMLElement | null = null
    audioStream: MediaStream | null = null
    videoStream: MediaStream | null = null
    videoRef: HTMLVideoElement | null = null
    microphone: any
    analyser: any
    scriptProcessor: any
    button: HTMLElement | null = null
    componentDidMount = async () => {
        const { settingState } = this.props
        const activeMic = settingState.micros.find((i) => i.active)
        const activeCamera = settingState.cameras.find((i) => i.active)
        if (activeMic) {
            this.onChangeMicro(activeMic?.deviceId)
        }
        if (activeCamera) {
            this.onChangeCamera(activeCamera.deviceId)
        }
    }
    componentWillUnmount = () => {
        if (this.audioStream) {
            this.audioStream.getTracks().forEach((track) => track.stop())
        }
        this.microphone && this.microphone.disconnect()
        this.analyser && this.analyser.disconnect()
        this.scriptProcessor && this.scriptProcessor.disconnect()
    }
    onMicOff = () => {
        const { settingState } = this.props
        const activeMicroDevide = settingState.micros.filter((i) => i.active)[0].deviceId
        const sfuUtils = utilssManager.SFUUtils
        if (!sfuUtils) {
            return
        }
        sfuUtils.producers.forEach(async (item: any) => {
            if (item.kind === 'audio' && item.track.getSettings().deviceId === activeMicroDevide) {
                await sfuUtils.closeProducer(item.id)
                item.track.stop()
            }
        })
        this.props.setRoom({ ...this.props.roomState, openMicro: false })
    }
    onMicOn = async () => {
        await this.props.setSpamMicro({
            ...this.props.spamMicro, disable: true
        })
        const { settingState } = this.props
        const micro = settingState.micros.find((c) => c.active)
        if (micro) {
            try {
                // const { resolution } = await resolutionScan(camera.deviceId)
                // if (!resolution) {
                //   return
                // }
                const constraint = {
                    audio: {
                        deviceId: micro ? { exact: micro.deviceId } : undefined,
                    },
                    video: false,
                }
                const stream = await navigator.mediaDevices.getUserMedia(constraint)
                if (stream) {
                    const micros = stream.getAudioTracks()[0]
                    const sfuUtils = utilssManager.SFUUtils
                    if (!sfuUtils) {
                        return
                    }
                    await sfuUtils.createProducer(micros.clone())
                    stream.getTracks().forEach((track) => track.stop())
                }
            } catch (error) {
                console.log('constraint err')
            }
        }
        this.props.setRoom({ ...this.props.roomState, openMicro: true })
        setTimeout(async () => {
            await this.props.setSpamMicro({
                ...this.props.spamMicro, disable: false
            })
        }, 3000);
    }

    onChangeMicro = async (deviceId: string) => {
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: {
                deviceId: deviceId ? { exact: deviceId } : undefined,
            },
            video: false,
        })
        const audio = stream.getAudioTracks()[0]
        const sfuUtils = utilssManager.SFUUtils
        if (!sfuUtils) {
            return
        }
        sfuUtils.producers.forEach(async (item: any) => {
            if (item.kind === 'audio') {
                await item.replaceTrack({ track: audio })
            }
        })
        // this.props.setRoom({ ...this.props.roomState, openMicro: true })
    }
    onShowChat = () => {
        this.setState({
            showChat: !this.state.showChat
        })
    }
    onCloseCamera = async () => {
        const { settingState } = this.props
        const activeCameraDevice = settingState.cameras.filter((i) => i.active)[0].deviceId
        const sfuUtils = utilssManager.SFUUtils
        if (!sfuUtils) {
            return
        }
        sfuUtils.producers.forEach(async (item: any) => {
            if (item.kind === 'video' && item.track.getSettings().deviceId === activeCameraDevice) {
                await sfuUtils.closeProducer(item.id)
                item.track.stop()
            }
        })
        this.props.setRoom({ ...this.props.roomState, openCamera: false })
    }
    onOpenCamera = async () => {
        await this.props.setSpamCamera({
            ...this.props.spamCamera, disable: true
        })
        const { settingState } = this.props
        const [width, height] = settingState.maxResolution.split('x') || [1280, 720]
        const camera = settingState.cameras.find((c) => c.active)
        if (camera) {
            try {
                // const { resolution } = await resolutionScan(camera.deviceId)
                // if (!resolution) {
                //   return
                // }
                const constraint = {
                    audio: false,
                    video: {
                        deviceId: camera.deviceId ? { exact: camera.deviceId } : undefined,
                        width: { max: Number(width), ideal: Number(width), min: 640 }, // new syntax
                        height: { max: Number(height), ideal: Number(height), min: 480 }, // new syntax
                    },
                }
                const stream = await navigator.mediaDevices.getUserMedia(constraint)
                if (stream) {
                    const video = stream.getVideoTracks()[0]
                    const sfuUtils = utilssManager.SFUUtils
                    if (!sfuUtils) {
                        return
                    }
                    await sfuUtils.createProducer(video.clone())
                    stream.getTracks().forEach((track) => track.stop())
                }
            } catch (error) {
                console.log('constraint err')
            }
        }
        this.props.setRoom({ ...this.props.roomState, openCamera: true })
    }
    onChangeCamera = async (deviceId: any) => {
        const stream = await navigator.mediaDevices.getUserMedia({
            video: {
                deviceId: deviceId ? { exact: deviceId } : undefined,
            },
            audio: false,
        })
        const video = stream.getVideoTracks()[0]
        const sfuUtils = utilssManager.SFUUtils
        if (!sfuUtils) {
            return
        }
        sfuUtils.producers.forEach(async (item: any) => {
            if (item.kind === 'video') {
                await item.replaceTrack({ track: video })
            }
        })
        // this.props.setRoom({ ...this.props.roomState, openCamera: true })
    }
    clickMicro = async (openMicro: any) => {
        if (openMicro) {
            this.onMicOff()
        } else {
            this.onMicOn()
        }
    }
    clickCamera = async (openCamera: any) => {
        if (openCamera) {
            this.onCloseCamera()
        } else {
            this.onOpenCamera()
        }
    }
    render() {
        const { row } = this.props.layoutMobile
        const { openMicro, openCamera } = this.props.roomState
        const { roomState, spamCamera, spamMicro } = this.props
        return (
            <Layout
                className={style.mainView}
                style={{ width: '100%', height: '100%', background: 'var(--background-1)' }}
            >
                <Content>
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            padding: 0,
                            margin: 0,
                            display: `${this.state.showChat ? '' : 'none'}`
                        }}
                    >
                        <MChatComponent onShowChat={this.onShowChat} />
                    </div>
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            padding: 0,
                            margin: 0,
                            display: `${this.state.showChat ? 'none' : ''}`
                        }}
                    >
                        <MobileView row={row} />
                        {/* <div
                            style={{
                                display: 'none'
                            }}
                        >
                            <MChatComponent onShowChat={this.onShowChat} />
                        </div> */}
                    </div>
                </Content>
                <Footer className={style.footer}>
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            background: 'transanfer',
                            borderRadius: 8,
                            padding: '0 8px',
                            justifyContent: 'center'
                        }}
                    >
                        <Button
                            className={style.btn}
                            onClick={() => {
                                this.props.setLayoutMobile({
                                    row: row === 1 ? 2 : 1
                                })
                            }}
                            type="primary"
                            shape="circle"
                            icon={<img src="./icons/grid.png" alt="" />}
                        />
                        <Button
                            onClick={() => {
                                !spamMicro.disable && this.clickMicro(openMicro)
                            }}
                            className={style.btn}
                            type="primary"
                            shape="circle"
                            icon={
                                openMicro ? (
                                    <img src="./icons/mic.png" alt="" />
                                ) : (
                                    <img src="./icons/mic-off.png" alt="" />
                                )
                            }
                        />
                        <Button
                            onClick={() => {
                                !spamCamera.disable && this.clickCamera(openCamera)
                            }}
                            className={style.btn}
                            type="primary"
                            shape="circle"
                            icon={
                                openCamera ? (
                                    <img src="./icons/video-camera.png" alt="" />
                                ) : (
                                    <img src="./icons/no-video.png" alt="" />
                                )
                            }
                        />
                        <Tooltip title="Chat">
                            <Badge style={{ top: 10, right: 10 }} count={this.props.messageState.length}>
                                <Button
                                    className={style.btn}
                                    onClick={this.onShowChat}
                                    type="primary"
                                    shape="circle"
                                    icon={<img src="./icons/comment.png" alt="" />}
                                />
                            </Badge>
                        </Tooltip>
                        <Popover content={<ShareLink roomState={roomState} />} title="Chia sẻ" trigger="click">
                            <Tooltip title="Share">
                                <Button
                                    className={style.btn}
                                    type="primary"
                                    shape="circle"
                                    icon={<img src="./icons/share_white_24dp.svg" alt="" />}
                                />
                            </Tooltip>
                        </Popover>
                        <Tooltip title="Setting">
                            <Button
                                onClick={() => {
                                    store.dispatch(setSettingTool({ visible: true }))
                                }}
                                className={style.btn}
                                type="primary"
                                shape="circle"
                                icon={<img src="./icons/settings.png" alt="" />}
                            />
                        </Tooltip>
                        <Button
                            onClick={() => {
                                window.location.reload()
                            }}
                            className={style.btn}
                            type="primary"
                            shape="round"
                            style={{ background: '#f44336', borderRadius: 20 }}
                            icon={
                                <img src="./icons/phone-call-end.png" style={{ width: 26, height: 26 }} alt="" />
                            }
                        />
                    </div>
                </Footer>
            </Layout>
        )
    }
}
const mapStateToProps = (state: any) => ({
    roomState: state.roomState,
    settingState: state.settingState,
    layout: state.layout,
    messageState: state.messageState,
    layoutMobile: state.layoutMobile,
    spamMicro: state.spamMicro,
    spamCamera: state.spamCamera
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        setRoom: (data: IRoom) => dispatch(setRoom(data)),
        setSetting: (data: ISetting) => dispatch(setSetting(data)),
        setLayout: (data: ILayout) => dispatch(setLayout(data)),
        setLargeView: (data: Array<ILargeView>) => dispatch(setLargeView(data)),
        setLayoutMobile: (data: ILayoutMobile) => dispatch(setLayoutMobile(data)),
        setSpamMicro: (data: ISpam) => dispatch(setSpamMicro(data)),
        setSpamCamera: (data: ISpam) => dispatch(setSpamCamera(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MMainView)
