import { Button, Modal } from 'antd'
import { t } from "i18next"
import { Component } from 'react'
import { connect } from 'react-redux'
import { defaultConfirm, IConfirm } from '../../models/reducers/confirmWhiteBoard.model'
import { setConfirmReducer } from '../../reducers/confirmWhiteBoardReducer'
import { setWhiteBoardReducer, WHITE_BOARD_TYPE } from '../../reducers/whiteboardReducer'
import './confirmWhiteBoard.scss'
type Props = {
    confirmReducer: IConfirm
    setConfirmReducer(data: any): void
    whiteBoard: string
    setWhiteBoard(data: string): void
}
type State = {}
class ConFirmWhiteBoard extends Component<Props, State> {
    state = {}
    openWhiteBoard = async () => {
        const { whiteBoard } = this.props
        if (whiteBoard === WHITE_BOARD_TYPE.DISABLE) {
            await this.props.setWhiteBoard(WHITE_BOARD_TYPE.ACTIVE)
        } else {
            await this.props.setWhiteBoard(WHITE_BOARD_TYPE.DISABLE)
        }
        this.props.setConfirmReducer(defaultConfirm)
        console.log(this.props.whiteBoard);
    }
    Cancel = async () => {
        await this.props.setConfirmReducer(defaultConfirm)
    }
    render() {
        const { confirmReducer } = this.props
        return (
            <Modal
                className="modal-basic"
                visible={confirmReducer.visible}
                width={confirmReducer.width}
                closable={false}
                style={{ height: confirmReducer.height, top: confirmReducer.top }}
                onCancel={() => this.props.setConfirmReducer(defaultConfirm)}
            >
                <div
                    className='title'
                >
                    <p>{t("confirmwhiteboard")}</p>
                </div>
                <div className="tool">
                    <Button
                        className='Open'
                        onClick={() => {
                            this.openWhiteBoard()
                        }}>{t("open")}</Button>
                    <Button
                        className='Close'
                        onClick={() => {
                            this.Cancel()
                        }}
                    >
                        {t("cancel")}
                    </Button>
                </div>

            </Modal>
        )
    }
}

const mapStateToProps = (state: any) => ({
    confirmReducer: state.confirmReducer,
    whiteBoard: state.whiteBoard
})
const mapDispatchToProps = (dispatch: any) => {
    return {
        setConfirmReducer: (data: IConfirm) => dispatch(setConfirmReducer(data)),
        setWhiteBoard: (data: any) => dispatch(setWhiteBoardReducer(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConFirmWhiteBoard)
