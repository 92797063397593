export interface IConfirm {
    visible: boolean
    top: any
    width: any
    height: any
    title: any
    content: any
  }
  
  export const defaultConfirm = {
    visible: false,
    top: 50,
    width: 200,
    height: 'auto',
    title: 'Open White Board',
    content: null,
  }
  
