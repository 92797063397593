import { RISService } from './RISService'
import { RTCService } from './RTCService'
import { SCUService } from './SCUService'

export interface IServicesManager {
  RTC: RTCService
  RIS: RISService
  SCU: SCUService
}

export const defaultServicesManager: IServicesManager = {
  RTC: new RTCService(''),
  RIS: new RISService(''),
  SCU: new SCUService(''),
}

export const servicesManager: IServicesManager = defaultServicesManager
