import { Component } from 'react'
import { connect } from 'react-redux'
import Room from '../../Layout/Room/Room'
import Setting from '../../Layout/Setting/Setting'
import Loading from '../../components/Loading/Loading'
import { IConfigAdmin } from '../../models/reducers/configAdmin.model'
import { IPatient } from '../../models/reducers/patient.model'
import { IRoom } from '../../models/reducers/room.model'
import { setAuth } from '../../reducers/authenticateReducer'
import { setConfigAdmin } from '../../reducers/configAdminReducer'
import { setPatient } from '../../reducers/patientReducer'
import { setRoom } from '../../reducers/roomReducer'
import { server, IServer } from '../../server/server'
import { getConfig } from '../../utils/storeManager'
import HomePage from '../HomePage'
type Props = {
  setAuth(data: boolean): void
  setConfigAdmin(data: Array<IConfigAdmin>): void
  setPatient(data: IPatient): void
  setRoomState(data: IRoom): void
  configAdminState: Array<IConfigAdmin>
  isAuthenticate: boolean
  roomState: IRoom
  hasVideo: boolean
  hasAudio: boolean
  screenShare: boolean
}

type State = {
  loading: boolean
}

class StartPage extends Component<Props, State> {
  state: Readonly<State> = {
    loading: true,
  }

  onShowHomePage = () => {
    this.props.setAuth(true)
  }

  componentDidMount = async () => {
    const configServer: IServer = getConfig()
    server.RTC = configServer.RTC
    const room = this.getParamsData()
    const roomState = {
      ...this.props.roomState,
      roomname: room.roomname,
      username: room.username,
      password: room.password,
    }
    await this.props.setRoomState(roomState)
    this.setState({
      loading: false,
    })
  }

  getParamsData = () => {
    const url = new URL(window.location.href)
    const param = url.searchParams
    const paramData: any = param.get('params')
    if (!paramData) {
      const room = {
        roomname: '',
        username: '',
        password: null,
      }
      return room
    } else {
      const decodeParams = decodeURIComponent(window.atob(paramData))
      return JSON.parse(decodeParams)
    }
  }

  render() {
    const { isAuthenticate } = this.props
    return (
      <div
        className="start-page"
        style={{
          width: '100%',
          height: '100vh',
          background: 'var(--background-1)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        {!isAuthenticate ? (
          <div
            className="boxed"
            style={{
              display: 'flex',
              width: 800,
              maxWidth: 800,
              maxHeight: 560,
              background: 'transparent',
              border: '4px solid',
              borderImageSlice: 1,
              borderImageSource: 'linear-gradient(to right bottom, #6a5af9, #f62682) ',
            }}
          >
            {this.state.loading ? (
              <Loading bg={'transparent'} />
            ) : (
              <>
                <Setting
                  hasVideo={this.props.hasVideo}
                  hasAudio={this.props.hasAudio}
                  hasShareScreen={this.props.screenShare}
                />
                <Room
                  onShowHomePage={this.onShowHomePage}
                  hasVideo={this.props.hasVideo}
                  hasAudio={this.props.hasAudio}
                />
              </>
            )}
          </div>
        ) : (
          <HomePage />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  configAdminState: state.configAdminState,
  isAuthenticate: state.isAuthenticate,
  roomState: state.roomState,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setConfigAdmin: (data: Array<IConfigAdmin>) => dispatch(setConfigAdmin(data)),
    setPatient: (data: IPatient) => dispatch(setPatient(data)),
    setAuth: (data: boolean) => dispatch(setAuth(data)),
    setRoomState: (data: IRoom) => dispatch(setRoom(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StartPage)
