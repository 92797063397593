import { message } from 'antd'
import { t } from 'i18next'
import { Component } from 'react'

interface IProps {
  onDoubleClick?(): void
  onDragOver?(e: any): void
  onDrop?(e: any, data: any): void
  dropKey: Array<string>
  style?: any
  className?: string
}
interface IState {}

class DropExtend extends Component<IProps, IState> {
  state = {}

  render() {
    return (
      <div
        onDoubleClick={this.props.onDoubleClick}
        className={this.props.className}
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          ...this.props.style,
        }}
        onDrop={(e: any) => {
          e.preventDefault()
          const data = e.dataTransfer.getData('data')
          const dataParse = JSON.parse(data)
          if (this.props.onDrop && this.props.dropKey.includes(dataParse.dragKey)) {
            e.dropKey = dataParse.dragKey
            this.props.onDrop(e, { ...dataParse.data })
          } else {
            const pointName =
              dataParse.dragKey === 'central-point'
                ? t('bridgePoint.connectionPoint')
                : t('bridgePoint.doctorPerform')
            const mess = `${t('youCanOnlyDrag')} ${pointName} ${t('area')}`
            message.destroy()
            message.warning(mess, 2)
          }
        }}
        onDragOver={(e) => {
          e.preventDefault()
          if (this.props.onDragOver) {
            this.props.onDragOver(e)
          }
        }}
      >
        {this.props.children}
      </div>
    )
  }
}

export default DropExtend
