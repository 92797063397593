import { IMessageState } from '../models/reducers/message.model'

export const ACTION_TYPES = {
  SET_MESSENGER: 'SET_MESSENGER',
}

interface IAction {
  type: string
  payload: any
}

const initializeMessage: Array<IMessageState> = []

const messageReducer = (state = initializeMessage, action: IAction) => {
  switch (action.type) {
    case ACTION_TYPES.SET_MESSENGER:
      state = action.payload
      return state
    default:
      return state
  }
}

const setMessage = (stream: Array<IMessageState>) => {
  return {
    type: ACTION_TYPES.SET_MESSENGER,
    payload: stream,
  }
}

export { messageReducer, setMessage }
