import { t } from 'i18next'
import { Component } from 'react'

type Props = {}

type State = {}

class EmptyLarge extends Component<Props, State> {
  state = {}

  render() {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          background: 'var(--background-3)',
          borderRadius: 8,
        }}
      >
        <div style={{ color: 'var(--color-default)', fontSize: 15 }}>{t('empty')}</div>
        <div style={{ fontStyle: 'italic', fontSize: 12 }}>({t('mainView.dragToView')})</div>
      </div>
    )
  }
}

export default EmptyLarge
