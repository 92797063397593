import { Button, Form, Input, Modal } from 'antd'
import { t } from 'i18next'
import { Component } from 'react'
import { connect } from 'react-redux'
import { IRoom } from '../../../models/reducers/room.model'
import { ISetting } from '../../../models/reducers/setting.model'
import { defaultISettingTool, ISettingTool } from '../../../models/reducers/settingTool.model'
import { setSettingTool } from '../../../reducers/settingTool'
import { servicesManager } from '../../../services/servicesManager'
type Props = {
    roomState: IRoom
    settingTool: ISettingTool
    setSettingTool(data: ISettingTool): void
    settingState: ISetting
}

type State = {}

class SettingTool extends Component<Props, State> {
    state = {}
    onChangePassWord = async (e: any) => {
        const newPass = e.target.value
        await servicesManager.RTC.onChangePassWord(newPass)
    }
    onChangeUsername = async (e: any) => {
        const newUserName = e.target.value
        await servicesManager.RTC.onChangeUserName(newUserName)
    }
    onFinish = async (e: any) => {
        if (e.username !== undefined) {
            const newName = e.username
            await servicesManager.RTC.onChangeUserName(newName)
        }
        if (e.password !== undefined) {
            const newPass = e.password
            await servicesManager.RTC.onChangePassWord(newPass)
        }
        this.props.setSettingTool(defaultISettingTool)
    }
    render() {
        const { settingTool } = this.props
        return (
            <Modal
                className="modal-setting"
                footer={null}
                visible={settingTool.visible}
                onCancel={() => this.props.setSettingTool(defaultISettingTool)}
                style={{ zIndex: '10000' }}
            >
                <Form
                    onFinish={this.onFinish}
                    name='Setting'
                    style={{ paddingTop: '20px' }}
                >
                    <label htmlFor="">{t("changepassword")}</label>
                    <Form.Item label='' name="password" >
                        <Input
                            disabled={!this.props.settingState.isKey}
                            onPressEnter={this.onChangePassWord}
                            type='password'
                        />
                    </Form.Item>
                    <label htmlFor="">{t("changeusername")}</label>
                    <Form.Item label='' name="username" >
                        <Input onPressEnter={this.onChangeUsername} />
                    </Form.Item>
                    <Form.Item style={{
                        position: 'relative', justifyContent: 'center', display: 'flex'
                    }} name="submit" >
                        <Button
                            type="primary"
                            htmlType='submit'
                        >
                            {t("submit")}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}
const mapStateToProps = (state: any) => {
    return {
        roomState: state.roomState,
        settingTool: state.settingTool,
        settingState: state.settingState
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        setSettingTool: (data: ISettingTool) => dispatch(setSettingTool(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SettingTool)
