import { t } from 'i18next'
import { Component } from 'react'
import { servicesManager } from '../../../services/servicesManager'
import { IUserParticipants } from '../../GridParticipants/GridParticipants'
type Props = {
    user: IUserParticipants
}
type State = {}
export default class RoomMaster extends Component<Props, State> {
    state = {}
    kickOut = async () => {
        const { user } = this.props
        const service = servicesManager.RTC
        service.kickUser(user)
    }
    chaneIsKey = async () => {
        const { user } = this.props
        const text: string = `Xác nhận nhường quyền chủ phòng cho ${user.username}`
        if (window.confirm(text) === true) {
            servicesManager.RTC.onChangeIsKey(user.id)
        }
    }
    render() {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                <button
                    style={{
                        background: 'transparent',
                        border: '1px solid',
                        height: '30px',
                        position: 'relative',
                        marginTop: '10px',
                        width: '200px'
                    }}
                    onClick={() => { this.kickOut() }}
                >
                    <span> {t("kick")} </span>
                </button>
                <button
                    style={{
                        background: 'transparent',
                        border: '1px solid',
                        height: '30px',
                        position: 'relative',
                        marginTop: '10px',
                        width: '200px'
                    }}
                    onClick={() => { this.chaneIsKey() }}
                >
                    <span> {t("key")} </span>
                </button>
            </div>
        )
    }
}
