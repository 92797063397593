import { Component } from 'react'
import { nonAccentVietnamese } from '../../utils/nonAccentVietnamese'
type Props = {
    username: string
}
type State = {}
export default class NoVideoMobile extends Component<Props, State> {
    state = {}
    render() {
        const name = this.props.username
        let abbreviations: any = ''
        try {
            abbreviations = nonAccentVietnamese(name)
                .match(/\b([A-Z a-z 0-9])/g)
                .join('')
                .toUpperCase()

        } catch (error) {
            abbreviations = name
        }
        // const bgColor = Math.floor(Math.random() * 16777215).toString(16)
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <span
                    style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        background: 'var(--background-2)',
                        textShadow: 'var(--text-shadow-white)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 4,
                    }}
                >
                    {abbreviations}
                </span>
            </div>
        )
    }
}
