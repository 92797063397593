import { defaultSetting, ISetting } from '../models/reducers/setting.model'

export const ACTION_TYPES = {
  SETTING: 'SETTING',
}

interface IAction {
  type: string
  payload: any
}

const initialize: ISetting = defaultSetting

const settingReducer = (state = initialize, action: IAction) => {
  switch (action.type) {
    case ACTION_TYPES.SETTING:
      state = action.payload
      return state

    default:
      return state
  }
}

const setSetting = (data: ISetting) => {
  return {
    type: ACTION_TYPES.SETTING,
    payload: data,
  }
}

export { settingReducer, setSetting }
