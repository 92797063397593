import { ICentralPoint } from '../models/reducers/centralPoint.model'
import { IConnectPoint } from '../models/reducers/connectionPoint.model'
import { SFU } from './SFU.util'

const listUserToBridgetList = (
  listUser: Array<{ id: string; isKey: boolean; username: string; producerList: Array<any> }>,
  sfuUtil: SFU
) => {
  return new Promise<{ connectionPoint: Array<IConnectPoint>; centralPoint: ICentralPoint | null }>(
    async (resolve, _reject) => {
      const connectionPoint: Array<IConnectPoint> = []
      let centralPoint: ICentralPoint | null = null
      for (let i = 0; i < listUser.length; i++) {
        const user = listUser[i]
        const consumerList: Array<{ id: string; type: string; active: boolean; play: boolean }> = []
        for (let j = 0; j < user.producerList.length; j++) {
          const { producerId } = user.producerList[j]
          const consumer = await sfuUtil.createConsumer(producerId)
          if (consumer.kind === 'video') {
            consumerList.push({
              id: consumer.id,
              type: consumer.kind,
              active:
                consumerList.filter((consume) => consume.type === 'video').length === 0
                  ? true
                  : false,
              play: true,
            })
          } else {
            consumerList.push({
              id: consumer.id,
              type: consumer.kind,
              active: false,
              play: true,
            })
          }
        }
        if (user.isKey) {
          centralPoint = { id: user.id, username: user.username, consumerList }
        } else {
          connectionPoint.push({ id: user.id, username: user.username, consumerList })
        }
      }
      resolve({ connectionPoint, centralPoint })
    }
  )
}
export { listUserToBridgetList }
