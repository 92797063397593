import { IResponse } from '../models/response/IResponse'
import { NOTIFYCATION_ERROR } from '../utils/notifycation'
import { ServiceBaseRISSCU } from './serviceBaseRISSCU'

class SCUService extends ServiceBaseRISSCU {
  constructor(baseURL: string) {
    super(baseURL)
  }
  // Get Router Capacities
  fetchStudyImage = async (sid: number) => {
    const url = '/vrpacs-file/get-study-image'
    const response: IResponse<any> = await this.service.post(url, { nondicom: true, sID: sid })
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      return
    }

    return response
  }

  uploadFileStudy = async (sid: number, date: any, form: any) => {
    const url = `/vrpacs-file/study-upload-file?sID=${sid}&seName=NonDicom&description=upload_${date}`
    const response: IResponse<any> = await this.service.post(url, form)
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      return
    }
    return response
  }

  deleteFileStudy = async (sid: number, files: Array<string>) => {
    const url = `/vrpacs-file/study-delete-file-on-folder`
    const response: IResponse<any> = await this.service.post(url, {
      fileName: files,
      folderName: 'NonDicom',
      sID: sid,
    })
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      return
    }
    return response
  }
}

export { SCUService }
