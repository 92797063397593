import { defaultCentralPoint, ICentralPoint } from '../models/reducers/centralPoint.model'

export const ACTION_TYPES = {
  CENTRAL_POINT: 'CENTRAL_POINT',
}

interface IAction {
  type: string
  payload: any
}

const initialize: ICentralPoint | null = defaultCentralPoint

const centralPointReducer = (state = initialize, action: IAction) => {
  switch (action.type) {
    case ACTION_TYPES.CENTRAL_POINT:
      state = action.payload
      return state

    default:
      return state
  }
}

const setCentralPoint = (data: ICentralPoint | null) => {
  return {
    type: ACTION_TYPES.CENTRAL_POINT,
    payload: data,
  }
}

export { centralPointReducer, setCentralPoint }
