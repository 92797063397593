import { Button, Popover, Tooltip } from 'antd'
import { t } from 'i18next'
import { Component } from 'react'
import { connect } from 'react-redux'
import ResizeObserver from 'react-resize-observer'
import { ICentralPoint } from '../../models/reducers/centralPoint.model'
import { IConnectPoint } from '../../models/reducers/connectionPoint.model'
import { ILargeView } from '../../models/reducers/largeView.model'
import { ISetting } from '../../models/reducers/setting.model'
import { setCentralPoint } from '../../reducers/centralPointReducer'
import { setConnectionPoint } from '../../reducers/connectionPointReducer'
import { setLargeView } from '../../reducers/largeviewReducer'
import { nonAccentVietnamese } from '../../utils/nonAccentVietnamese'
import { utilssManager } from '../../utils/utilsManager'
import Audio from "../Audio/Audio"
import RoomMaster from '../Views/Mobile/RoomMaster'
import { IUserParticipants } from './GridParticipants'
import styles from './GridParticipants.module.scss'
type Props = {
  name: string
  row: number
  col: number
  widthParticipants: any
  user: IUserParticipants
  settingState: ISetting
  centralPoint: ICentralPoint
  connectionPoint: Array<IConnectPoint>
  setCentralPoint(data: ICentralPoint | null): void
  setConnectionPoint(data: Array<IConnectPoint>): void
  setLargeView(data: Array<ILargeView>): void
}

type State = {
  size: number
}

class Abbreviations extends Component<Props, State> {
  state = {
    size: 20,
  }

  render() {
    const { name, row, col, widthParticipants, user, settingState, centralPoint } = this.props
    let abbreviations: any = ''
    try {
      abbreviations = nonAccentVietnamese(name)
        .match(/\b([A-Z a-z 0-9])/g)
        .join('')
        .toUpperCase()
    } catch (error) {
      abbreviations = name
    }
    const bgColor = Math.floor(Math.random() * 16777215).toString(16)
    return (
      <div
        style={{
          width: `calc(100% / ${col} - 8px)`,
          height: `calc(100% / ${row} - 8px)`,
          maxHeight: widthParticipants === '100%' ? '100%' : '105px',
          maxWidth: widthParticipants === '100%' ? '100%' : '180px',
          borderRadius: 4,
          margin: 4,
          minHeight: '90px',
          minWidth: '160px',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: '0',
            width: 'calc(100% - 0px)',
            padding: '0 5px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textShadow: 'var(--text-shadow-black)',
            zIndex: 1,
            color: 'var(--color-light)',
          }}
        >
          {name}
        </div>
        {
          utilssManager.me !== user.id ?
            user.audios.length === 0 ? ''
              :
              <Audio id={user.id} audio={user.audios[0]} consumerId={user.audios[0].id} />
            : ''
        }
        {user.id !== centralPoint.id && settingState.isKey === true &&
          <Tooltip title={t("more")}>
            <Popover
              style={{
                position: 'relative',
                borderRadius: '10px',
                overflow: 'hidden'
              }}
              title={null}
              content={<RoomMaster user={user} />}
              trigger="click"
            >
              <Button
                style={{
                  position: 'absolute',
                  top: 0,
                  right: '4%',
                  background: 'transparent',
                  border: 'none',
                  width: '16px',
                  height: '16px',
                  padding: 0,
                  zIndex: 1000
                }}
                icon={<img style={{
                  width: 'auto',
                  height: 'auto',
                  position: 'relative',
                  top: 0,
                  right: '1%'
                }} src="./icons/more.png" alt="" />}
              />
            </Popover>
          </Tooltip>
        }
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            background: 'var(--background-2)',
            textShadow: 'var(--text-shadow-white)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 4,
          }}
        >
          <ResizeObserver
            onResize={(rect) => {
              this.setState({
                size: Math.min(rect.width, rect.height),
              })
            }}
          />
          <div
            className={styles.abbreviations}
            style={{
              color: `#${bgColor}`,
              fontSize: Math.min(Math.round(this.state.size / 3), 30),
            }}
          >
            {abbreviations}
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state: any) => ({
  centralPoint: state.centralPoint,
  connectionPoint: state.connectionPoint,
  settingState: state.settingState
})
const mapDispatchToProps = (dispatch: any) => {
  return {
    setCentralPoint: (data: ICentralPoint | null) => dispatch(setCentralPoint(data)),
    setConnectionPoint: (data: Array<IConnectPoint>) => dispatch(setConnectionPoint(data)),
    setLargeView: (data: Array<ILargeView>) => dispatch(setLargeView(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Abbreviations)
