import { Component } from 'react'
import { connect } from 'react-redux'
import ResizeObserver from 'react-resize-observer'
import { ICentralPoint } from '../../../models/reducers/centralPoint.model'
import { ILargeView } from '../../../models/reducers/largeView.model'
import { setLargeView } from '../../../reducers/largeviewReducer'
import CameraLarge from '../../BridgePoint/Camera/CameraLarge'
import DropExtend from '../../DragAndDrop/DropExtend'
import style from './ConferenceView.module.scss'
type Props = {
  notCameras?: boolean
  numberOfSmallCameras: number
  centralPoint: ICentralPoint

  largeView: Array<ILargeView>
  setLargeView(data: Array<ILargeView>): void
}

type State = {
  width: number
  height: number
  mainConsumerId: string
}

class ConferenceView extends Component<Props, State> {
  state = {
    width: 0,
    height: 0,
    mainConsumerId: '',
  }
  ratio: number = 16 / 9

  componentDidMount = () => {
    const { centralPoint } = this.props
    for (let i = 0; i < centralPoint.consumerList.length; i++) {
      if (centralPoint.consumerList[i].type === 'video') {
        this.setState({
          mainConsumerId: centralPoint.consumerList[i].id,
        })
        break
      }
    }
  }

  onDrop = (viewIndex: number, consumerId: string) => {
    if (viewIndex === -1) {
      this.setState({
        mainConsumerId: consumerId,
      })
      return
    }
    const largeView = [...this.props.largeView]
    largeView[viewIndex].consumerId = consumerId
    this.props.setLargeView(largeView)
  }

  getSizeWidthRatio = () => {
    const { width, height } = this.state
    if (width / height < this.ratio) {
      return { width: Math.round(width), height: Math.round(width / this.ratio) }
    } else {
      return { width: Math.round(height * this.ratio), height: Math.round(height) }
    }
  }

  gridTemplateAreas = () => {
    const { numberOfSmallCameras } = this.props
    const size = (numberOfSmallCameras + 4) / 4
    let data = ''
    let index = 0
    for (let i = 0; i < size; i++) {
      let xx = ''
      for (let j = 0; j < size; j++) {
        if (i === 0 || i === size - 1 || j === 0 || j === size - 1) {
          index++
          xx += ` x${index}`
        } else {
          xx += ` main`
        }
      }
      data += `"${xx}"`
    }
    return data
  }

  render() {
    const { numberOfSmallCameras, notCameras, largeView } = this.props
    const size: { width: number; height: number } = this.getSizeWidthRatio()
    const smallCameras = []
    for (let i = 0; i < numberOfSmallCameras; i++) {
      smallCameras.push({ consumerId: null })
    }
    let data = []
    if (numberOfSmallCameras === largeView.length) {
      data = largeView
    } else {
      data = smallCameras
    }
    const gridNumber = (numberOfSmallCameras + 4) / 4
    return (
      <div
        id="main-videos"
        style={{
          width: '100%',
          height: '100%',
          background: 'var(--background-2)',
          padding: 4,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ResizeObserver
          onResize={(rect) => {
            this.setState({
              width: rect.width - 8,
              height: rect.height - 8,
            })
          }}
        />
        <div
          className={style.layout}
          style={{
            width: size.width,
            maxWidth: size.width,
            height: size.height,
            maxHeight: size.height,
            // width: '100%',
            // height: '100%',
            display: 'grid',
            gridTemplateAreas: this.gridTemplateAreas(),
          }}
        >
          {data.map((item, i) => (
            <div
              key={item.consumerId ? `${item.consumerId}_${i}` : `cameraItem_${i}`}
              style={{
                gridArea: `x${i + 1}`,
                maxWidth: size.width / gridNumber,
                maxHeight: size.height / gridNumber,
                minWidth: size.width / gridNumber,
                minHeight: size.height / gridNumber,
              }}
              className={style.cameraItem}
            >
              {notCameras ? (
                <div style={{ width: '100%', height: '100%', background: 'var(--background-2)' }} />
              ) : (
                <DropExtend
                  style={{ width: '100%', height: '100%', background: 'var(--background-2)' }}
                  onDrop={(e: any, _data2: any) => {
                    if (e.dropKey === 'central-point') {
                      this.onDrop(i, _data2.id)
                    } else {
                      this.onDrop(i, _data2.consumer.id)
                    }
                  }}
                  key={i}
                  dropKey={['central-point', 'connection-point']}
                >
                  {item.consumerId ? <CameraLarge consumerId={item.consumerId} /> : null}
                </DropExtend>
              )}
            </div>
          ))}

          <div
            className={style.cameraItem}
            style={{
              gridArea: 'main',
              maxWidth: (size.width / gridNumber) * (gridNumber - 2),
              maxHeight: (size.height / gridNumber) * (gridNumber - 2),
              minWidth: (size.width / gridNumber) * (gridNumber - 2),
              minHeight: (size.height / gridNumber) * (gridNumber - 2),
            }}
          >
            {notCameras ? (
              <div style={{ width: '100%', height: '100%', background: 'var(--background-2)' }} />
            ) : (
              <DropExtend
                style={{
                  width: '100%',
                  height: '100%',
                  background: 'var(--background-2)',
                }}
                onDrop={(e: any, data2: any) => {
                  if (e.dropKey === 'central-point') {
                    this.onDrop(-1, data2.id)
                  } else {
                    this.onDrop(-1, data2.consumer.id)
                  }
                }}
                dropKey={['central-point', 'connection-point']}
              >
                {this.state.mainConsumerId ? (
                  <CameraLarge consumerId={this.state.mainConsumerId} />
                ) : null}
              </DropExtend>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  centralPoint: state.centralPoint,
  largeView: state.largeView,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLargeView: (data: Array<ILargeView>) => dispatch(setLargeView(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceView)
