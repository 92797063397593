import { Button, Col, Form, Input, Modal, Row, Select, Tabs } from 'antd'
import { t } from 'i18next'
import { Component } from 'react'
import { connect } from 'react-redux'
import { IRoom } from '../../models/reducers/room.model'
import { ISetting } from '../../models/reducers/setting.model'
import { defaultISettingTool, ISettingTool } from '../../models/reducers/settingTool.model'
import { setSetting } from '../../reducers/settingReducer'
import { setSettingTool } from '../../reducers/settingTool'
import { servicesManager } from '../../services/servicesManager'
type Props = {
    roomState: IRoom
    settingTool: ISettingTool
    setSettingTool(data: ISettingTool): void
    settingState: ISetting
    setSetting(data: ISetting): void
}

type State = {}

class SettingTool extends Component<Props, State> {
    state = {}
    onChangePassWord = async (e: any) => {
        const newPass = e.target.value
        await servicesManager.RTC.onChangePassWord(newPass)
    }
    onChangeUsername = async (e: any) => {
        const newUserName = e.target.value
        await servicesManager.RTC.onChangeUserName(newUserName)
    }
    onFinish = async (e: any) => {
        if (e.username !== undefined) {
            const newName = e.username
            await servicesManager.RTC.onChangeUserName(newName)
        }
        if (e.password !== undefined) {
            const newPass = e.password
            await servicesManager.RTC.onChangePassWord(newPass)
        }
        this.props.setSettingTool(defaultISettingTool)
    }
    onChangeSetting = (type: string, value: any) => {
        const settingData: ISetting = { ...this.props.settingState }
        switch (type) {
            case 'codec':
                settingData.codecRecoder = value
                break
            case 'quality':
                settingData.qualityRecorder = Number(value)
                break
        }
        this.props.setSetting(settingData)
    }
    render() {  
        const { settingTool, settingState } = this.props
        return (
            <Modal
                className="modal-setting"
                footer={null}
                visible={settingTool.visible}
                onCancel={() => this.props.setSettingTool(defaultISettingTool)}
                style={{ zIndex: '10000' }}
            >
                <Tabs>
                    <Tabs.TabPane tab={t("settingroom")} key="1">
                        <Form
                            onFinish={this.onFinish}
                            name='Setting'
                            style={{ paddingTop: '20px' }}
                        >
                            <label htmlFor="">{t("changepassword")}</label>
                            <Form.Item label='' name="password" >
                                <Input
                                    disabled={!this.props.settingState.isKey}
                                    onPressEnter={this.onChangePassWord}
                                    type='password'
                                />
                            </Form.Item>
                            <label htmlFor="">{t("changeusername")}</label>
                            <Form.Item label='' name="username" >
                                <Input onPressEnter={this.onChangeUsername} />
                            </Form.Item>
                            <Form.Item style={{
                                position: 'relative', justifyContent: 'center', display: 'flex'
                            }} name="submit" >
                                <Button
                                    type="primary"
                                    htmlType='submit'
                                >
                                    {t("submit")}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("advance")} key="2">
                        <Form
                            onFinish={this.onFinish}
                            name='Setting'
                            style={{ paddingTop: '20px' }}
                        >
                            <Row>
                                <Col span={24}>
                                    <label htmlFor="">Ghi hình</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={16}>
                                    <label>Codec</label>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                    >
                                        <Select value={settingState.codecRecoder}
                                            // tslint:disable-next-line: no-shadowed-variable
                                            onChange={(value) => this.onChangeSetting("codec", value)}
                                        >
                                            <Select.Option value="vp8">VP8 ({t("default")})</Select.Option>
                                            <Select.Option value='vp9'>VP9</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={16}>
                                    <label>{t("quality")}</label>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        style={{
                                            display: 'flex'
                                        }}
                                    >
                                        <Select value={settingState.qualityRecorder.toString()}
                                            // tslint:disable-next-line: no-shadowed-variable
                                            onChange={(value) => this.onChangeSetting("quality", value)}
                                        >
                                            <Select.Option value="0.5">50%</Select.Option>
                                            <Select.Option value='0.75'>75% ({t("default")})</Select.Option>
                                            <Select.Option value='0.9'>90%</Select.Option>
                                            <Select.Option value='0.95'>95%</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Tabs.TabPane>
                </Tabs>
            </Modal>
        )
    }
}
const mapStateToProps = (state: any) => {
    return {
        roomState: state.roomState,
        settingTool: state.settingTool,
        settingState: state.settingState
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        setSettingTool: (data: ISettingTool) => dispatch(setSettingTool(data)),
        setSetting: (data: ISetting) => dispatch(setSetting(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SettingTool)
