import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
const resources = {
  vi: {
    translation: {
      layout: 'Chọn Bố Cục',
      microoff: 'Tắt Mic',
      microon: 'Bật Mic',
      microsetting: 'Chọn Micro',
      microsignal: 'Tín hiệu mic',
      cameraoff: 'Tắt Camera',
      cameraon: 'Bật Camera',
      camerasetting: 'Chọn Camera',
      cameraresolution: 'Chọn độ phân giải camera',
      camerasignal: 'Tín hiệu camera',
      speakertest: 'Thử Loa',
      sharescreen: 'Chia sẻ màn hình',
      sharescreenoff: 'Dừng chia sẻ màn hình',
      recoderscreen: 'Quay Màn Hình',
      recoderscreenoff: 'Dừng Quay Màn Hình',
      openwhiteboard: 'Mở Bảng Vẽ',
      closeroom: 'Rời Khỏi Cuộc Họp',
      share: 'Chia Sẻ',
      settingroom: 'Cài Đặt Phòng Họp',
      paticipants: 'Những Người Tham Gia',
      roomname: 'Tên Phòng',
      password: 'Mật Khẩu',
      username: 'Tên hiển thị',
      submit: 'Xác Nhận',
      support: 'Hỗ trợ',
      fullpaticipants: 'Mở Những Người Tham Gia',
      gridlayout: 'Bố Cục Lưới',
      conferencelayout: 'Chọn Bố Cục Cho Hội Nghị',
      conferencelayoutcenter: 'Chọn Bố Cục Trung Tâmr',
      fullscreen: 'Toàn màn hình',
      more: 'Thêm',
      mute: 'Im lặng',
      notmute: 'Mở Âm Thanh',
      joinroom: 'Tham Gia Phòng',
      leftroom: 'Rời Phòng',
      endroom: 'Kết Thúc Cuộc Họp',
      changepassword: 'Thay Đổi Mật Khẩu Phòng',
      changeusername: 'Thay Đổi Tên Hiển Thị',
      confirmwhiteboard: 'Mở Bảng Vẽ ?',
      open: 'Mở',
      cancel: 'Đóng',
      kick: 'Đuổi khỏi phòng',
      key: 'Nhường chủ phòng',
      advance: 'Nâng Cao',
      default: 'Mặc định',
      quality: 'Chất lượng',
      spam: 'Vui lòng thao tác chậm lại',
      noCameraAccess: 'Không có quyền truy cập camera',
      noMicroAccess: 'Không có quyền truy cập micro',
      noScreenCapturingAccess: 'Không có quyền chia sẻ màn hình',
    },
  },
  en: {
    translation: {
      noCameraAccess: 'No camera access',
      noMicroAccess: 'No micro access',
      layout: 'Select Layout',
      microoff: 'Close Micro',
      microon: 'Open Micro',
      microsetting: 'Select Micro',
      microsignal: 'Micro Signal',
      cameraoff: 'Close Camera',
      cameraon: 'Open Camera',
      camerasetting: 'Select Camera',
      cameraresolution: 'Select Camera Resolution',
      camerasignal: 'Camera Signal',
      speakertest: 'Test Speaker',
      sharescreen: 'Share Screen',
      sharescreenoff: 'Stop Share Screen',
      openwhiteboard: 'Open White Board',
      closeroom: 'Leave Room',
      share: 'Share Room',
      settingroom: 'Setting Room',
      paticipants: 'Paticipants',
      roomname: 'Room Name',
      password: 'Pass Word',
      username: 'User Name',
      submit: 'Submit',
      support: 'Support',
      fullpaticipants: 'Full View Paticipants',
      gridlayout: 'Select Grid Layout',
      conferencelayout: 'Select Conference Layout',
      conferencelayoutcentrer: 'Select Conference Layout Center',
      fullscreen: 'Full Screen',
      more: 'More',
      mute: 'Silent Sound',
      notmute: 'Open Sound',
      joinroom: 'Join Room',
      leftroom: 'Left Room',
      endroom: 'End Meeting',
      changepassword: 'Change Room Pass',
      changeusername: 'Change Username',
      confirmwhiteboard: 'Open White Board ?',
      open: 'Open',
      cancel: 'Cancel',
      kick: 'Kick',
      key: 'Change Key',
      advance: 'Advance',
      default: 'Default',
      quality: 'quality',
      spam: 'Please slow down',
      noScreenCapturingAccess: 'No Screen Capturing Access',
    },
  },
}
i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: 'vi',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: true, // not needed for react as it escapes by default
    },
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: './locales/{{lng}}/{{ns}}.json',
      allowMultiLoading: true,
    },
  })

export default i18n
