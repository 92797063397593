export enum EAudioType {
  speakerTest = 'speakerTest',
  joinRoom = 'joinRoom',
  requestJoinRoom = 'requestJoinRoom',
  newMessage = 'newMessage',
}

const playAudio = (type: string) => {
  if (EAudioType.speakerTest === type) {
    const audio = document.createElement('audio')
    audio.src = './audio/outgoingRinging.wav'
    audio.muted = false
    audio.autoplay = true
    audio.onended = () => {
      document.body.removeChild(audio)
    }
    document.body.appendChild(audio)
  }

  switch (type) {
    case EAudioType.requestJoinRoom: {
      const audio = document.createElement('audio')
      audio.src = './audio/join_call.ogg'
      audio.muted = false
      audio.autoplay = true
      audio.onended = () => {
        document.body.removeChild(audio)
      }
      document.body.appendChild(audio)
      break
    }

    case EAudioType.joinRoom:
      {
        const audio = document.createElement('audio')
        audio.src = './audio/join.wav'
        audio.muted = false
        audio.autoplay = true
        audio.onended = () => {
          document.body.removeChild(audio)
        }
        document.body.appendChild(audio)
      }

      break
    case EAudioType.speakerTest: {
      const audio = document.createElement('audio')
      audio.src = './audio/outgoingRinging.wav'
      audio.muted = false
      audio.autoplay = true
      audio.onended = () => {
        document.body.removeChild(audio)
      }
      document.body.appendChild(audio)
      break
    }

    case EAudioType.newMessage: {
      const audio: any = document.getElementById('messenger')
      if (!audio) {
        console.log("none");

        return
      }
      audio.src = './audio/messenger.mp3'
      audio.muted = false
      const playPromise = audio.play();

      if (playPromise !== undefined) {
        playPromise.then( (_: any) => {
          // Automatic playback started!
          // Show playing UI.
        })
        .catch(() => {
          // Auto-play was prevented
          // Show paused UI.
        });
      }
      break
    }
    default:
      break
  }
}
export { playAudio }
