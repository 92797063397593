import { t } from 'i18next'
import { Component } from 'react'

type Props = {
  bg?: string
}

type State = {}

class Loading extends Component<Props, State> {
  state = {}

  render() {
    const { bg } = this.props
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          color: 'var(--color-default)',
          background: bg ? bg : '#242424',
        }}
      >
        <img width={50} height={50} src="./icons/loading.svg" alt="" />
        {t('loading')}
      </div>
    )
  }
}

export default Loading
