import { defaultModal, IModal } from '../models/reducers/modal.model'

export const ACTION_TYPES = {
  SET_MODAL: 'SET_MODAL',
}

interface IAction {
  type: string
  payload: any
}

const initialize: IModal = defaultModal

const modalReducer = (state = initialize, action: IAction) => {
  switch (action.type) {
    case ACTION_TYPES.SET_MODAL:
      state = action.payload
      return state
    default:
      return state
  }
}

const setModal = (data: IModal) => {
  return {
    type: ACTION_TYPES.SET_MODAL,
    payload: data,
  }
}

export { modalReducer, setModal }
