import { defaultLayoutMobile, ILayoutMobile } from "../models/reducers/layoutMobile"


export const ACTION_TYPES = {
    LAYOUT_MOBILE: 'LAYOUT_MOBILE',
}

interface IAction {
    type: string
    payload: any
}

const initialize: ILayoutMobile = defaultLayoutMobile

const layoutMobile = (state = initialize, action: IAction) => {
    switch (action.type) {
        case ACTION_TYPES.LAYOUT_MOBILE:
            state = action.payload
            return state

        default:
            return state
    }
}

const setLayoutMobile = (data: any) => {
    return {
        type: ACTION_TYPES.LAYOUT_MOBILE,
        payload: data,
    }
}

export { layoutMobile, setLayoutMobile }
