const encodeLink = (roomState: any) => {
  try {
    const room = {
      roomname: roomState.roomname,
      username: '',
      password: roomState.password,
    }
    const link =
      window.location.protocol +
      '//' +
      window.location.host +
      '/?params=' +
      btoa(JSON.stringify(room))
    return link
  } catch (error) {
    return ''
  }
}
export { encodeLink }
