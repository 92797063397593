import { message } from 'antd'
import { store } from '../../../redux/store'
const arrRedo = []
export let listToolsDataClient = []
export const disableAll = (canvas) => {
  const isg_draw_board = window.isg_draw_board
  isg_draw_board.Tools.DrawTools.lengthTool.deactivate(canvas)
  isg_draw_board.Tools.DrawTools.rectangleTool.deactivate(canvas)
  isg_draw_board.Tools.DrawTools.arrowTool.deactivate(canvas)
  isg_draw_board.Tools.DrawTools.arrowTextTool.deactivate(canvas)
  isg_draw_board.Tools.DrawTools.annotationRectangleTool.deactivate(canvas)
  isg_draw_board.Tools.DrawTools.annotationElipseTool.deactivate(canvas)
  isg_draw_board.Tools.DrawTools.polygonTool.deactivate(canvas)
  isg_draw_board.Tools.DrawTools.pencilTool.deactivate(canvas)
  isg_draw_board.Tools.DrawTools.elipseTool.deactivate(canvas)
  isg_draw_board.Tools.DrawTools.heartTool.deactivate(canvas)
  isg_draw_board.Tools.DrawTools.starTool.deactivate(canvas)
  isg_draw_board.Tools.DrawTools.textTool.deactivate(canvas)
  isg_draw_board.Tools.DrawTools.imageTool.deactivate(canvas)
}

const lengthToolDraw = () => {
  const isg_draw_board = window.isg_draw_board
  const canvas = document.getElementById('board')
  const drawColor = store.getState().drawBoard.drawColor
  disableAll(canvas)
  isg_draw_board.Tools.DrawTools.lengthTool.setConfiguration({
    renderNode: { defaultColor: drawColor, activedColor: `${drawColor}90` },
  })
  isg_draw_board.Tools.DrawTools.lengthTool.activate(canvas)
}

const rectToolDraw = () => {
  const drawColor = store.getState().drawBoard.drawColor
  const isg_draw_board = window.isg_draw_board
  const canvas = document.getElementById('board')
  disableAll(canvas)
  isg_draw_board.Tools.DrawTools.rectangleTool.setConfiguration({
    renderNode: { defaultColor: drawColor, activedColor: `${drawColor}90` },
  })
  isg_draw_board.Tools.DrawTools.rectangleTool.activate(canvas)
}

const arrowToolDraw = () => {
  const drawColor = store.getState().drawBoard.drawColor
  const isg_draw_board = window.isg_draw_board
  const canvas = document.getElementById('board')
  disableAll(canvas)
  isg_draw_board.Tools.DrawTools.arrowTool.setConfiguration({
    renderNode: { defaultColor: drawColor, activedColor: `${drawColor}90` },
  })
  isg_draw_board.Tools.DrawTools.arrowTool.activate(canvas)
}

const polygonToolDraw = () => {
  const drawColor = store.getState().drawBoard.drawColor
  const isg_draw_board = window.isg_draw_board
  const canvas = document.getElementById('board')
  disableAll(canvas)
  isg_draw_board.Tools.DrawTools.polygonTool.setConfiguration({
    renderNode: { defaultColor: drawColor, activedColor: `${drawColor}90` },
  })
  isg_draw_board.Tools.DrawTools.polygonTool.activate(canvas)
}

const arrowTextoolDraw = () => {
  const drawColor = store.getState().drawBoard.drawColor
  const isg_draw_board = window.isg_draw_board
  const canvas = document.getElementById('board')
  disableAll(canvas)
  isg_draw_board.Tools.DrawTools.arrowTextTool.setConfiguration({
    renderNode: { defaultColor: drawColor, activedColor: `${drawColor}90` },
  })
  isg_draw_board.Tools.DrawTools.arrowTextTool.activate(canvas)
}

const annotationElipseToolDraw = () => {
  const drawColor = store.getState().drawBoard.drawColor
  const isg_draw_board = window.isg_draw_board
  const canvas = document.getElementById('board')
  disableAll(canvas)
  isg_draw_board.Tools.DrawTools.annotationElipseTool.setConfiguration({
    renderNode: { defaultColor: drawColor, activedColor: `${drawColor}90` },
  })
  isg_draw_board.Tools.DrawTools.annotationElipseTool.activate(canvas)
}

const annotationRectangleToolDraw = () => {
  const drawColor = store.getState().drawBoard.drawColor
  const isg_draw_board = window.isg_draw_board
  const canvas = document.getElementById('board')
  disableAll(canvas)
  isg_draw_board.Tools.DrawTools.annotationRectangleTool.setConfiguration({
    renderNode: { defaultColor: drawColor, activedColor: `${drawColor}90` },
  })
  isg_draw_board.Tools.DrawTools.annotationRectangleTool.activate(canvas)
}

const pencilToolDraw = () => {
  const drawColor = store.getState().drawBoard.drawColor
  const isg_draw_board = window.isg_draw_board
  const canvas = document.getElementById('board')
  disableAll(canvas)
  isg_draw_board.Tools.DrawTools.pencilTool.setConfiguration({
    renderNode: { defaultColor: drawColor, activedColor: `${drawColor}90` },
  })
  isg_draw_board.Tools.DrawTools.pencilTool.activate(canvas)
}

const elipseToolDraw = () => {
  const drawColor = store.getState().drawBoard.drawColor
  const isg_draw_board = window.isg_draw_board
  const canvas = document.getElementById('board')
  disableAll(canvas)
  isg_draw_board.Tools.DrawTools.elipseTool.setConfiguration({
    renderNode: { defaultColor: drawColor, activedColor: `${drawColor}90` },
  })
  isg_draw_board.Tools.DrawTools.elipseTool.activate(canvas)
}

const heartToolDraw = () => {
  const drawColor = store.getState().drawBoard.drawColor
  const isg_draw_board = window.isg_draw_board
  const canvas = document.getElementById('board')
  disableAll(canvas)
  isg_draw_board.Tools.DrawTools.heartTool.setConfiguration({
    renderNode: { defaultColor: drawColor, activedColor: `${drawColor}90` },
  })
  isg_draw_board.Tools.DrawTools.heartTool.activate(canvas)
}

const starToolDraw = () => {
  const drawColor = store.getState().drawBoard.drawColor
  const isg_draw_board = window.isg_draw_board
  const canvas = document.getElementById('board')
  disableAll(canvas)
  isg_draw_board.Tools.DrawTools.starTool.setConfiguration({
    renderNode: { defaultColor: drawColor, activedColor: `${drawColor}90` },
  })
  isg_draw_board.Tools.DrawTools.starTool.activate(canvas)
}

const textToolDraw = (data) => {
  const fontStyle = data.italic ? 'italic' : 'normal'
  const fontWeight = data.bold ? 'bold' : 'normal'
  const fontSize = Number(data.size)
  const drawColor = store.getState().drawBoard.drawColor
  const isg_draw_board = window.isg_draw_board
  const canvas = document.getElementById('board')
  disableAll(canvas)
  isg_draw_board.Tools.DrawTools.textTool.setConfiguration({
    renderNode: {
      defaultColor: drawColor,
      activedColor: `${drawColor}90`,
      fontStyle: fontStyle,
      fontSize: fontSize,
      fontWeight: fontWeight,
      fontFamily: 'Arial',
    },
  })
  isg_draw_board.Tools.DrawTools.textTool.activate(canvas)
}

const imageToolDraw = () => {
  const drawColor = store.getState().drawBoard.drawColor
  const isg_draw_board = window.isg_draw_board
  const canvas = document.getElementById('board')

  const input = document.createElement('input')
  input.type = 'file'
  input.accept = 'image/png, image/jpeg'
  input.onchange = (e) => {
    const reader = new FileReader()
    reader.onload = (e1) => {
      if (e1.total < 5000000) {
        const image = new Image()
        image.onload = (e2) => {
          disableAll(canvas)
          isg_draw_board.Tools.DrawTools.imageTool.setConfiguration({
            renderNode: {
              defaultColor: drawColor,
              activedColor: `${drawColor}90`,
              imageElement: image,
            },
          })
          isg_draw_board.Tools.DrawTools.imageTool.activate(canvas)
        }

        image.src = reader.result
      } else {
        message.error('Kick thuoc khong phu hop')
      }
    }
    reader.readAsDataURL(e.target.files[0])
  }
  input.click()
}

const eraserToolDraw = () => {
  const canvas = document.getElementById('board')
  disableAll(canvas)
}

const undoTool = () => {
  const canvas = document.getElementById('board')
  const ctx = canvas?.getContext('2d')
  const isg_draw_board = window.isg_draw_board
  const toolData = isg_draw_board.Tools.ToolState.getToolState()
  const { listTools } = toolData
  if (listTools.length > 0) {
    arrRedo.push(listTools[listTools.length - 1])
    listTools.pop()
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    for (let index = 0; index < listTools.length; index++) {
      const tool = listTools[index]
      tool.draw(ctx)
    }
  }
}

const redoTool = () => {
  const canvas = document.getElementById('board')
  const ctx = canvas?.getContext('2d')
  const isg_draw_board = window.isg_draw_board
  const toolData = isg_draw_board.Tools.ToolState.getToolState()
  const { listTools } = toolData
  if (arrRedo[arrRedo.length - 1]) {
    listTools.push(arrRedo[arrRedo.length - 1])
    arrRedo.pop()
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    for (let index = 0; index < listTools.length; index++) {
      const tool = listTools[index]
      tool.draw(ctx)
    }
  }
}

export {
  imageToolDraw,
  textToolDraw,
  heartToolDraw,
  starToolDraw,
  elipseToolDraw,
  pencilToolDraw,
  annotationRectangleToolDraw,
  lengthToolDraw,
  rectToolDraw,
  arrowToolDraw,
  polygonToolDraw,
  arrowTextoolDraw,
  annotationElipseToolDraw,
  eraserToolDraw,
  undoTool,
  redoTool,
}
