import { IConfigAdmin } from '../models/reducers/configAdmin.model'

export const ACTION_TYPES = {
  SET_CONFIG_ADMIN: 'SET_CONFIG_ADMIN',
}

interface IAction {
  type: string
  payload: any
}

const initialize: Array<IConfigAdmin> = []

const configAdminReducer = (state = initialize, action: IAction) => {
  switch (action.type) {
    case ACTION_TYPES.SET_CONFIG_ADMIN:
      state = action.payload
      return state

    default:
      return state
  }
}

const setConfigAdmin = (data: Array<IConfigAdmin>) => {
  return {
    type: ACTION_TYPES.SET_CONFIG_ADMIN,
    payload: data,
  }
}

const findConfigByConfigCode = (code: string, configAdmin: Array<IConfigAdmin>) => {
  return configAdmin.find((item) => item.configCode === code)
}

export { configAdminReducer, setConfigAdmin, findConfigByConfigCode }
