import { defaultConfirm, IConfirm } from "../models/reducers/confirmWhiteBoard.model"

export const ACTION_TYPES = {
  SET_CONFIRM: 'SET_CONFIRM',
}

interface IAction {
  type: string
  payload: any
}

const initialize: IConfirm = defaultConfirm

const confirmReducer = (state = initialize, action: IAction) => {
  switch (action.type) {
    case ACTION_TYPES.SET_CONFIRM:
      state = action.payload
      return state
    default:
      return state
  }
}

const setConfirmReducer = (data: any) => {
  return {
    type: ACTION_TYPES.SET_CONFIRM,
    payload: data,
  }
}

export { confirmReducer, setConfirmReducer }
