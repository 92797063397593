import { Component } from 'react'
type ICell = { active: boolean; row: number; column: number }

type IProps = {
  cellClick(row: number, col: number): void
}

type IState = {
  row: number
  column: number
  cells: Array<ICell>
}

class LayoutButton extends Component<IProps, IState> {
  state = { row: 3, column: 3, cells: [] }

  componentDidMount = () => {
    this.createCell()
  }

  createCell = () => {
    const cells = []
    for (let i = 0; i < this.state.row; i++) {
      for (let j = 0; j < this.state.column; j++) {
        cells.push({ active: false, row: i, column: j })
      }
    }

    this.setState({ cells })
  }

  render() {
    const { cells } = this.state
    const cellWidth = 100 / this.state.column + '%'
    const cellHeight = 100 / this.state.row + '%'
    return (
      <div
        style={{
          width: 90,
          height: 90,
          background: 'black',
          border: '1px solid #ddd',
          borderRadius: 2,
        }}
      >
        {cells.map((cell: ICell, index) => {
          return (
            <div
              key={index}
              style={{
                background: cell.active ? 'royalBlue' : 'transparent',
                width: cellWidth,
                height: cellHeight,
                float: 'left',
                border: '1px solid #ddd',
              }}
              onMouseEnter={() => {
                const cellsArray = []
                for (let i = 0; i < this.state.row; i++) {
                  for (let j = 0; j < this.state.column; j++) {
                    if (i <= cell.row && j <= cell.column) {
                      cellsArray.push({ active: true, row: i, column: j })
                    } else {
                      cellsArray.push({ active: false, row: i, column: j })
                    }
                  }
                }
                this.setState({ cells: cellsArray })
              }}
              onClick={() => {
                this.props.cellClick(cell.row + 1, cell.column + 1)
              }}
            />
          )
        })}
      </div>
    )
  }
}

export default LayoutButton
