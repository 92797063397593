import { IResponse } from '../models/response/IResponse'
import { IConsumer } from '../models/response/consumer.model'
import { IMessage } from '../models/response/message.model'
import { IRoomResponse } from '../models/response/roomResponse.model'
import { cloneToolData, redraw } from '../utils/drawBoard'
import { NOTIFYCATION_ERROR } from '../utils/notifycation'
import { ServiceBase } from './serviceBase'
class RTCService extends ServiceBase {
  constructor(baseURL: string) {
    super(baseURL)
  }

  // Get Router Capacities
  getRouterCapacities = async () => {
    const url = '/v1/router/capacities'
    const response: IResponse<Array<IMessage>> = await this.service.get(url)
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      console.error(response.message)
      return
    }
    return response.data
  }

  // Create Transport
  createTransport = async () => {
    const url = '/v1/transport/create'
    const response: IResponse<Array<IMessage>> = await this.service.post(url)
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      return
    }
    return response.data
  }
  // Connect Transport
  connectTransport = async (dtlsParameters: any, transportId: string) => {
    const url = '/v1/transport/connect'
    const response: IResponse<Array<IMessage>> = await this.service.post(url, {
      connectTransport: {
        dtlsParameters,
        transportId,
      },
    })
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      return
    }

    return response.data
  }

  // Create Producer
  createProducer = async (producerParams: {
    producerTransportId: string
    kind: string
    rtpParameters: string
    appData: string
  }) => {
    const url = '/v1/producer/create'
    const response: IResponse<Array<IMessage>> = await this.service.post(url, { producerParams })
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      return
    }

    return response.data
  }

  // Create Consumer
  createConsumer = async (consumerParams: {
    rtpCapabilities: string
    consumerTransportId: string // might be
    producerId: string
  }) => {
    const url = '/v1/consumer/create'
    const response: IResponse<{ params: IConsumer }> = await this.service.post(url, {
      consumerParams,
    })
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      return
    }

    return response.data
  }
  kickUser = async (user: any) => {
    const text: string = `Xác nhận mời ${user.username} ra khỏi phòng`
    if (window.confirm(text) === true) {
      await this.service.delete(`v1/room/kickUser/${user.id}`)
    }
  }
  // Close Producer
  closeProducer = async (producerParams: { producerId: string }) => {
    const url = '/v1/producer/close'
    const response: IResponse<Array<IMessage>> = await this.service.post(url, { producerParams })
    if (response.status !== 1) {
      // NOTIFYCATION_ERROR(url, response.message)
      return
    }

    return response.data
  }

  // Check Room Existence
  checkRoomExistence = async (roomname: string) => {
    const url = '/v1/room/checkRoomExistence'
    const response: IResponse<Array<IMessage>> = await this.service.post(url, { roomname })
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      return
    }

    return response.data
  }

  // Create Room
  createRoom = async (roomData: {
    roomname: string
    password: string
    userId: string
    username: string
  }) => {
    const url = '/v1/room/create'
    const response: IResponse<IRoomResponse> = await this.service.post(url, roomData)
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      console.error(response.message)
      return
    }
    this.addToken(response.data.access_token)
    return response.data
  }

  // Join Room Permission
  joinRoomPermission = async (roomData: {
    roomname: string
    password: string
    userId: string
    username: string
  }) => {
    const url = '/v1/room/joinRoomPermission'
    const response: IResponse<IRoomResponse> = await this.service.post(url, roomData)
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      console.error(response.message)
      return
    }
    return response.data
  }

  // Join Room
  joinRoom = async (roomData: {
    roomname: string
    password: string
    userId: string
    username: string
  }) => {
    const url = '/v1/room/join'
    const response: IResponse<IRoomResponse> = await this.service.post(url, roomData)
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      console.error(response.message)
      return
    }
    this.addToken(response.data.access_token)
    return response.data
  }

  // Join Room
  getPermision = async () => {
    const url = '/v1/permission'
    const response: IResponse<any> = await this.service.get(url)
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      console.error(response.message)
      return
    }
    return response.data
  }

  // user Leave
  userLeave = async () => {
    const url = '/v1/room/create'
    const response: IResponse<IRoomResponse> = await this.service.delete(url)
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      return
    }
    return response.data
  }
  // Get All User In Room
  getAllUser = async () => {
    const url = '/v1/room/getAllUserWithYou'
    const response: IResponse<any> = await this.service.get(url)
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      console.error(response.message)
      return
    }
    return response.data
  }
  // Change PassWord
  onChangePassWord = async (password: string) => {
    const response = await this.service.post('/v1/room/changePassword', { password })
    if (response.status === 1) {
      console.log('');
    } else {
      console.log("Change PassWord Fail");
    }
  }
  // Change UserName
  onChangeUserName = async (username: string) => {
    const response = await this.service.post('/v1/user/changename', {
      name: username,
    })
    if (response.status === 1) {
      console.log("Change username success");
      
    } else {
      console.log("Erroe Change UserName");

    }
  }
  // Change IsKey
  onChangeIsKey = async (id: string) => {
    await this.service.post(`/v1/room/authorizationKey/${id}`)
  }
  sendMessage = async (message: string) => {
    const url = '/v1/messenger/send'
    const response: IResponse<any> = await this.service.post(url, {
      message,
    })
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      console.error(response.message)
      return
    }
    return response.data
  }

  getMessage = async () => {
    const url = '/v1/room/getAllMessengers'
    const response: IResponse<any> = await this.service.get(url)
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      console.error(response.message)
      return
    }
    return response.data
  }

  changeConsumerLayer = async (consumerId: string, layer: number) => {
    const url = '/v1/consumer/changeLayer'
    const response: IResponse<any> = await this.service.post(url, {
      id: consumerId,
      layer,
    })
    if (response.status !== 1) {
      console.error(response.message)
      return
    }
    return response.data
  }
  // open White Board
  openWhiteBoard = async () => {
    const url = `/v1/notifies/board/open`
    const response = await this.service.post(url)
    if (response.status !== 1) {
      console.error('Error /v1/notifies/board/open post')
    }
  }
  addToolDraw = async (data: any) => {
    const url = '/v1/notifies/board'
    const response: IResponse<any> = await this.service.post(url, { data })
    if (response.status !== 1) {
      console.error(response.message)
      return
    }
    return response.data
  }
  // Get All Board
  getDrawBoardData = async () => {
    const url = '/v1/notifies/board'
    const response = await this.service.get(url)
    console.log(response);

    const listToolsDataClient = response.data
    const { isg_draw_board } = window
    const listTools = []
    for (let i = 0; i < listToolsDataClient.length; i++) {
      const data = listToolsDataClient[i]
      const cloneData = await cloneToolData(data)
      cloneData.end = true
      cloneData.selectPoint = -1

      cloneData.selectTextbox = false
      cloneData.listPoint = data.listPoint
      cloneData.id = data.id
      cloneData.toolType = data.toolType
      if (data.imageData) {
        cloneData.imageData = data.imageData
      }
      cloneData.activedColor = data.activedColor
      cloneData.defaultColor = data.defaultColor
      cloneData.texts = data.texts
      cloneData.fontSize = data.fontSize
      cloneData.textSizeClone = data.textSizeClone
      cloneData.textSize = data.textSize
      cloneData.mouseOff = data.mouseOff
      cloneData.author = data.author
      listTools.push(cloneData)
    }
    console.log(listTools);

    isg_draw_board.Tools.ToolState.setToolState({ activeTool: null, listTools })
    redraw()
  }
  // modified
  modified = async (data: any) => {
    const url = `/v1/notifies/board`
    const response = await this.service.put(url, data)
    if (response.status !== 1) {
      console.error('Error notifies/board PUT')
      return
    }
  }
  // mouseDown
  mouseDown = async (data: any) => {
    const response = await this.service.delete(
      `/${data}`
    )
    if (response.status !== 1) {
      console.error('Error notifies/board POST')
    }
  }
  // clear All tools
  clearAll = async () => {
    const url = `/v1/notifies/board/all`
    await this.service.post(url)
  }

}


export { RTCService }
