import { Tooltip } from 'antd'
import { t } from 'i18next'
import { Component } from 'react'
import { setCentralPoint } from '../../reducers/centralPointReducer'
import { setConnectionPoint } from '../../reducers/connectionPointReducer'
import { store } from '../../redux/store'
import { utilssManager } from '../../utils/utilsManager'
import './Audio.scss'
type Props = {
  consumerId: string
  audio: any
  id: string
}
type State = {
  mute: boolean
}
class Audio extends Component<Props, State> {
  state = {
    mute: false,
  }
  audioRef: HTMLAudioElement | null = null
  microTest: HTMLElement | any = null
  microphone: any
  analyser: any
  scriptProcessor: any
  componentDidMount = () => {
    this.getStream()
  }
  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.consumerId !== this.props.consumerId) {
      this.getStream()
    }
  }
  initMicroTest = async (stream: MediaStream) => {
    this.microphone && this.microphone.disconnect()
    this.analyser && this.analyser.disconnect()
    this.scriptProcessor && this.scriptProcessor.disconnect()
    const audioContext = new AudioContext()
    this.analyser = audioContext.createAnalyser()
    this.microphone = audioContext.createMediaStreamSource(stream)
    this.scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1)
    this.analyser.smoothingTimeConstant = 0.8
    this.analyser.fftSize = 1024
    this.microphone.connect(this.analyser)
    this.analyser.connect(this.scriptProcessor)
    this.scriptProcessor.connect(audioContext.destination)
    this.scriptProcessor.onaudioprocess = () => {
      const array = new Uint8Array(this.analyser.frequencyBinCount)
      this.analyser.getByteFrequencyData(array)
      const arraySum = array.reduce((a, value) => a + value, 0)
      const average = arraySum / array.length
      if (!this.microTest) {
        return
      }
      const elements = this.microTest.querySelectorAll('div')
      elements[0].style.height = Math.round((((average * 3) / 5) * 3) / 4) + 'px'
      elements[1].style.height = Math.round((average * 3) / 4) + 'px'
      elements[2].style.height = Math.round((((average * 3) / 5) * 3) / 4) + 'px'
    }
  }
  getStream = () => {
    const sfuUtils = utilssManager.SFUUtils
    if (!this.audioRef || !sfuUtils) {
      return
    }
    const { consumerId } = this.props
    const { stream } = sfuUtils.consumers.get(consumerId)
    if (!stream) {
      return
    } else {
      this.audioRef.srcObject = stream
      this.initMicroTest(stream)
    }
  }
  onMuted = async () => {
    const state: any = store.getState()
    const { connectionPoint, centralPoint } = state
    if (centralPoint.id === this.props.id) {
      const newCentralPoint: any = { ...centralPoint }
      newCentralPoint.consumerList.map((i: any) => {
        if (i.id === this.props.audio.id) {
          i.play = !this.props.audio.play
        }
      })
      await store.dispatch(setCentralPoint(newCentralPoint))
    } else {
      const newConnectionPoint: any = [...connectionPoint]
      newConnectionPoint.forEach((item: any) => {
        if (item.id === this.props.id) {
          item.consumerList.map((i: any) => {
            if (i.id === this.props.audio.id) {
              i.play = !this.props.audio.play
            }
          })
        }
      })
      await store.dispatch(setConnectionPoint(newConnectionPoint))
    }
  }
  componentWillUnmount = () => {
    this.microphone && this.microphone.disconnect()
    this.analyser && this.analyser.disconnect()
    this.scriptProcessor && this.scriptProcessor.disconnect()
  }
  render() {
    const { play } = this.props.audio
    return (
      <>
        <audio
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          autoPlay={true}
          muted={!play ? true : false}
          ref={(ref) => {
            this.audioRef = ref
          }}
        />
        {!play ? (
          <Tooltip title={t('mute')}>
            <div
              ref={(ref) => (this.microTest = ref)}
              style={{
                position: 'absolute',
                top: 0,
                width: 30,
                height: 30,
                background: '#00000070',
                borderRadius: '50%',
                zIndex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={this.onMuted}
            >
              <div
                style={{
                  width: 5,
                  background: '#fff',
                  margin: 1,
                  borderRadius: 3,
                  minHeight: '3px',
                  maxHeight: '20px',
                  display: 'none',
                }}
              />
              <div
                style={{
                  width: 5,
                  background: '#fff',
                  margin: 1,
                  borderRadius: 3,
                  minHeight: '5px',
                  maxHeight: '26px',
                  display: 'none',
                }}
              />
              <div
                style={{
                  width: 5,
                  background: '#fff',
                  margin: 1,
                  borderRadius: 3,
                  minHeight: '3px',
                  maxHeight: '20px',
                  display: 'none',
                }}
              />
              <img style={{ width: 16, height: 16 }} src={'./icons/mute.png'} alt="" />
            </div>
          </Tooltip>
        ) : (
          <Tooltip title={t('notmute')}>
            <div
              ref={(ref) => (this.microTest = ref)}
              style={{
                position: 'absolute',
                top: 0,
                width: 30,
                height: 30,
                background: '#00000070',
                borderRadius: '50%',
                zIndex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={this.onMuted}
            >
              <>
                <div
                  style={{
                    width: 5,
                    background: '#fff',
                    margin: 1,
                    borderRadius: 3,
                    minHeight: '3px',
                    maxHeight: '20px',
                  }}
                />
                <div
                  style={{
                    width: 5,
                    background: '#fff',
                    margin: 1,
                    borderRadius: 3,
                    minHeight: '5px',
                    maxHeight: '26px',
                  }}
                />
                <div
                  style={{
                    width: 5,
                    background: '#fff',
                    margin: 1,
                    borderRadius: 3,
                    minHeight: '3px',
                    maxHeight: '20px',
                  }}
                />
              </>
            </div>
          </Tooltip>
        )}
      </>
    )
  }
}

export default Audio
