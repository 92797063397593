export interface IPatientData {
  sid: any
  svid: any
}

const defaultPatientData: IPatientData = {
  sid: -1,
  svid: -1,
}

const patientData: IPatientData = defaultPatientData

export { defaultPatientData, patientData }
