import { Button } from 'antd'
import { Component } from 'react'
import { ISetting } from '../../models/reducers/setting.model'
import { ISpam } from '../../models/reducers/spam.model'
import { setSpamCamera } from '../../reducers/spamCamera'
import { store } from '../../redux/store'
import { utilssManager } from '../../utils/utilsManager'
type Props = {
    consumerId: any
    id: any
    settingState: ISetting
}
type State = {}

class VideoMobile extends Component<Props, State> {
    state = {}
    videoRef: HTMLVideoElement | null = null
    videoStream: MediaStream | null | undefined
    componentDidMount = async () => {
        this.getStream()
    }
    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.consumerId !== this.props.consumerId) {
            this.getStream()
        }
    }
    // initCameraTest = async (deviceId: string | undefined) => {
    //     try {
    //         const config = {
    //             video: {
    //                 deviceId: deviceId ? { exact: deviceId } : undefined,
    //             },
    //             audio: false,
    //         }
    //         if (this.videoRef) {
    //             this.videoStream?.getTracks().forEach((track) => track.stop())
    //         }
    //         this.videoStream = await navigator.mediaDevices.getUserMedia(config)
    //         this.videoRef && this.videoStream && (this.videoRef.srcObject = this.videoStream)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
    componentWillUnmount() {
        if (this.videoRef) {
            this.videoRef.removeEventListener('playing', this.playingUpdate)
        }
    }
    getStream = async () => {
        const { consumerId } = this.props
        const sfuUtils = utilssManager.SFUUtils
        if (!this.videoRef || !sfuUtils) {
            return
        }
        const consumeData = sfuUtils.consumers.get(consumerId)
        if (!consumeData) {
            return
        }
        const { stream } = consumeData
        if (!stream) {
            return
        }
        this.videoRef.srcObject = stream
        this.videoRef.removeEventListener('playing', this.playingUpdate)
        this.videoRef.addEventListener('playing', this.playingUpdate)
    }
    onFullscreen = () => {
        console.log("FullScreen");
        const elem = this.videoRef
        if (!elem) {
            return
        }
        if (elem.requestFullscreen) {
            elem.requestFullscreen()
        }
    }
    playingUpdate = () => {
        const state: any = store.getState()
        const spamCamera = state.spamCamera
        const newSpamCamera: ISpam = { ...spamCamera, disable: false }
        setTimeout(() => {
            store.dispatch(setSpamCamera(newSpamCamera))
        }, 500);
    }
    render() {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                }}
            >
                <Button
                    style={{
                        width: '20px',
                        height: '20px',
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        zIndex: 1000
                    }}
                    onClick={this.onFullscreen} size="small" type="text"
                    icon={<img style={{ width: '16px', height: '16px', position: 'absolute', bottom: 0, right: 0 }} alt="" src="./icons/full-video.png" />} />
                <video
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        background: 'black',
                        borderRadius: '9px',
                    }}
                    autoPlay={true}
                    muted={true}
                    playsInline={true}
                    controls={false}
                    ref={(ref) => { this.videoRef = ref }}
                />
            </div>
        )
    }
}
export default VideoMobile
