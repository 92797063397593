const EVENTS = {
  SOCKET_CONNECTION: 'connect',
  SOCKET_CHANGE_ROOM_PASSWORD: 'SOCKET_CHANGE_ROOM_PASSWORD',
  SOCKET_GET_ROOM_INFO: 'SOCKET_GET_ROOM_INFO',
  SOCKET_DISCONNECT: 'disconnect',
  SOCKET_USER_DISCONNECT: 'SOCKET_USER_DISCONNECT',
  SOCKET_REMOVE_ROOM: 'SOCKET_REMOVE_ROOM',
  SOCKET_USER_LEFT_ROOM: 'SOCKET_USER_LEFT_ROOM',
  SOCKET_USER_JOIN_ROOM: 'SOCKET_USER_JOIN_ROOM',
  INCOMMING_MESSAGE: 'INCOMMING_MESSAGE',
  SOCKET_EXIT_ROOM: 'SOCKET_EXIT_ROOM',
  SOCKET_SEND_MESSAGE: 'SOCKET_SEND_MESSAGE',
  SOCKET_GET_MESSAGE_IN_ROOM: 'SOCKET_GET_MESSAGE_IN_ROOM',
  CHECK_ROOM_ALREADY: 'CHECK_ROOM_ALREADY',
  REQUEST_JOIN_ROOM_PERMISSION: 'REQUEST_JOIN_ROOM_PERMISSION',
  KICKED: 'KICKED',
  FILE_SHARE: 'FILE_SHARE',
  ACTIVE_SPEAKER: 'ACTIVE_SPEAKER',
  AUTHORIZATION_KEY: 'AUTHORIZATION_KEY',
  USER_UPDATE_NAME: 'USER_UPDATE_NAME',
  OPEN_WHITE_BOARD: "OPEN_WHITE_BOARD",
  BOARD_ADD_TOOLS: 'BOARD_ADD_TOOLS',
  BOARD_MODIFED_TOOLS: 'BOARD_MODIFED_TOOLS',
  BOARD_REMOVE_TOOLS: 'BOARD_REMOVE_TOOLS',
}

const RTC_EVENTS = {
  NEW_PRODUCER_DATA: 'NEW_PRODUCER_DATA',
  CREATE_TRANSPORT: 'CREATE_RTC_TRANSPORT',
  CONNECT_TRANSPORT: 'CONNECT_RTC_TRANSPORT',
  CREATE_PRODUCE: 'CREATE_PRODUCE',
  CREATE_CONSUME: 'CREATE_CONSUME',
  CONSUME_RESUME: 'CONSUME_RESUME',
  GET_ROUTER_CAPABILITIES: 'GET_ROUTER_CAPABILITIES',
  GET_PRODUCER: 'GET_PRODUCER',
  NEW_PRODUCER: 'NEW_PRODUCER',
  CLOSE_PRODUCER: 'CLOSE_PRODUCER',
  CLOSE_CONSUME: 'CLOSE_CONSUME',
  UPDATE_CONSUME: 'UPDATE_CONSUME',
  GET_PRODUCER_OF_USER_KEY: 'GET_PRODUCER_OF_USER_KEY',
  
}

export { EVENTS, RTC_EVENTS }
