export const ACTION_TYPES = {
  SET_IMAGE_NONDICOM: 'SET_IMAGE_NONDICOM',
}

interface IAction {
  type: string
  payload: any
}

const initialize: Array<string> = []

const imagesReducer = (state = initialize, action: IAction) => {
  switch (action.type) {
    case ACTION_TYPES.SET_IMAGE_NONDICOM:
      state = action.payload
      return state
    default:
      return state
  }
}

const setImages = (data: Array<string>) => {
  return {
    type: ACTION_TYPES.SET_IMAGE_NONDICOM,
    payload: data,
  }
}

export { imagesReducer, setImages }
