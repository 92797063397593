import { Component } from 'react'
import { connect } from 'react-redux'
import ResizeObserver from 'react-resize-observer'
import { ICentralPoint } from '../../../models/reducers/centralPoint.model'
import { ILargeView } from '../../../models/reducers/largeView.model'
import { setLargeView } from '../../../reducers/largeviewReducer'
import CameraLarge from '../../BridgePoint/Camera/CameraLarge'
import DropExtend from '../../DragAndDrop/DropExtend'
import styles from './CustomView.module.scss'
type Props = {
  notCameras?: boolean
  numberOfSmallCameras: number
  centralPoint: ICentralPoint
  largeView: Array<ILargeView>
  setLargeView(data: Array<ILargeView>): void
}

type State = {
  rect: any
  mainConsumerId: string
}

export class CustomView extends Component<Props, State> {
  state = {
    rect: {
      width: 0,
      height: 0,
    },
    mainConsumerId: '',
  }
  ratio = 1 / 1

  componentDidMount = () => {
    const { centralPoint } = this.props
    for (let i = 0; i < centralPoint.consumerList.length; i++) {
      if (centralPoint.consumerList[i].type === 'video') {
        this.setState({
          mainConsumerId: centralPoint.consumerList[i].id,
        })
        break
      }
    }
  }

  getGridTemplateAreas = () => {
    const { numberOfSmallCameras } = this.props
    let data = ''
    for (let i = 0; i < numberOfSmallCameras; i++) {
      data += `"main x${i}"`
    }
    return data
  }

  getGridTemplateRows = () => {
    const { numberOfSmallCameras } = this.props
    let data = ''
    for (let i = 0; i < numberOfSmallCameras; i++) {
      data += `1fr `
    }
    return data
  }

  getRatio = () => {
    const { numberOfSmallCameras } = this.props
    switch (numberOfSmallCameras) {
      case 2:
        return 1 / 1
      case 3:
        return 4 / 3
      default:
        return 16 / 9
    }
  }

  onDrop = (viewIndex: number, consumerId: string) => {
    if (viewIndex === -1) {
      this.setState({
        mainConsumerId: consumerId,
      })
      return
    }
    const largeView = [...this.props.largeView]
    largeView[viewIndex].consumerId = consumerId
    this.props.setLargeView(largeView)
  }

  render() {
    const { rect } = this.state
    const { numberOfSmallCameras, largeView, notCameras } = this.props
    const smallCameras = []
    for (let i = 0; i < numberOfSmallCameras; i++) {
      smallCameras.push({ consumerId: null })
    }
    let data = []
    if (numberOfSmallCameras === largeView.length) {
      data = largeView
    } else {
      data = smallCameras
    }
    return (
      <div
        className={styles.customViewWrapper}
        style={{
          gridTemplateColumns: `1fr ${(rect.height / numberOfSmallCameras) * this.getRatio()}px `,
          gridTemplateRows: this.getGridTemplateRows(),
          gridTemplateAreas: this.getGridTemplateAreas(),
        }}
      >
        <ResizeObserver
          onResize={(_rect) => {
            this.setState({
              rect: _rect,
            })
          }}
        />
        {data.map((item, i) => (
          <div
            key={i}
            style={{
              gridArea: `x${i}`,
              maxHeight: rect.height / numberOfSmallCameras,
            }}
            className={styles.cameraItem}
          >
            {notCameras ? (
              <div style={{ width: '100%', height: '100%', background: 'var(--background-3)' }} />
            ) : (
              <DropExtend
                style={{ width: '100%', height: '100%', background: 'var(--background-2)' }}
                onDrop={(e: any, data2: any) => {
                  if (e.dropKey === 'central-point') {
                    this.onDrop(i, data2.id)
                  } else {
                    this.onDrop(i, data2.consumer.id)
                  }
                }}
                key={i}
                dropKey={['central-point', 'connection-point']}
              >
                {item.consumerId ? <CameraLarge consumerId={item.consumerId} /> : null}
              </DropExtend>
            )}
          </div>
        ))}
        <div className={styles.cameraItem} style={{ gridArea: 'main' }}>
          {notCameras ? (
            <div style={{ width: '100%', height: '100%', background: 'var(--background-3)' }} />
          ) : (
            <DropExtend
              style={{ width: '100%', height: '100%', background: 'var(--background-2)' }}
              onDrop={(e: any, data1: any) => {
                if (e.dropKey === 'central-point') {
                  this.onDrop(-1, data1.id)
                } else {
                  this.onDrop(-1, data1.consumer.id)
                }
              }}
              dropKey={['central-point', 'connection-point']}
            >
              {this.state.mainConsumerId ? (
                <CameraLarge consumerId={this.state.mainConsumerId} />
              ) : null}
            </DropExtend>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  centralPoint: state.centralPoint,
  largeView: state.largeView,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLargeView: (data: Array<ILargeView>) => dispatch(setLargeView(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomView)
