import { defaultPatient, IPatient } from '../models/reducers/patient.model'

export const ACTION_TYPES = {
  SET_PATIENT: 'SET_PATIENT',
}

interface IAction {
  type: string
  payload: any
}

const initialize: IPatient = defaultPatient

const patientsReducer = (state = initialize, action: IAction) => {
  switch (action.type) {
    case ACTION_TYPES.SET_PATIENT:
      state = action.payload
      return state
    default:
      return state
  }
}

const setPatient = (data: IPatient) => {
  return {
    type: ACTION_TYPES.SET_PATIENT,
    payload: data,
  }
}

export { patientsReducer, setPatient }
