import { ICentralPoint } from '../models/reducers/centralPoint.model'
import { defaultConfirm } from '../models/reducers/confirmWhiteBoard.model'
import { IConnectPoint } from '../models/reducers/connectionPoint.model'
import { ILargeView } from '../models/reducers/largeView.model'
import { setAuth } from '../reducers/authenticateReducer'
import { setCentralPoint } from '../reducers/centralPointReducer'
import { setConfirmReducer } from '../reducers/confirmWhiteBoardReducer'
import { setConnectionPoint } from '../reducers/connectionPointReducer'
// import { setDrawBoardReducer } from '../reducers/drawBoardReducer'
import { setLargeView } from '../reducers/largeviewReducer'
import { setSetting } from '../reducers/settingReducer'
import { WHITE_BOARD_TYPE } from '../reducers/whiteboardReducer'
// import { WHITE_BOARD_TYPE } from '../reducers/whiteboardReducer'
import { store } from '../redux/store'
import { SFU } from './SFU.util'
import { cloneToolData, redraw } from './drawBoard'
import { EVENTS, RTC_EVENTS } from './events'
import { utilssManager } from './utilsManager'
interface IRTCListener {
  socket: any
  sfuUtil: SFU
}
class RTCListener {
  socket: any
  sfuUtil: SFU
  constructor(parameters: IRTCListener) {
    this.socket = parameters.socket
    this.sfuUtil = parameters.sfuUtil
    this.removeEventListener()
    this.addEventListener()
  }
  addEventListener = () => {
    this.socket.on(RTC_EVENTS.CLOSE_CONSUME, this.onCloseConsume)
    this.socket.on(RTC_EVENTS.NEW_PRODUCER, this.onNewProducer)
    this.socket.on(EVENTS.OPEN_WHITE_BOARD, this.onOpenWhiteBoard)
    this.socket.on(EVENTS.BOARD_ADD_TOOLS, this.onWhiteBoardAddTools)
    this.socket.on(EVENTS.BOARD_MODIFED_TOOLS, this.onModifedTools)
    this.socket.on(EVENTS.BOARD_REMOVE_TOOLS, this.onRemoveTools)
    this.socket.on(EVENTS.KICKED, this.onKickUser)
  }

  removeEventListener = () => {
    this.socket.off(RTC_EVENTS.CLOSE_CONSUME)
    this.socket.off(RTC_EVENTS.NEW_PRODUCER)
  }

  onKickUser = async () => {
    await this.sfuUtil.sendTransport.close()
    await this.sfuUtil.recvTransport.close()
    this.socket.off(EVENTS.SOCKET_USER_JOIN_ROOM)
    this.socket.off(EVENTS.SOCKET_USER_LEFT_ROOM)
    this.socket.off(EVENTS.SOCKET_DISCONNECT)
    this.socket.off(RTC_EVENTS.CLOSE_CONSUME)
    this.socket.off(RTC_EVENTS.NEW_PRODUCER)
    store.dispatch(setAuth(false))
  }
  onNewProducer = async (data: {
    producer_id: string
    isKey: boolean
    peerId: string
    peerName: string
    producer_type: string
  }) => {
    const consumer = await this.sfuUtil.createConsumer(data.producer_id)
    const state: any = store.getState()
    // if (data.peerId === this.socket.id) {
    //   return
    // }
    if (data.isKey) {
      const currentCentralPoint: ICentralPoint | null = { ...state.centralPoint }
      if (!currentCentralPoint) {
        return
      }
      currentCentralPoint.consumerList.push({
        id: consumer.id,
        type: consumer.kind,
        active:
          currentCentralPoint.consumerList.filter((consume) => consume.type === 'video').length ===
            0
            ? true
            : false,
        play: true,
      })
      store.dispatch(setCentralPoint(currentCentralPoint))
      if (data.peerId === utilssManager.me) {
        const { settingState } = state
        settingState.isKey = true
        store.dispatch(setSetting(settingState))
      }
    } else {
      const currentConnectionPoint: Array<IConnectPoint> = [...state.connectionPoint]
      const currentUserIndex = currentConnectionPoint.findIndex((user) => user.id === data.peerId)
      if (currentUserIndex === -1) {
        return
      }
      currentConnectionPoint[currentUserIndex].consumerList.push({
        id: consumer.id,
        type: consumer.kind,
        active:
          currentConnectionPoint[currentUserIndex].consumerList.filter(
            (consume) => consume.type === 'video'
          ).length === 0
            ? true
            : false,
        play: true,
      })
      store.dispatch(setConnectionPoint(currentConnectionPoint))
    }
  }

  onCloseConsume = ({ consumerId }: any) => {
    this.sfuUtil.closeConsumer(consumerId)
    const state: any = store.getState()
    const centralPoint: ICentralPoint | null = { ...state.centralPoint }
    const connectionPoint: Array<IConnectPoint> = [...state.connectionPoint]
    const listLargeView: Array<ILargeView> = [...state.largeView]
    if (centralPoint) {
      centralPoint.consumerList = centralPoint.consumerList.filter(
        (consume) => consume.id !== consumerId
      )
    }
    connectionPoint.map((user) => {
      user.consumerList = user.consumerList.filter((consumer) => consumer.id !== consumerId)
      return user
    })
    connectionPoint.map((user) => {
      user.consumerList = user.consumerList.filter((consumer) => consumer.id !== consumerId)
      return user
    })
    listLargeView.map((view) => {
      view.consumerId === consumerId && (view.consumerId = null)
      return view
    })
    store.dispatch(setCentralPoint(centralPoint))
    store.dispatch(setLargeView(listLargeView))
    store.dispatch(setConnectionPoint(connectionPoint))
  }
  onOpenWhiteBoard = async () => {
    const state: any = store.getState()
    if (state.whiteBoard === WHITE_BOARD_TYPE.ACTIVE) {
      console.log("opened");
    } else {
      store.dispatch(setConfirmReducer({
        ...defaultConfirm, visible: true
      }))
    }

    // await store.dispatch(setDrawBoardReducer(WHITE_BOARD_TYPE.ACTIVE))
  }
  onWhiteBoardAddTools = async (e: any) => {
    const { isg_draw_board } = window
    const toolData = isg_draw_board.Tools.ToolState.getToolState()
    const { listTools } = toolData
    const newListTool = [...listTools]
    const cloneData = await cloneToolData(e)
    cloneData.end = true
    cloneData.selectPoint = -1
    cloneData.selectTextbox = false
    cloneData.id = e.id
    cloneData.activedColor = e.activedColor
    if (e.imageData) {
      cloneData.imageData = e.imageData
    }
    cloneData.defaultColor = e.defaultColor
    cloneData.listPoint = e.listPoint
    cloneData.texts = e.texts
    cloneData.textSizeClone = e.textSizeClone
    cloneData.textSize = e.textSize
    cloneData.fontSize = e.fontSize
    cloneData.mouseOff = e.mouseOff
    cloneData.author = e.author

    newListTool.unshift(cloneData)
    isg_draw_board.Tools.ToolState.setToolState({
      activeTool: toolData.activeTool,
      listTools: newListTool,
    })
    redraw()
  }
  onModifedTools = async (e: any) => {
    const { isg_draw_board } = window
    const toolData = isg_draw_board.Tools.ToolState.getToolState()
    const { listTools } = toolData
    const newListTool = [...listTools]
    const modifiedIndex = newListTool.findIndex((i) => i.id === e.id)
    if (modifiedIndex !== -1) {
      const cloneData = await cloneToolData(e)
      cloneData.end = true
      cloneData.selectPoint = -1
      cloneData.selectTextbox = false
      cloneData.id = e.id
      cloneData.activedColor = e.activedColor
      if (e.imageData) {
        cloneData.imageData = e.imageData
      }
      cloneData.defaultColor = e.defaultColor
      cloneData.listPoint = e.listPoint
      cloneData.texts = e.texts
      cloneData.textSizeClone = e.textSizeClone
      cloneData.textSize = e.textSize
      cloneData.fontSize = e.fontSize
      cloneData.mouseOff = e.mouseOff
      cloneData.author = e.author

      newListTool[modifiedIndex] = cloneData
      isg_draw_board.Tools.ToolState.setToolState({
        activeTool: toolData.activeTool,
        listTools: newListTool,
      })
    }
    redraw()
  }
  onRemoveTools = async (e: any) => {
    const listToolsDataClient = e
    const { isg_draw_board } = window
    const listTools = []
    for (let i = 0; i < listToolsDataClient.length; i++) {
      const data = listToolsDataClient[i]
      const cloneData = await cloneToolData(data)
      cloneData.end = true
      cloneData.selectPoint = -1
      cloneData.selectTextbox = false
      cloneData.listPoint = data.listPoint
      cloneData.id = data.id
      cloneData.toolType = data.toolType
      if (data.imageData) {
        cloneData.imageData = data.imageData
      }
      cloneData.activedColor = data.activedColor
      cloneData.defaultColor = data.defaultColor
      cloneData.texts = data.texts
      cloneData.fontSize = data.fontSize
      cloneData.textSizeClone = data.textSizeClone
      cloneData.textSize = data.textSize
      cloneData.mouseOff = data.mouseOff
      listTools.push(cloneData)
    }
    isg_draw_board.Tools.ToolState.setToolState({ activeTool: null, listTools })
    redraw()
  }
}

export { RTCListener }
