import { SFU } from './SFU.util'
import { RoomListener } from './room.util'
import { RTCListener } from './rtc.util'

export interface IUtilsManager {
  RTCUtils: RTCListener | null
  SFUUtils: SFU | null
  RoomUtils: RoomListener | null
  me: string
}

export const defaultUtilsManager: IUtilsManager = {
  RTCUtils: null,
  SFUUtils: null,
  RoomUtils: null,
  me: '',
}

export const utilssManager: IUtilsManager = defaultUtilsManager
