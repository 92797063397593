import { defaultRoom, IRoom } from '../models/reducers/room.model'

export const ACTION_TYPES = {
  ROOM: 'ROOM',
}

interface IAction {
  type: string
  payload: any
}

const initialize: IRoom = defaultRoom

const roomReducer = (state = initialize, action: IAction) => {
  switch (action.type) {
    case ACTION_TYPES.ROOM:
      state = action.payload
      return state

    default:
      return state
  }
}

const setRoom = (data: IRoom) => {
  return {
    type: ACTION_TYPES.ROOM,
    payload: data,
  }
}

export { roomReducer, setRoom }
