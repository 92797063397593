import { defaultLayout, ILayout } from '../models/reducers/layout.model'

export const ACTION_TYPES = {
  LAYOUT: 'LAYOUT',
}

interface IAction {
  type: string
  payload: any
}

const initialize: ILayout = defaultLayout

const layoutReducer = (state = initialize, action: IAction) => {
  switch (action.type) {
    case ACTION_TYPES.LAYOUT:
      state = action.payload
      return state

    default:
      return state
  }
}

const setLayout = (data: ILayout) => {
  return {
    type: ACTION_TYPES.LAYOUT,
    payload: data,
  }
}

export { layoutReducer, setLayout }
