import {
    annotationElipseToolDraw,
    annotationRectangleToolDraw,
    arrowTextoolDraw,
    arrowToolDraw,
    elipseToolDraw,
    eraserToolDraw,
    heartToolDraw,
    imageToolDraw,
    lengthToolDraw,
    pencilToolDraw,
    polygonToolDraw,
    rectToolDraw,
    starToolDraw,
} from '../../components/Views/WhiteBoard/actionTools'
export interface IDrawTool {
    id: string
    active: boolean | undefined
    img: string
    name: string
    onClick(): void
}
export const defaultDrawTools: Array<IDrawTool> = [
    {
        id: 'draw-length',
        active: false,
        img: '/icons/tools/line.png',
        name: 'Length',
        onClick: () => lengthToolDraw(),
    },
    {
        id: 'draw-rect',
        active: false,
        img: '/icons/tools/rect.png',
        name: 'Rect',
        onClick: () => rectToolDraw(),
    },
    {
        id: 'draw-arrow',
        active: false,
        img: '/icons/tools/arrow.png',
        name: 'Arrow',
        onClick: () => arrowToolDraw(),
    },
    {
        id: 'draw-polygon',
        active: false,
        img: '/icons/tools/polygon.png',
        name: 'Polygon',
        onClick: () => polygonToolDraw(),
    },
    {
        id: 'draw-arrow-text',
        active: false,
        img: '/icons/tools/arrow-text.png',
        name: 'Arrow text',
        onClick: () => arrowTextoolDraw(),
    },
    {
        id: 'draw-annotation-elipse',
        active: false,
        img: '/icons/tools/ellipse-text.png',
        name: 'Annotation Elipse',
        onClick: () => annotationElipseToolDraw(),
    },
    {
        id: 'draw-annotation-rectangle',
        active: false,
        img: '/icons/tools/rect-text.png',
        name: 'Annotation Rectangle',
        onClick: () => annotationRectangleToolDraw(),
    },
    {
        id: 'draw-pencil',
        active: false,
        img: '/icons/tools/pencil.png',
        name: 'Pencil',
        onClick: () => pencilToolDraw(),
    },
    {
        id: 'draw-elipse',
        active: false,
        img: '/icons/tools/ellipse.png',
        name: 'Elipse',
        onClick: () => elipseToolDraw(),
    },
    {
        id: 'draw-heart',
        active: false,
        img: '/icons/tools/heart.png',
        name: 'Heart',
        onClick: () => heartToolDraw(),
    },
    {
        id: 'draw-star',
        active: false,
        img: '/icons/tools/star.png',
        name: 'Star',
        onClick: () => starToolDraw(),
    },

    {
        id: 'draw-image',
        active: false,
        img: '/icons/tools/image.png',
        name: 'Image',
        onClick: () => imageToolDraw(),
    },

    {
        id: 'eraser',
        active: false,
        img: '/icons/tools/eraser.png',
        name: 'Eraser',
        onClick: () => eraserToolDraw(),
    },
]
