import { Component } from 'react'
import { connect } from 'react-redux'
import ResizeObserver from 'react-resize-observer'
import { ICentralPoint } from '../../models/reducers/centralPoint.model'
import { IConnectPoint } from '../../models/reducers/connectionPoint.model'
import { ILayout } from '../../models/reducers/layout.model'
import Abbreviations from './Abbreviations'
import styles from './GridParticipants.module.scss'
import ParticipantsItem from './ParticipantsItem'
type Props = {
  centralPoint: ICentralPoint
  connectionPoint: Array<IConnectPoint>
  layout: ILayout
}

type State = {
  width: number
  height: number
  data: Array<any>
}
export interface IUserParticipants {
  id: string
  username: string
  videos: Array<{ active: boolean; id: string; play: boolean; type: string }>
  audios: Array<{ active: boolean; id: string; play: boolean; type: string }>
  totalView: number
}

export class GridParticipants extends Component<Props, State> {
  state = {
    width: 0,
    height: 0,
    data: [],
  }
  rect = { width: 0, height: 0 }
  wrapByCol = false
  margin = 8

  renderGridParticipants = () => {
    const users: Array<IUserParticipants> = []
    let total = 0
    const { centralPoint, connectionPoint } = this.props
    const videos = centralPoint?.consumerList?.filter((i) => i.type === 'video')
    const audios = centralPoint?.consumerList?.filter((i) => i.type === 'audio')
    if (centralPoint) {
      const userCenterPoint: IUserParticipants = {
        id: centralPoint.id,
        username: centralPoint.username,
        videos,
        audios,
        totalView: videos.length === 0 ? 1 : videos.length,
      }
      users.push(userCenterPoint)
      total += userCenterPoint.totalView
    }
    connectionPoint.forEach((user) => {
      const videosConnectionPoint = user.consumerList.filter((i) => i.type === 'video')
      const audiosConnectionPoint = user.consumerList.filter((i) => i.type === 'audio')
      const userConnectionPoint: IUserParticipants = {
        id: user.id,
        username: user.username,
        videos: videosConnectionPoint,
        audios: audiosConnectionPoint,
        totalView: videosConnectionPoint.length === 0 ? 1 : videosConnectionPoint.length,
      }
      total += userConnectionPoint.totalView
      users.push(userConnectionPoint)
    })
    return { users, total }
  }

  getRowAndColumn = (total: number) => {
    let row = 1
    let col = 1
    while (row * col < total) {
      if (row === col) {
        if (this.wrapByCol) {
          row = row + 1
        } else {
          col = col + 1
        }
      } else {
        if (this.wrapByCol) {
          col = col + 1
        } else {
          row = row + 1
        }
      }
    }
    return { row, col }
  }

  render() {
    const { layout } = this.props
    const { users, total } = this.renderGridParticipants()
    const { row, col } = this.getRowAndColumn(total)
    return (
      <div className={`${styles.wrapperGridParticipants} `}>
        <ResizeObserver onResize={(_rect) => { }} />

        {users.map((user) => {
          if (user.videos.length === 0) {
            return (
              <Abbreviations
                key={user.id}
                row={row}
                col={col}
                widthParticipants={layout.widthParticipants}
                name={user.username}
                user={user}
              />
            )
          } else {
            return user.videos.map((video, index) => {
              return (
                <ParticipantsItem
                  row={row}
                  col={col}
                  index={index}
                  key={video.id}
                  widthParticipants={layout.widthParticipants}
                  user={user}
                  video={video}
                />
              )
            })
          }
        })}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  centralPoint: state.centralPoint,
  connectionPoint: state.connectionPoint,
  layout: state.layout,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(GridParticipants)
