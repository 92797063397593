import { Typography } from 'antd'
import { Component } from 'react'
import { IRoom } from '../../models/reducers/room.model'
import { encodeLink } from '../../utils/shareLink'
type Props = {
  roomState: IRoom
}
type State = {
  link: any
}
const { Paragraph } = Typography
export default class ShareLink extends Component<Props, State> {
  state = {
    link: window.location.href,
  }
  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.roomState !== this.props.roomState) {
      this.getLink()
    }
  }
  componentDidMount = async () => {
    this.getLink()
  }
  getLink = () => {
    const { roomState } = this.props
    const link = encodeLink(roomState)
    this.setState({
      link,
    })
  }
  render() {
    return (
      <div style={{ width: 200 }}>
        <Paragraph
          ellipsis={{
            expandable: true,
            rows: 3,
          }}
          copyable={{ text: this.state.link }}
        >
          {JSON.stringify(this.state.link)}
        </Paragraph>
      </div>
    )
  }
}
