export interface IShareLink {
    visible: boolean
}
export const defaultIShareLink: IShareLink = {
    visible: false
}

export const ACTION_TYPES = {
    SHARE: 'SHARE',
}

interface IAction {
    type: string
    payload: any
}

const initialize: IShareLink = defaultIShareLink

const shareLink = (state = initialize, action: IAction) => {
    switch (action.type) {
        case ACTION_TYPES.SHARE:
            state = action.payload
            return state

        default:
            return state
    }
}

const setShareLink = (data: any) => {
    return {
        type: ACTION_TYPES.SHARE,
        payload: data,
    }
}

export { shareLink, setShareLink }
