import { GoogleOutlined, LockOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Form, Input, Row, Switch } from 'antd'
import DetectRTC from 'detectrtc'
import { t } from 'i18next'
import { Component } from 'react'
import { connect } from 'react-redux'
import { IConfigAdmin } from '../../models/reducers/configAdmin.model'
import { IPatient } from '../../models/reducers/patient.model'
import { IRoom } from '../../models/reducers/room.model'
import { setRoom } from '../../reducers/roomReducer'
import styles from './style.module.scss'
type Props = {
  prev?(): void
  setRoom(data: IRoom): void
  onShowHomePage(): void
  patient: IPatient
  configAdminState: Array<IConfigAdmin>
  roomState: IRoom
  hasVideo: boolean
  hasAudio: boolean
}
type State = {}
class Room extends Component<Props, State> {
  state = {}
  formRef: any = null
  removeAccents = (str: string) => {
    const AccentsMap = [
      'aàảãáạăằẳẵắặâầẩẫấậ',
      'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
      'dđ',
      'DĐ',
      'eèẻẽéẹêềểễếệ',
      'EÈẺẼÉẸÊỀỂỄẾỆ',
      'iìỉĩíị',
      'IÌỈĨÍỊ',
      'oòỏõóọôồổỗốộơờởỡớợ',
      'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
      'uùủũúụưừửữứự',
      'UÙỦŨÚỤƯỪỬỮỨỰ',
      'yỳỷỹýỵ',
      'YỲỶỸÝỴ',
    ]
    for (let i = 0; i < AccentsMap.length; i++) {
      const re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g')
      const char = AccentsMap[i][0]
      str = str.replace(re, char)
    }
    return str
  }
  componentDidMount = () => {
    this.setState({
      notSupport: {
        camera: DetectRTC.hasWebcam && DetectRTC.isWebsiteHasWebcamPermissions ? false : true,
        micro:
          DetectRTC.hasMicrophone && DetectRTC.isWebsiteHasMicrophonePermissions ? false : true,
      },
    })
    const { roomState } = this.props
    this.formRef.setFieldsValue({
      roomname: roomState.roomname,
      username: roomState.username,
      password: roomState.password,
      camera: this.props.hasVideo,
      micro: this.props.hasAudio,
    })
  }
  submitForm = async (values: any) => {
    const { username, roomname, password, camera, micro } = values
    const roomData: IRoom = {
      roomname,
      username,
      password: password ? password : '',
      openCamera: camera,
      openMicro: micro,
    }
    await this.props.setRoom(roomData)
    this.props.onShowHomePage()
  }
  render() {
    return (
      <div
        className={styles.room}
        style={{
          background: 'var(--background-2)',
        }}
      >
        <div className={styles.formJoinRoom}>
          <Row
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              padding: 15,
            }}
          >
            <Avatar
              src={'./favicon.ico'}
              size={{ xs: 80, sm: 80, md: 80, lg: 80, xl: 80, xxl: 100 }}
            />
          </Row>
          <Form
            ref={(ref) => (this.formRef = ref)}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            onFinish={this.submitForm}
            autoComplete="off"
          >
            <Form.Item
              name="roomname"
              rules={[{ required: true, message: 'Tên phòng không được để trống!' }]}
            >
              <Input size="middle" placeholder={t('roomname')} prefix={<GoogleOutlined />} />
            </Form.Item>

            <Form.Item name="password">
              <Input.Password
                size="middle"
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder={t('password')}
              />
            </Form.Item>

            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Tên hiển thị không được để trống' }]}
            >
              <Input size="middle" placeholder={t('username')} prefix={<UserOutlined />} />
            </Form.Item>

            <Form.Item valuePropName="checked" name="camera">
              <Switch
                disabled={this.props.hasVideo ? false : true}
                checkedChildren="Camera"
                unCheckedChildren="Camera"
              />
            </Form.Item>
            <Form.Item valuePropName="checked" name="micro">
              <Switch
                disabled={this.props.hasAudio ? false : true}
                checkedChildren="Micro"
                unCheckedChildren="Micro"
              />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 24 }}>
              <div>
                <Button
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  icon={<LogoutOutlined />}
                >
                  {t('submit')}
                </Button>
              </div>
            </Form.Item>
          </Form>

          <div style={{ textAlign: 'center', color: '#6a5af9' }}>Copyright 2021 © C+.jsc</div>
          <div style={{ textAlign: 'center', color: '#6a5af9' }}>
            <span>{t('support')}:</span>
            <span>096.873.6995 - Ngô Văn Sơn</span>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  configAdminState: state.configAdminState,
  patient: state.patient,
  roomState: state.roomState,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setRoom: (data: IRoom) => dispatch(setRoom(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Room)
