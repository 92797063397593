import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Input, Menu, Row, Tooltip } from 'antd'
import { Component } from 'react'
import { connect } from 'react-redux'
import ResizeObserver from 'react-resize-observer'
import { colors, IDrawBoard } from '../../../models/reducers/drawBoard.model'
import { IRoom } from '../../../models/reducers/room.model'
import { IDrawTool } from '../../../models/reducers/toolBoard.model'
import { setDrawBoardReducer } from '../../../reducers/drawBoardReducer'
import { setDrawTools } from '../../../reducers/toolsBoard'
import { setWhiteBoardReducer, WHITE_BOARD_TYPE } from '../../../reducers/whiteboardReducer'
import { servicesManager } from '../../../services/servicesManager'
import { redraw, renderDrawBoard } from '../../../utils/drawBoard'
import { utilssManager } from '../../../utils/utilsManager'
import { disableAll, redoTool, textToolDraw, undoTool } from './actionTools'
import style from "./whiteBoard.module.scss"
type Props = {
    drawTools: Array<IDrawTool>
    drawBoard: IDrawBoard
    roomState: IRoom
    setDrawTools(data: any): void
    setDrawBoard(data: any): void
    whiteBoard: string
    setWhiteBoard(data: string): void
}
type State = {
    styleText: any
}
class WhiteBoard extends Component<Props, State> {
    state = {
        styleText: {
            bold: false,
            underline: false,
            italic: false,
            size: '14',
        },
    }
    toolActiveIndex = -1
    componentDidMount = () => {
        const { roomState } = this.props
        const isg_draw_board = window.isg_draw_board
        const service = servicesManager.RTC
        const canvas: any = document.getElementById('board')
        isg_draw_board.Tools.MouseEvent.mouseInput.enable(canvas)
        isg_draw_board.Tools.ToolOption.setRenderNode(canvas)
        isg_draw_board.Tools.ToolOption.setAuthor({
            id: utilssManager.RoomUtils?.socket.id,
            name: roomState.username,
        })
        service.getDrawBoardData()
        canvas.addEventListener('vrpacstoolsrender', () => {
            renderDrawBoard()
        })
        canvas.addEventListener('vrpacstoolsadded', async (e: any) => {
            console.log('vrpacstoolsadded', e.detail)
            const data = {
                ...e.detail.activeTool,
                toolType: e.detail.toolType,
                userId: utilssManager.RoomUtils?.socket.id,
            }
            setTimeout(async () => {
                await service.addToolDraw(data)
            }, 500)
        })
        canvas.addEventListener('vrpacstoolsmousedown', async (event: any) => {
            if (event.detail.which === 3) {
                if (event) {
                    event.stopPropagation()
                    event.preventDefault()
                }
                const toolData = isg_draw_board.Tools.ToolState.getToolState()
                console.log(toolData.activeTool)
                if (toolData && toolData.activeTool) {
                    if (
                        toolData.activeTool.author.id === utilssManager.RoomUtils?.socket.id
                    ) {
                        await service.mouseDown(toolData.activeTool.id)
                    }
                }
            }
        })

        canvas.addEventListener('vrpacstoolsmodifed', (e: any) => {
            const data = {
                ...e.detail.activeTool,
                toolType: e.detail.toolType,
                userId: utilssManager.RoomUtils?.socket.id
            }
            setTimeout(async () => {
                await service.modified(data)
            }, 500)
        })
    }
    onActiveTool = async (id: string, index: any) => {
        const btn = document.getElementById('text-draw-btn')
        if (btn) {
            btn.className = style["btn-tool-draw"]
        }
        this.toolActiveIndex = index
        const { drawTools } = this.props
        const newDrawTools = [...drawTools]
        if (drawTools[index].active !== undefined) {
            newDrawTools.forEach((item) => {
                if (item.active !== undefined) {
                    if (item.id === id) {
                        item.active = true
                    } else {
                        item.active = false
                    }
                }
            })
        }
        this.props.setDrawTools(newDrawTools)
    }
    onActiveColor = async (drawColor: string) => {
        const { drawTools, drawBoard } = this.props
        const newDrawBoard = { ...drawBoard }
        newDrawBoard.drawColor = drawColor
        await this.props.setDrawBoard(newDrawBoard)
        if (drawTools[this.toolActiveIndex]) {
            drawTools[this.toolActiveIndex].onClick()
        }
    }
    onSetStyleText = (name: string, value: any, e?: any) => {
        const { drawTools } = this.props
        const newDrawTools = [...drawTools]
        newDrawTools.forEach((item) => {
            if (item.active !== undefined) {
                item.active = false
            }
        })
        this.props.setDrawTools(newDrawTools)

        const btn = document.getElementById('text-draw-btn')
        if (btn) {
            if (btn.className.indexOf(style.active) === -1) {
                btn.className = `${style["btn-tool-draw"]}  ${style.active}`
            }
        }
        const { styleText } = this.state
        const newStyleText = { ...styleText }
        switch (name) {
            case 'bold':
                newStyleText.bold = value
                break
            case 'italic':
                newStyleText.italic = value
                break
            case 'underline':
                newStyleText.underline = value
                break
            case 'size': {
                let size = e.target.value
                const value1 = Number(e.target.value)
                if (value1 <= 10) {
                    size = '12'
                }
                newStyleText.size = size
                break
            }
            default:
                break
        }
        this.setState({
            styleText: newStyleText,
        })
        textToolDraw(newStyleText)
    }
    changeBackground = async () => {
        const canvas = document.getElementById('board')
        const board = document.getElementById('whiteBoard')
        if (canvas && board) {
            if (canvas.style.background === 'transparent') {
                canvas.style.background = 'rgb(255, 255, 255)'
                board.style.background = '#202124'
            } else {
                canvas.style.background = 'transparent'
                board.style.background = 'transparent'
            }
        }
    }
    closeWhiteBoard = async () => {
        this.props.setDrawBoard({
            ...this.props.drawBoard,
            visible: false,
        })
        const canvas = document.getElementById('board')
        if (canvas) {
            disableAll(canvas)
        }
        const { whiteBoard } = this.props
        if (whiteBoard === WHITE_BOARD_TYPE.DISABLE) {
            await this.props.setWhiteBoard(WHITE_BOARD_TYPE.ACTIVE)
        } else {
            await this.props.setWhiteBoard(WHITE_BOARD_TYPE.DISABLE)
        }
    }
    clearAllTools = async () => {
        const service = servicesManager.RTC
        service.clearAll()
        console.log("clearTools");
        
    }
    render() {
        const { drawTools, drawBoard } = this.props
        const { drawColor } = drawBoard
        const { styleText } = this.state
        return (
            <div id='whiteBoard' className={style.whiteBoard}>
                <ResizeObserver
                    onResize={(rect: { width: number; height: number }) => {
                        const canvas: any = document.getElementById('board')
                        const wrapper = document.getElementById('whiteBoard')
                        if (wrapper) {
                            if (rect.width / rect.height > 16 / 9) {
                                canvas.height = rect.height
                                canvas.width = (rect.height * 16) / 9
                            } else {
                                canvas.width = rect.width
                                canvas.height = (rect.width * 9) / 16
                            }
                            redraw()
                        }
                    }}
                />
                <div className={style.whiteBoardMain}>
                    <canvas id="board" className={style.board}
                        onContextMenu={(e) => {
                            e.preventDefault()
                            return false
                        }}
                    />
                    <div className={style.tools} >
                        {
                            drawTools.map((item, index) => {
                                return (
                                    <Tooltip title={item.name} key={index}>
                                        <Button
                                            className={`${style["btn-tool-draw"]} ${item.active ? style.active : ''}`}
                                            onClick={
                                                () => {
                                                    this.onActiveTool(item.id, index)
                                                    item.onClick()
                                                }}
                                        >
                                            <img src={item.img} alt={item.img} />
                                        </Button>
                                    </Tooltip>
                                )
                            })
                        }
                        <Dropdown
                            trigger={['click']}
                            overlay={
                                <Menu id={style["menu-draw-color"]}>
                                    <Menu.Item className={style["ant-dropdown-menu-item"]} >
                                        <Row className={style["ant-row"]}>
                                            {colors.map((item, index) => (
                                                <Col
                                                    key={index}
                                                    span={6}
                                                    className={style["ant-col"]}
                                                    style={{ background: item }}
                                                    onClick={() => this.onActiveColor(item)}
                                                >
                                                    {drawColor === item && <CheckOutlined />}
                                                </Col>
                                            ))}
                                        </Row>
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button className={style["btn-tool-draw"]} onClick={() => { }}>
                                <img src={'/icons/tools/color.png'} style={{ width: 34, height: 34 }} />
                            </Button>
                        </Dropdown>
                        <Button
                            className={style["btn-tool-draw"]}
                            id="text-draw-btn"
                            onClick={() => {
                                this.onActiveTool('text', 0)
                                const btn = document.getElementById('text-draw-btn')
                                if (btn) {
                                    if (btn.className.indexOf('active') === -1) {
                                        btn.className = `${style["btn-tool-draw"]} ${style.active}`
                                    }
                                }
                                textToolDraw(styleText)
                            }}
                        >
                            <img src={'/icons/tools/text.png'} style={{ width: 24, height: 24 }} />
                        </Button>
                        <Input
                            style={{ width: 60 }}
                            value={styleText.size}
                            type="number"
                            onChange={(e) => {
                                this.onSetStyleText('size', e.target.value, e)
                            }}
                        />
                        <button
                            className={`${style["text-style"]} ${styleText.bold ? style.active : ''}`}
                            onClick={(e) => {
                                this.onSetStyleText('bold', !styleText.bold, e)
                            }}
                        >
                            B
                        </button>
                        <button
                            className={`${style["text-style"]} ${style.italic} ${styleText.italic ? style.active : ''}`}
                            onClick={(e) => {
                                this.onSetStyleText('italic', !styleText.italic, e)
                            }}
                        >
                            I
                        </button>
                        <Tooltip title={'Change background board'}>
                            <Button
                                className={style["btn-tool-draw"]}
                                onClick={() => {
                                    this.changeBackground()
                                }}
                            >
                                <img src={'/icons/tools/background.png'} />
                            </Button>
                        </Tooltip>
                        <Button
                            className={style["btn-tool-draw"]}
                            onClick={() => {
                                undoTool()
                            }}
                        >
                            <img src={'/icons/tools/undo.png'} />
                        </Button>
                        <Button
                            className={style["btn-tool-draw"]}
                            onClick={() => {
                                redoTool()
                            }}
                        >
                            <img src={'/icons/tools/redo.png'} />
                        </Button>
                        <Tooltip title={'Clear all'}>
                            <Button
                                className={style["btn-tool-draw"]}
                                onClick={() => {
                                    this.clearAllTools()
                                }}
                            >
                                <img src={'/icons/tools/cleaning.svg'} style={{ width: 24, height: 24 }} />
                            </Button>
                        </Tooltip>
                        <Button
                            className={style["btn-tool-draw"]}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            onClick={() => {
                                this.closeWhiteBoard()
                            }}
                        >
                            <CloseOutlined style={{ fontSize: 24, color: 'red', padding: 0, margin: 0 }} />
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state: any) => {
    return {
        drawTools: state.drawTools,
        drawBoard: state.drawBoard,
        roomState: state.roomState,
        whiteBoard: state.whiteBoard
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        setDrawTools: (data: any) => dispatch(setDrawTools(data)),
        setDrawBoard: (data: any) => dispatch(setDrawBoardReducer(data)),
        setWhiteBoard: (data: any) => dispatch(setWhiteBoardReducer(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(WhiteBoard)
