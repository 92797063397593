import { Button, Popover, Tooltip } from 'antd'
import { t } from 'i18next'
import { Component } from 'react'
import { connect } from 'react-redux'
import { ICentralPoint } from '../../models/reducers/centralPoint.model'
import { IConnectPoint } from '../../models/reducers/connectionPoint.model'
import { ILargeView } from '../../models/reducers/largeView.model'
import { ISetting } from '../../models/reducers/setting.model'
import { setCentralPoint } from '../../reducers/centralPointReducer'
import { setConnectionPoint } from '../../reducers/connectionPointReducer'
import { setLargeView } from '../../reducers/largeviewReducer'
import { isIphone } from '../../utils/checkIos'
import { mobileAndTabletCheck } from '../../utils/checkMobileAndTable'
import { utilssManager } from '../../utils/utilsManager'
import Audio from '../Audio/Audio'
import Camera from '../BridgePoint/Camera/Camera'
import DragExtend from '../DragAndDrop/DragExtend'
import RoomMaster from '../Views/Mobile/RoomMaster'
import { IUserParticipants } from './GridParticipants'

type Props = {
  settingState: ISetting
  centralPoint: ICentralPoint
  connectionPoint: Array<IConnectPoint>
  row: number
  col: number
  index: number
  widthParticipants: any
  user: IUserParticipants
  video: { active: boolean; id: string; play: boolean; type: string }
  setCentralPoint(data: ICentralPoint | null): void
  setConnectionPoint(data: Array<IConnectPoint>): void
  setLargeView(data: Array<ILargeView>): void
}

type State = {}

export class ParticipantsItem extends Component<Props, State> {
  state = {}

  onActiveCamera = (userId: string, consumerId: string) => {
    const centralPointValue = this.props.centralPoint
    if (!centralPointValue) {
      return
    }
    if (centralPointValue.id === userId) {
      const centralPoint = { ...centralPointValue }
      centralPoint.consumerList.map((consumer) => {
        if (consumer.id === consumerId) {
          consumer.active = true
        } else {
          consumer.active = false
        }
        return consumer
      })
      this.props.setCentralPoint(centralPoint)
    } else {
      const connectionPoint = [...this.props.connectionPoint]
      const currentUser = connectionPoint.find((point) => point.id === userId)
      if (!currentUser) {
        return
      }
      currentUser.consumerList.map((consumer) => {
        if (consumer.id === consumerId) {
          consumer.active = true
        } else {
          consumer.active = false
        }
        return consumer
      })
      this.props.setConnectionPoint(connectionPoint)
    }
  }

  shorten = (value: string) => {
    let name = value.trim()
    while (name.indexOf('  ') !== -1) {
      name = name.replace('  ', ' ')
    }
    const array = name.split(' ')
    if (array.length > 1) {
      let result = ''
      for (let i = 0; i < array.length; i++) {
        if (i === array.length - 1) {
          result += '.' + array[i]
        } else if (i === 0) {
          result += array[i].charAt(0)
        } else {
          result += '.' + array[i].charAt(0)
        }
      }
      return result
    } else {
      return array[0]
    }
  }

  render() {
    const { row, col, widthParticipants, user, video, index, centralPoint, settingState } = this.props
    const iPhone = isIphone()
    this.shorten(user.username)
    return (
      <DragExtend
        style={{
          borderColor: `${video.active ? 'var(--color-active)' : 'var(--background-4)'}`,
          width: `calc(100% / ${col} - 8px)`,
          height: `calc(100% / ${row} - 8px)`,
          borderRadius: 4,
          margin: 4,
          maxHeight: widthParticipants === '100%' ? '100%' : '105px',
          maxWidth: widthParticipants === '100%' ? '100%' : '180px',
          minHeight: '90px',
          minWidth: '160px',
          position: 'relative',
        }}
        // style={{ border: '1px solid #3f51b5', padding: 1 }}
        key={video.id}
        dragKey={'central-point'}
        data={video}
        onClick={async () => {
          if (mobileAndTabletCheck()) {
            this.props.setLargeView([{ consumerId: video.id }])
          } else {
            this.onActiveCamera(user.id, video.id)
          }
        }}
      >
        {user.id !== centralPoint.id && settingState.isKey === true &&
          <Tooltip title={t("more")}>
            <Popover
              style={{
                position: 'relative',
                borderRadius: '10px',
                overflow: 'hidden'
              }}
              title={null}
              content={<RoomMaster user={user} />}
              trigger="click"
            >
              <Button
                style={{
                  position: 'absolute',
                  top: 0,
                  right: '4%',
                  background: 'transparent',
                  border: 'none',
                  width: '16px',
                  height: '16px',
                  padding: 0,
                  zIndex: 1000
                }}
                icon={<img style={{
                  width: 'auto',
                  height: 'auto',
                  position: `${iPhone ? 'absolute' : 'relative'}`,
                  top: 0,
                  right: '1%'
                }} src="./icons/more.png" alt="" />}
              />
            </Popover>
          </Tooltip>
        }
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: '0',
            padding: '0 5px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            textShadow: 'var(--text-shadow-black)',
            width: 'calc(100% - 0px)',
            zIndex: 1,
            color: utilssManager.me === user.id ? 'orange' : 'var(--color-light)',
          }}
        >
          {this.shorten(user.username || '')}
        </div>
        {
          index === 0 ?
            utilssManager.me !== user.id ?
              user.audios.length === 0 ? ''
                :
                <Audio id={user.id} audio={user.audios[0]} consumerId={user.audios[0].id} />
              : ''
            : ''
        }
        <div
          style={{
            width: '100%',
            height: '100%',
            background: 'var(--background-2)',
            borderRadius: 4,
          }}
        >
          <Camera noControls={widthParticipants === '100%' ? false : true} consumerId={video.id} />
        </div>
      </DragExtend>
    )
  }
}

const mapStateToProps = (state: any) => ({
  centralPoint: state.centralPoint,
  connectionPoint: state.connectionPoint,
  settingState: state.settingState
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCentralPoint: (data: ICentralPoint | null) => dispatch(setCentralPoint(data)),
    setConnectionPoint: (data: Array<IConnectPoint>) => dispatch(setConnectionPoint(data)),
    setLargeView: (data: Array<ILargeView>) => dispatch(setLargeView(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantsItem)
