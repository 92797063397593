import { defaultISpam, ISpam } from "../models/reducers/spam.model"

export const ACTION_TYPES = {
    SPAM_CAMERA: 'SPAM_CAMERA'
}
interface IAction {
    type: string,
    payload: any
}
const initialize: ISpam = defaultISpam
const spamCamera = (state = initialize, action: IAction) => {
    switch (action.type) {
        case ACTION_TYPES.SPAM_CAMERA:
            state = action.payload
            return state
        default:
            return state
    }
}
const setSpamCamera = (data: any) => {
    return {
        type: ACTION_TYPES.SPAM_CAMERA,
        payload: data
    }
}
export { spamCamera, setSpamCamera }
