import { defaultISpam, ISpam } from "../models/reducers/spam.model"

export const ACTION_TYPES = {
    SPAM_MICRO: 'SPAM_MICRO'
}
interface IAction {
    type: string,
    payload: any
}
const initialize: ISpam = defaultISpam
const spamMicro = (state = initialize, action: IAction) => {
    switch (action.type) {
        case ACTION_TYPES.SPAM_MICRO:
            state = action.payload
            return state
        default:
            return state
    }
}
const setSpamMicro = (data: any) => {
    return {
        type: ACTION_TYPES.SPAM_MICRO,
        payload: data
    }
}
export { spamMicro, setSpamMicro }
