import { combineReducers } from 'redux'
import { authReducer } from '../reducers/authenticateReducer'
import { centralPointReducer } from '../reducers/centralPointReducer'
import { configAdminReducer } from '../reducers/configAdminReducer'
import { confirmReducer } from '../reducers/confirmWhiteBoardReducer'
import { connectionPointReducer } from '../reducers/connectionPointReducer'
import { drawBoardReducer } from '../reducers/drawBoardReducer'
import { errorBoundryReducer } from '../reducers/errorBoudryReducer'
import { imagesReducer } from '../reducers/imagesReducer'
import { largeViewReducer } from '../reducers/largeviewReducer'
import { layoutMobile } from '../reducers/layoutMobile'
import { layoutReducer } from '../reducers/layoutReducer'
import { layoutTypeReducer } from '../reducers/layoutTypeReducer'
import { messageReducer } from '../reducers/messageReducer'
import { modalReducer } from '../reducers/modalReducer'
import { patientsReducer } from '../reducers/patientReducer'
import { roomReducer } from '../reducers/roomReducer'
import { settingReducer } from '../reducers/settingReducer'
import { settingTool } from '../reducers/settingTool'
import { shareLink } from '../reducers/shareLink'
import { spamCamera } from '../reducers/spamCamera'
import { spamMicro } from '../reducers/spamMicro'
import { drawTools } from '../reducers/toolsBoard'
import { whiteBoardReducer } from '../reducers/whiteboardReducer'
const rootReducer: any = combineReducers({
  errorBoundryState: errorBoundryReducer,
  isAuthenticate: authReducer,
  connectionPoint: connectionPointReducer,
  centralPoint: centralPointReducer,
  largeView: largeViewReducer,
  layout: layoutReducer,
  settingState: settingReducer,
  roomState: roomReducer,
  configAdminState: configAdminReducer,
  imagesState: imagesReducer,
  patient: patientsReducer,
  messageState: messageReducer,
  modal: modalReducer,
  layoutType: layoutTypeReducer,
  whiteBoard: whiteBoardReducer,
  drawTools,
  drawBoard: drawBoardReducer,
  confirmReducer,
  layoutMobile,
  settingTool,
  shareLink,
  spamCamera,
  spamMicro
})
export { rootReducer }
