export interface IRoom {
  roomname: string
  username: string
  password: string
  openCamera: boolean
  openMicro: boolean
}

export const defaultRoom: IRoom = {
  roomname: '',
  username: '',
  password: '',
  openCamera: true,
  openMicro: true,
}
