export interface ILayout {
  row: number
  column: number
  showBridgeList: boolean
  showGallery: boolean
  showDoctorPoint: boolean
  showInfoPatient: boolean
  showChat: boolean
  widthParticipants: any
}

export const defaultLayout: ILayout = {
  row: 1,
  column: 1,
  showBridgeList: true,
  showGallery: false,
  showDoctorPoint: true,
  showInfoPatient: true,
  showChat: false,
  widthParticipants: 400,
}
