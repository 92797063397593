import { t } from 'i18next'
import { defaultCentralPoint, ICentralPoint } from '../models/reducers/centralPoint.model'
import { defaultConnectPoint, IConnectPoint } from '../models/reducers/connectionPoint.model'
import { defaultLargeView } from '../models/reducers/largeView.model'
import { IMessageState } from '../models/reducers/message.model'
import { setAuth } from '../reducers/authenticateReducer'
import { setCentralPoint } from '../reducers/centralPointReducer'
import { setConnectionPoint } from '../reducers/connectionPointReducer'
import { setImages } from '../reducers/imagesReducer'
import { setLargeView } from '../reducers/largeviewReducer'
import { setMessage } from '../reducers/messageReducer'
import { setRoom } from '../reducers/roomReducer'
import { setSetting } from '../reducers/settingReducer'
import { store } from '../redux/store'
import { RTCService } from '../services/RTCService'
import { EVENTS } from './events'
import { NOTIFYCATION_INFO } from './notifycation'
import { utilssManager } from './utilsManager'
interface IRoomListener {
  socket: any
  rtcService: RTCService
  room: { roomname: string; password: string; username: string }
}

class RoomListener {
  socket: any
  rtcService: RTCService
  room: { roomname: string; password: string; username: string; userId: string }
  onRoomCreated: () => void

  constructor(parameters: IRoomListener, onRoomCreated: () => void) {
    this.socket = parameters.socket
    this.onRoomCreated = onRoomCreated
    this.rtcService = parameters.rtcService
    this.room = { ...parameters.room, userId: '' }
    this.removeEventListener()
    this.addEventListener()
  }

  addEventListener = () => {
    this.socket.on(EVENTS.SOCKET_CONNECTION, this.onConnected)
    this.socket.on(EVENTS.SOCKET_USER_JOIN_ROOM, this.onUserJoinRoom)
    this.socket.on(EVENTS.SOCKET_USER_LEFT_ROOM, this.onUserLeftRoom)
    this.socket.on(EVENTS.KICKED, () => { })
    this.socket.on(EVENTS.ACTIVE_SPEAKER, () => { })
    this.socket.on(EVENTS.INCOMMING_MESSAGE, (mesages: Array<IMessageState>) => {
      store.dispatch(setMessage(mesages))
    })
    this.socket.on(EVENTS.FILE_SHARE, () => { })
    this.socket.on(EVENTS.REQUEST_JOIN_ROOM_PERMISSION, this.onHasRequestJoinRoomPermission)
    this.socket.on(EVENTS.SOCKET_DISCONNECT, this.onDisconnected)
    this.socket.on(EVENTS.USER_UPDATE_NAME, this.onChangeUserName)
    this.socket.on(EVENTS.AUTHORIZATION_KEY, this.onChangeIsKey)
    this.socket.on(EVENTS.SOCKET_CHANGE_ROOM_PASSWORD, this.onChangePassWord)
  }

  removeEventListener = () => {
    this.socket.off(EVENTS.SOCKET_CONNECTION)
    this.socket.off(EVENTS.SOCKET_USER_JOIN_ROOM)
    this.socket.off(EVENTS.SOCKET_USER_LEFT_ROOM)
    this.socket.off(EVENTS.KICKED)
    this.socket.off(EVENTS.ACTIVE_SPEAKER)
    this.socket.off(EVENTS.INCOMMING_MESSAGE)
    this.socket.off(EVENTS.FILE_SHARE)
    this.socket.off(EVENTS.REQUEST_JOIN_ROOM_PERMISSION)
    this.socket.off(EVENTS.SOCKET_DISCONNECT)
  }

  onConnected = async () => {
    this.room.userId = this.socket.id
    const hasRoom = await this.rtcService.checkRoomExistence(this.room.roomname)
    if (hasRoom === undefined) {
      return
    }

    if (hasRoom) {
      //   const joinRoomPermision = await this.rtcService.joinRoomPermission(this.room)
      const roomData = await this.rtcService.joinRoom(this.room)
      console.log(roomData)
    } else {
      const roomData = await this.rtcService.createRoom(this.room)
      console.log(roomData)
    }

    // await this.rtcService.getPermision()
    // console.log('first', hasRoom)
    this.onRoomCreated()
  }

  onDisconnected = () => {
    console.log('disconnect')
    this.removeEventListener()
    this.rtcService.userLeave()
  }

  onUserJoinRoom = ({ name, id }: any) => {
    const state: any = store.getState()
    const currentConnectionPoint: Array<IConnectPoint> = [...state.connectionPoint]
    currentConnectionPoint.push({ id, username: name, consumerList: [] })
    store.dispatch(setConnectionPoint(currentConnectionPoint))
    NOTIFYCATION_INFO(name, t("joinroom"))
  }

  onUserLeftRoom = async ({ name, id, isKey }: any) => {
    const state: any = store.getState()
    const SFUUtils = utilssManager.SFUUtils
    if (isKey) {
      if (SFUUtils) {
        SFUUtils.consumers.forEach((consumer: any) => {
          consumer.track?.stop()
        })
        SFUUtils.producers.forEach((producer: any) => {
          producer.track?.stop()
        })
        SFUUtils.consumers = new Map()
        SFUUtils.producers = new Map()
      }
      store.dispatch(setConnectionPoint(defaultConnectPoint))
      store.dispatch(setCentralPoint(defaultCentralPoint))
      store.dispatch(setLargeView(defaultLargeView))
      store.dispatch(setImages([]))
      store.dispatch(setAuth(false))
    } else {
      const currentConnectionPoint: Array<IConnectPoint> = [...state.connectionPoint].filter(
        (point) => point.id !== id
      )
      store.dispatch(setConnectionPoint(currentConnectionPoint))
    }
    if (isKey) {
      NOTIFYCATION_INFO(name, t("endroom"))
    } else {
      NOTIFYCATION_INFO(name, t("leftroom"))
    }
  }

  onHasRequestJoinRoomPermission = (data: any) => {
    console.log(data)
  }
  // Đổi chủ phòng
  onChangeIsKey = async (socketId: any) => {
    const state: any = store.getState()
    const newSetting: any = { ...state.settingState }
    if (utilssManager.me === socketId) {
      newSetting.isKey = true
    } else {
      newSetting.isKey = false
    }
    const currentCentralPoint: any = { ...state.centralPoint }
    let newCentralPoint: ICentralPoint = { ...state.centralPoint }
    const newConnectionPoint: Array<IConnectPoint> = []
    const connectionPoint: any = [...state.connectionPoint]
    connectionPoint.map((user: any) => {
      if (user.id !== socketId) {
        newConnectionPoint.push(user)
      } else {
        newCentralPoint = user
        newConnectionPoint.push(currentCentralPoint)
      }
    })
    if (utilssManager.me === socketId) {
      NOTIFYCATION_INFO(state.centralPoint.username, "Đã nhường bạn làm chủ phòng")
    }
    await store.dispatch(setSetting(newSetting))
    await store.dispatch(setCentralPoint(newCentralPoint))
    await store.dispatch(setConnectionPoint(newConnectionPoint))
  }
  // Đổi tên người dùng
  onChangeUserName = async (data: { userId: string, username: string }) => {
    const state: any = store.getState()
    if (state.centralPoint.id === data.userId) {
      await store.dispatch(setCentralPoint({
        ...state.centralPoint, username: data.username
      }))
    } else {
      const newConnectionPoint = [...state.connectionPoint]
      newConnectionPoint.map(async (user: any) => {
        if (user.id === data.userId) {
          user.username = data.username
        }
      })
      await store.dispatch(setConnectionPoint(newConnectionPoint))
    }
  }
  // Đổi password
  onChangePassWord = async (password: any) => {
    const state: any = store.getState()
    await store.dispatch(setRoom({
      ...state.roomState, password
    }))
  }
}

export { RoomListener }
