import { SendOutlined } from '@ant-design/icons'
import { Input, Layout } from 'antd'
import { Component } from 'react'
import { connect } from 'react-redux'
import { IMessageState } from '../../models/reducers/message.model'
import { setMessage } from '../../reducers/messageReducer'
import { servicesManager } from '../../services/servicesManager'
import ChatContentComponent from './ChatContentComponent'
import './styles.scss'
const { Footer, Content } = Layout
const { Search } = Input
type Props = {
    messageState: Array<IMessageState>
    setMessage(data: Array<IMessageState>): void
    onShowChat():void
}

type State = {
    value: string
}

class ChatComponent extends Component<Props, State> {
    state = {
        value: '',
    }
    messagesEnd: any = null
    messagebox: any = null
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: 'smooth' })
    }

    sendMessage = async (values: string) => {
        if (values) {
            const rtcService = servicesManager.RTC
            await rtcService.sendMessage(values)
            this.getMessager()
            this.setState({ value: '' })
        }
    }

    componentDidMount = async () => {
        await this.getMessager()
        this.scrollToBottom()
    }

    componentDidUpdate = () => {
        this.scrollToBottom()
    }

    getMessager = async () => {
        const rtcService = servicesManager.RTC
        const messages = await rtcService.getMessage()
        this.props.setMessage(messages)
    }
    render() {
        const { messageState } = this.props
        return (
            <Layout
                className="chatboxContainer"
                style={{
                    width: '100%',
                    height: '100%',
                    background: 'transparent',
                }}
            >
                <Content
                    style={{
                        background: 'transparent',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        position: 'relative'
                    }}
                    className="chat-content-overflow"
                >
                    <div
                        style={{
                            position: 'relative',
                            margin: '5px',
                            height: '20px'
                        }}
                        onClick={()=>{
                            this.props.onShowChat()
                        }}
                    >
                        <span
                            style={{
                                position: 'absolute',
                                right: '2%'
                            }}
                        >
                            <img src="./icons/close.png" alt="" />
                        </span>
                    </div>
                    {messageState.map((chat, index) => {
                        return <ChatContentComponent key={index} chat={chat} />
                    })}
                    <div
                        style={{ float: 'left', clear: 'both' }}
                        ref={(el) => {
                            this.messagesEnd = el
                        }}
                    />
                </Content>
                <Footer
                    style={{
                        padding: 5,
                        background: 'var(--background-2)',
                    }}
                >
                    <Search
                        value={this.state.value}
                        onChange={(e: any) => {
                            this.setState({ value: e.target.value })
                        }}
                        ref={(ref) => {
                            this.messagebox = ref
                        }}
                        placeholder=""
                        enterButton={<SendOutlined />}
                        size="middle"
                        onSearch={this.sendMessage}
                    />
                </Footer>
            </Layout>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        messageState: state.messageState,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setMessage: (data: Array<IMessageState>) => dispatch(setMessage(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatComponent)
