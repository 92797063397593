export interface IModal {
  visible: boolean
  top: any
  width: any
  height: any
  title: any
  content: any
}

export const defaultModal = {
  visible: false,
  top: 50,
  width: 600,
  height: 'auto',
  title: '',
  content: null,
}
