import { IConfigAdmin } from '../models/reducers/configAdmin.model'
import { IPatient } from '../models/reducers/patient.model'
import { IResponse } from '../models/response/IResponse'
import { NOTIFYCATION_ERROR } from '../utils/notifycation'
import { ServiceBaseRISSCU } from './serviceBaseRISSCU'

class RISService extends ServiceBaseRISSCU {
  constructor(baseURL: string) {
    super(baseURL)
  }
  // Get Router Capacities
  getConfigAdmin = async () => {
    const url = '/api/Configs/all'
    const response: IResponse<Array<IConfigAdmin>> = await this.service.get(url)
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      console.error(response.message)
      return []
    }

    return response.data
  }

  permissionUploadFile = async (svid: any) => {
    const url = `/api/NonDicom/can-edit?svid=${svid}`
    const response: IResponse<Array<IConfigAdmin>> = await this.service.get(url)
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      return []
    }

    return response.data
  }

  getInfoPatient = async (sid: any, svid: any) => {
    const url = `api/Reports`
    const response: IResponse<IPatient> = await this.service.post(url, {
      studyId: sid,
      svId: svid,
    })
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      return
    }

    return response.data
  }

  getUserInfo = async () => {
    const url = '/api/Users/info'
    const response: IResponse<any> = await this.service.get(url)
    if (response.status !== 1) {
      NOTIFYCATION_ERROR(url, response.message)
      return
    }

    return response.data
  }
}

export { RISService }
