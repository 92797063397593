import { Col, Radio, Row } from 'antd'
import { t } from 'i18next'
import { Component } from 'react'
import { connect } from 'react-redux'
import { ICentralPoint } from '../../models/reducers/centralPoint.model'
import { IConnectPoint } from '../../models/reducers/connectionPoint.model'
import { ILargeView } from '../../models/reducers/largeView.model'
import { ILayout } from '../../models/reducers/layout.model'
import { setLargeView } from '../../reducers/largeviewReducer'
import { setLayout } from '../../reducers/layoutReducer'
import {
  getNumberOfSmallCameras,
  setLayoutType,
  LAYOUT_TYPE,
} from '../../reducers/layoutTypeReducer'
import LayoutButton from '../LayoutButton/LayoutButton'
import ConferenceView from '../Views/ConferenceView/ConferenceView'
import CustomView from '../Views/CustomView/CustomView'
import styles from './SelectLayout.module.scss'
type Props = {
  centralPoint: ICentralPoint
  connectionPoint: Array<IConnectPoint>
  layout: ILayout
  layoutType: string
  setLayout(data: ILayout): void
  setLargeView(data: Array<ILargeView>): void
  setLayoutType(data: string): void
}

type State = {}

export class SelectLayout extends Component<Props, State> {
  state = {}

  onChangeLayoutLargeView = (row: number, col: number) => {
    const { centralPoint, layout } = this.props
    const activeCentalPoint = centralPoint?.consumerList.find(
      (consumer) => consumer.active === true
    )
    const totalLargeView = row * col
    const largeView: Array<ILargeView> = []

    for (let index = 0; index < totalLargeView; index++) {
      largeView.push({ consumerId: activeCentalPoint && index === 0 ? activeCentalPoint.id : null })
    }
    this.props.setLayout({ ...layout, row, column: col })
    this.props.setLargeView(largeView)
    this.props.setLayoutType(LAYOUT_TYPE.GRID)
  }

  onChange = (e: any) => {
    const { connectionPoint } = this.props
    const listConsumer: Array<string> = []
    connectionPoint.forEach((user) => {
      user.consumerList.forEach((i) => {
        i.type === 'video' && listConsumer.push(i.id)
      })
    })
    const layoutType = e.target.value
    this.props.setLayoutType(layoutType)
    const numberOfSmallCameras = getNumberOfSmallCameras(layoutType)
    const largeView: Array<ILargeView> = []
    for (let index = 0; index < numberOfSmallCameras; index++) {
      largeView.push({ consumerId: listConsumer[index] ? listConsumer[index] : null })
    }
    this.props.setLargeView(largeView)
  }

  render() {
    const { layoutType } = this.props
    const numberOfSmallCameras = getNumberOfSmallCameras(layoutType)
    return (
      <div className={styles.selectLayout}>
        <Row style={{ marginTop: 30 }}>
          <Col span={6}>
            <div style={{ marginBottom: 10 }}> {t("gridlayout")}:</div>
          </Col>
          <Col span={18}>
            <div>
              <LayoutButton cellClick={this.onChangeLayoutLargeView} />
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col span={6}>
            <div style={{ marginBottom: 10 }}> {t("conferencelayout")}:</div>
          </Col>
          <Col span={18}>
            <div>
              <Radio.Group onChange={(v) => this.onChange(v)} value={layoutType}>
                <Radio value={LAYOUT_TYPE.TWO_CAMERA}>1.2</Radio>
                <Radio value={LAYOUT_TYPE.THREE_CAMERA}>1.3</Radio>
                <Radio value={LAYOUT_TYPE.FOUR_CAMERA}>1.4</Radio>
                <Radio value={LAYOUT_TYPE.FIVE_CAMERA}>1.5</Radio>
                <Radio value={LAYOUT_TYPE.SIX_CAMERA}>1.6</Radio>
                <Radio value={LAYOUT_TYPE.SEVEN_CAMERA}>1.7</Radio>
                <Radio value={LAYOUT_TYPE.EIGHT_CAMERA}>1.8</Radio>
                <Radio value={LAYOUT_TYPE.NINE_CAMERA}>1.9</Radio>
                <Radio value={LAYOUT_TYPE.TEN_CAMERA}>1.10</Radio>
              </Radio.Group>
              {numberOfSmallCameras > 0 && (
                <div style={{ width: 320, height: 180, background: 'var(--background-2)' }}>
                  <CustomView
                    notCameras={true}
                    numberOfSmallCameras={numberOfSmallCameras <= 10 ? numberOfSmallCameras : 4}
                  />
                </div>
              )}
              {numberOfSmallCameras === 0 && (
                <div style={{ width: 320, height: 180, background: 'var(--background-2)' }}>
                  <CustomView notCameras={true} numberOfSmallCameras={4} />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col span={6}>
            <div style={{ marginBottom: 10 }}> {t("conferencelayoutcenter")}:</div>
          </Col>
          <Col span={18}>
            <Radio.Group onChange={(v) => this.onChange(v)} value={layoutType}>
              <Radio value={LAYOUT_TYPE.CENTER_4x4}>
                1.{getNumberOfSmallCameras(LAYOUT_TYPE.CENTER_4x4)}
              </Radio>
              <Radio value={LAYOUT_TYPE.CENTER_5x5}>
                1.{getNumberOfSmallCameras(LAYOUT_TYPE.CENTER_5x5)}
              </Radio>
              <Radio value={LAYOUT_TYPE.CENTER_6x6}>
                1.{getNumberOfSmallCameras(LAYOUT_TYPE.CENTER_6x6)}
              </Radio>
              <Radio value={LAYOUT_TYPE.CENTER_7x7}>
                1.{getNumberOfSmallCameras(LAYOUT_TYPE.CENTER_7x7)}
              </Radio>
              <Radio value={LAYOUT_TYPE.CENTER_8x8}>
                1.{getNumberOfSmallCameras(LAYOUT_TYPE.CENTER_8x8)}
              </Radio>
            </Radio.Group>
            <div>
              <div style={{ width: 320, height: 180, background: 'var(--background-2)' }}>
                <ConferenceView
                  notCameras={true}
                  numberOfSmallCameras={numberOfSmallCameras > 10 ? numberOfSmallCameras : 12}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  centralPoint: state.centralPoint,
  connectionPoint: state.connectionPoint,
  layout: state.layout,
  layoutType: state.layoutType,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLayout: (data: ILayout) => dispatch(setLayout(data)),
    setLargeView: (data: Array<ILargeView>) => dispatch(setLargeView(data)),
    setLayoutType: (data: string) => dispatch(setLayoutType(data)),
    // setCentralPoint: (data: ICentralPoint | null) => dispatch(setCentralPoint(data)),
    // setConnectionPoint: (data: Array<IConnectPoint>) => dispatch(setConnectionPoint(data)),
    // setLargeView: (data: Array<ILargeView>) => dispatch(setLargeView(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectLayout)
