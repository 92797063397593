import { Avatar, Col, Row } from 'antd'
import { Component } from 'react'
import { IMessageState } from '../../models/reducers/message.model'
import { playAudio } from '../../utils/playAudio'
import { utilssManager } from '../../utils/utilsManager'

type Props = {
  chat: IMessageState
}

type State = {}

class ChatContentComponent extends Component<Props, State> {
  state = {}
  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.chat !== prevProps.chat) {
      if (this.props.chat.id !== utilssManager.RoomUtils?.socket.id) {
        playAudio('newMessage')
      }
    }
  }
  render() {
    return (
      <Row>
        <Col span={3} style={{ alignItems: 'flex-end', display: 'flex', padding: 5 }}>
          {this.props.chat.id === utilssManager.RoomUtils?.socket.id ? null : (
            <Avatar src={`./icons/avatar.png?v=${window.TELEMEDIC_VERSION}`} />
          )}
        </Col>
        <Col span={21}>
          {this.props.chat.id === utilssManager.RoomUtils?.socket.id ? null : (
            <Row style={{ width: '100%' }}>
              <div style={{ fontSize: 11, color: '#90949c' }}>{this.props.chat.name}</div>
            </Row>
          )}

          <Row style={{ width: '100%' }}>
            <div
              style={
                this.props.chat.id === utilssManager.RoomUtils?.socket.id
                  ? {
                    marginTop: 2,
                    marginBottom: 2,
                    marginRight: 5,
                    background: '#42a5f5',
                    color: '#fff',
                    borderRadius: '20px 20px 3px 20px',
                    display: 'block',
                    maxWidth: '75%',
                    padding: '7px 13px 7px 13px',
                    marginLeft: 'auto',
                    fontSize: 13,
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                  }
                  : {
                    marginTop: 2,
                    marginBottom: 2,
                    background: '#ddd',
                    borderRadius: '20px 20px 20px 3px',
                    display: 'block',
                    maxWidth: '75%',
                    padding: '7px 13px 7px 13px',
                    float: 'left',
                    fontSize: 13,
                    color: '#444950',
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                  }
              }
            >
              {this.props.chat.message}
            </div>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default ChatContentComponent
