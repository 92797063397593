import configDevelopment from '../mocks/config.development.json'
const checkAuth = (key = 'token') => {
  const token = localStorage.getItem(key)
  if (token) {
    return true
  } else {
    return false
  }
}

const saveToken = (token: string, key = 'token') => {
  localStorage.setItem(key, token)
}

const getToken = (key = 'token') => {
  const token = localStorage.getItem(key)
  return token
}

const clearToken = (key = 'token') => {
  localStorage.removeItem(key)
}

const clearRefreshtoken = (key = 'tokenRefresh') => {
  localStorage.removeItem(key)
}

const getConfig = (key = 'serverConfig') => {
  let parseConfig: any
  if (process.env.NODE_ENV === 'development') {
    parseConfig = configDevelopment
  } else {
    const config = localStorage.getItem(key)
    parseConfig = config ? convertConfig(JSON.parse(config)) : configDevelopment
  }
  const RTC = parseConfig.RTC_SERVER
  return {RTC }
}

const clearAll = () => {
  clearToken('token')
  clearRefreshtoken('tokenRefresh')
}

const convertConfig = (serverCfg: any) => {
  if (window.location.protocol === 'http:') {
    const config = {
      SCU_SERVER: serverCfg.HTTP_SCU_SERVER,
      RIS_SERVER: serverCfg.HTTP_RIS_SERVER,
      RTC_SERVER: serverCfg.HTTP_SOCKET_SERVER,
    }
    return config
  } else {
    const config = {
      SCU_SERVER: serverCfg.HTTPS_SCU_SERVER,
      RIS_SERVER: serverCfg.HTTPS_RIS_SERVER,
      RTC_SERVER: serverCfg.HTTPS_SOCKET_SERVER,
    }
    return config
  }
}

export { checkAuth, saveToken, getToken, clearAll, getConfig }
