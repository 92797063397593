import { Component } from 'react'
import { connect } from 'react-redux'
import { IError } from '../../models/reducers/error.model'
import { setErrorBoundry } from '../../reducers/errorBoudryReducer'

interface IProps {
  children?: any
  errorBoundryState: IError
  setErrorBoundry(error: IError): void
}
interface IState {}

class ErrorBoundry extends Component<IProps, IState> {
  state = {}

  render() {
    const { errorBoundryState } = this.props
    if (errorBoundryState.show && errorBoundryState.error) {
      return <div />
    } else {
      return this.props.children
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    errorBoundryState: state.errorBoundryState,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setErrorBoundry: (error: IError) => dispatch(setErrorBoundry(error)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundry)
