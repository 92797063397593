import { defaultLargeView, ILargeView } from '../models/reducers/largeView.model'

export const ACTION_TYPES = {
  LARGE_VIEW: 'LARGE_VIEW',
}

interface IAction {
  type: string
  payload: any
}

const initialize: Array<ILargeView> = defaultLargeView

const largeViewReducer = (state = initialize, action: IAction) => {
  switch (action.type) {
    case ACTION_TYPES.LARGE_VIEW:
      state = action.payload
      return state

    default:
      return state
  }
}

const setLargeView = (data: Array<ILargeView>) => {
  return {
    type: ACTION_TYPES.LARGE_VIEW,
    payload: data,
  }
}

export { largeViewReducer, setLargeView }
