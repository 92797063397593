import { Button, Tooltip } from 'antd'
import { t } from 'i18next'
import { Component } from 'react'
import { ISpam } from '../../../models/reducers/spam.model'
import { setSpamCamera } from '../../../reducers/spamCamera'
import { store } from '../../../redux/store'
import { utilssManager } from '../../../utils/utilsManager'
import './Camera.scss'
type Props = {
  noControls?: boolean
  consumerId: string
}
type State = {}
class Camera extends Component<Props, State> {
  state = {}
  videoRef: HTMLVideoElement | null = null
  player: any
  componentDidMount = () => {
    this.getStream()
  }
  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.consumerId !== this.props.consumerId) {
      this.getStream()
    }
  }
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
    if (this.videoRef) {
      this.videoRef.removeEventListener('playing', this.playingUpdate)
    }
  }
  playingUpdate = () => {
    const state: any = store.getState()
    const spamCamera = state.spamCamera
    const newSpamCamera: ISpam = { ...spamCamera, disable: false }
    setTimeout(() => {
      store.dispatch(setSpamCamera(newSpamCamera))
    }, 500)
  }

  getStream = () => {
    const sfuUtils = utilssManager.SFUUtils
    if (!this.videoRef || !sfuUtils) {
      return
    }
    const { consumerId } = this.props

    const { stream } = sfuUtils.consumers.get(consumerId)
    if (!stream) {
      return
    }
    this.videoRef.srcObject = stream
    this.videoRef.removeEventListener('playing', this.playingUpdate)
    this.videoRef.addEventListener('playing', this.playingUpdate)
  }
  onFullscreen = () => {
    const elem = this.videoRef
    if (!elem) {
      return
    }
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    }
  }

  onPictureInPicture = () => {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture()
    } else if (document.pictureInPictureEnabled) {
      this.videoRef?.requestPictureInPicture()
    }
  }

  render() {
    return (
      <div
        className="camera-normal"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          borderRadius: '8px',
          position: 'relative',
        }}
      >
        {!this.props.noControls && (
          <div className="controlsContainer">
            <div className="rightControls">
              <Tooltip title="Picture In Picture">
                <Button onClick={this.onPictureInPicture} size="small" type="text">
                  <img className="controlsIcon" alt="" src="./icons/minimize.png" />
                </Button>
              </Tooltip>
              <Tooltip title={t('fullscreen')} placement="topRight">
                <Button onClick={this.onFullscreen} size="small" type="text">
                  <img className="controlsIcon" alt="" src="./icons/full-video.png" />
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
        <video
          playsInline={true}
          className="video-js"
          controls={false}
          poster={'./icons/loading.svg'}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            background: '#000',
            borderRadius: '9px',
          }}
          autoPlay={true}
          muted={true}
          ref={(ref) => {
            this.videoRef = ref
          }}
        />
      </div>
    )
  }
}

export default Camera
