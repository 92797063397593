export interface IPatient {
  pName: string
  pCode: string
  pAge: any
  serviceName: string
  modality: string
  birthDay: string
}

export const defaultPatient = {
  pName: '',
  pCode: '',
  pAge: 0,
  serviceName: '',
  modality: '',
  birthDay: '',
}
