import { CloseOutlined } from '@ant-design/icons'
import { Button, Layout } from 'antd'
import { Content, Header } from 'antd/lib/layout/layout'
import { Component } from 'react'
import { connect } from 'react-redux'
import ChatComponent from '../../components/ChatComponent/ChatComponent'
import { ILayout } from '../../models/reducers/layout.model'
import { setLayout } from '../../reducers/layoutReducer'
import styles from './ChatSider.module.scss'
type Props = {
  layout: ILayout
  setLayout(data: ILayout): void
}

type State = {}

export class ChatSider extends Component<Props, State> {
  state = {}
  onClose = () => {
    const { layout } = this.props
    this.props.setLayout({
      ...layout,
      showChat: false,
    })
  }
  render() {
    return (
      <Layout className={styles.wrapperChatSider}>
        <Header className={styles.header}>
          <div className={styles.headerLeft}>Chat</div>
          <div className={styles.headerRight}>
            <Button icon={<CloseOutlined />} onClick={this.onClose}/>
          </div>
        </Header>
        <Content className={styles.content}>
          <ChatComponent />
        </Content>
      </Layout>
    )
  }
}
const mapStateToProps = (state: any) => ({
  layout: state.layout,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLayout: (data: ILayout) => dispatch(setLayout(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatSider)
