import { notification } from 'antd'

export const NOTIFYCATION_ERROR = (url: string, message: any) => {
  notification.error({
    message: 'ERROR: ' + url,
    description: message || 'Server Error',
    duration: 10,
  })
}

export const NOTIFYCATION_INFO = (title: string, message: any) => {
  notification.info({
    message: title,
    description: message,
    duration: 2,
  })
}
