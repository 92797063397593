import { Button } from 'antd'
import { Component } from 'react'
import Div100vh from 'react-div-100vh'
import { connect } from 'react-redux'
import MSetting from '../Layout/Mobile/Setting/MSetting'
import MRoom from '../Layout/Room/MRoom'
import Loading from '../components/Loading/Loading'
import { IConfigAdmin } from '../models/reducers/configAdmin.model'
import { IPatient } from '../models/reducers/patient.model'
import { IRoom } from '../models/reducers/room.model'
import { setAuth } from '../reducers/authenticateReducer'
import { setConfigAdmin } from '../reducers/configAdminReducer'
import { setPatient } from '../reducers/patientReducer'
import { setRoom } from '../reducers/roomReducer'
import { server, IServer } from '../server/server'
import { getConfig } from '../utils/storeManager'
import MHomePage from './MHomePage'
type Props = {
  setAuth(data: boolean): void
  setConfigAdmin(data: Array<IConfigAdmin>): void
  setPatient(data: IPatient): void
  configAdminState: Array<IConfigAdmin>
  isAuthenticate: boolean
  roomState: IRoom
  hasVideo: boolean
  hasAudio: boolean
  screenShare: boolean
  setRoomState(data: IRoom): void
}

type State = {
  loading: boolean
  show: boolean
  display: boolean
}

class StartPage extends Component<Props, State> {
  state: Readonly<State> = {
    loading: true,
    show: false,
    display: true,
  }

  onShowHomePage = () => {
    this.props.setAuth(true)
  }

  componentDidMount = async () => {
    const configServer: IServer = getConfig()
    server.RTC = configServer.RTC
    const room = this.getParamsData()
    const roomState = {
      ...this.props.roomState,
      roomname: room.roomname,
      username: room.username,
      password: room.password,
    }
    await this.props.setRoomState(roomState)
    this.setState({
      loading: false,
    })
  }

  getParamsData = () => {
    const url = new URL(window.location.href)
    const param = url.searchParams
    const paramData: any = param.get('params')
    if (!paramData) {
      const room = {
        roomname: '',
        username: '',
        password: null,
      }
      return room
    } else {
      const decodeParams = decodeURIComponent(window.atob(paramData))
      return JSON.parse(decodeParams)
    }
  }
  showSetting = () => {
    const show: boolean = this.state.show
    this.setState({
      show: !show,
    })
  }
  prev = () => {
    this.setState({
      display: false,
    })
  }
  next = () => {
    this.setState({
      display: true,
    })
  }
  render() {
    const { isAuthenticate } = this.props
    return (
      <Div100vh>
        <div
          className="start-page"
          style={{
            width: '100%',
            height: '100%',
            background: 'var(--background-1)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            flexWrap: 'wrap',
          }}
        >
          {!isAuthenticate ? (
            <>
              <Button
                style={{ zIndex: '10', background: '#183333' }}
                onClick={() => {
                  this.prev()
                }}
              >
                Cài đặt
              </Button>
              <Button
                style={{ zIndex: '10', background: '#183333' }}
                onClick={() => {
                  this.next()
                }}
              >
                Tạo Phòng
              </Button>
              <div
                className="boxed"
                style={{
                  display: 'flex',
                  width: '100%',
                  maxWidth: 800,
                  maxHeight: 600,
                  background: 'transparent',
                  border: '4px solid',
                  borderImageSlice: 1,
                  borderImageSource: 'linear-gradient(to right bottom, #6a5af9, #f62682) ',
                }}
              >
                {this.state.loading ? (
                  <Loading bg={'transparent'} />
                ) : (
                  <>
                    <div
                      style={{
                        display: this.state.display ? `none` : '',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <MSetting
                        hasVideo={this.props.hasVideo}
                        hasAudio={this.props.hasAudio}
                        hasShareScreen={this.props.screenShare}
                      />
                    </div>
                    <div
                      style={{
                        display: this.state.display ? '' : 'none',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <MRoom
                        hasVideo={this.props.hasVideo}
                        hasAudio={this.props.hasAudio}
                        onShowHomePage={this.onShowHomePage}
                      />
                    </div>
                  </>
                )}
              </div>
              <div
                style={{
                  marginTop: '-50%',
                  background: 'transparent',
                  alignItems: 'center',
                  fontSize: 'bold',
                }}
                className="control"
              />
            </>
          ) : (
            <MHomePage />
          )}
        </div>
      </Div100vh>
    )
  }
}

const mapStateToProps = (state: any) => ({
  configAdminState: state.configAdminState,
  isAuthenticate: state.isAuthenticate,
  roomState: state.roomState,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setConfigAdmin: (data: Array<IConfigAdmin>) => dispatch(setConfigAdmin(data)),
    setPatient: (data: IPatient) => dispatch(setPatient(data)),
    setAuth: (data: boolean) => dispatch(setAuth(data)),
    setRoomState: (data: IRoom) => dispatch(setRoom(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StartPage)
